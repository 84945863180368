// ** React Imports
import { Link } from "react-router-dom"

// ** Reactstrap Imports
import { Button, Col, Row } from "reactstrap"

// ** Custom Hooks
import { useSkin } from "utility/hooks/useSkin"

// ** Styles
import "@styles/base/pages/page-misc.scss"
import { useTranslation } from "react-i18next"

interface ErrorPageNotFounProps {
  hideBackButton?: boolean
}

const ErrorPageNotFound = ({ hideBackButton }: ErrorPageNotFounProps) => {
  // ** Hooks
  const { skin } = useSkin()
  const { t } = useTranslation()

  const illustration = skin === "dark" ? "error-dark.svg" : "error.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default
  return (
    <div className="misc-wrapper">
      <Row
        className="d-flex flex-column flex-lg-row w-100"
        style={{
          maxWidth: "950px"
        }}
      >
        <Col sm={12} lg={6} className=" text-center">
          <img
            className="img-fluid"
            style={{ width: "100%", maxWidth: "450px" }}
            src={source}
            alt="Not authorized page"
          />
        </Col>
        <Col sm={12} lg={6} className=" d-flex justify-content-center align-items-center">
          <div className="text-start p-1 p-md-0">
            <h1 className="mb-1">{t("not_found")} 🕵🏻‍♀️</h1>
            <h4 className="text-wrap d-block d-md-none">{t("requested_page_not_found1")}</h4>
            <h4 className="text-nowrap d-none d-md-block">{t("requested_page_not_found1")}</h4>
            <h4>{t("requested_page_not_found2")}</h4>
            <h4>{t("requested_page_not_found3")}</h4>
            {hideBackButton && (
              <Button className="mt-1 round w-50" color="primary" tag={Link} to={-1} outline>
                {t("back")}
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default ErrorPageNotFound
