import { axiosBase } from "./../axiosbase"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { CreatedCommunity } from "./types.d"
import { CreatedToken } from "./../tokens/types.d"

interface FetchCommunitiesProps {
  accessToken: string
  tokens?: CreatedToken[]
}

interface FetchCommunityProductProps {
  accessToken: string
  communityId: string
}

export const fetchCommunities = createAsyncThunk<CreatedCommunity[], FetchCommunitiesProps>(
  "communties/fetchCommunities",
  async ({ accessToken, tokens }: FetchCommunitiesProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }

    const communityIds = tokens.map((token) => token?.communityId)
    const uniqueCommunityIds = [...new Set(communityIds)]

    const communities = await Promise.all(
      uniqueCommunityIds.map(async (communityId) => {
        return axiosBase.get(`/communities/${communityId}`, config).then((response) => response.data)
      })
    )

    return communities?.filter(Boolean)
  }
)

export const fetchCommunitiyProducts = createAsyncThunk(
  "communities/fecthCommunityProducts",
  async ({ accessToken, communityId }: FetchCommunityProductProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    try {
      const { data } = await axiosBase.get(`/communities/${communityId}/products`, config)
      return data
    } catch (error) {
      console.log(error)
    }
  }
)
