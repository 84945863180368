export interface useLoggerProps {
  disabled?: boolean
}

interface LoggerEnvironment {
  environment?: "dev" | "prod"
}

export interface CreateLoggerReturnProps {
  read: (type?: "error" | "warn" | "log") => void
  add: (logMessage: string) => void
  remove: (index: number) => void
  reset: () => void
}
export type CreateLogger = (
  identificationMessage?: string,
  environment?: LoggerEnvironment["environment"]
) => CreateLoggerReturnProps
/**
 * Custom hook for generate loggers to functions with a simple way and better readability
 * @param {string} identificationMessage - Message to identify the logger
 * @param {string} environment - Environment to show the logger
 */
const useLogger = (isEnabled = false) => {
  const formatLog = (logMessage) => {
    return `\n${logMessage}`
  }

  const createLogger = (identificationMessage?: string, environment: LoggerEnvironment["environment"] = "dev") => {
    const log = identificationMessage ? [identificationMessage] : []

    /**
     * Read all logs who are been added and the type of log can be changed
     * @param {'error' | 'warn' | 'log'} type - Type of log to read
     * @default console.log()
     */
    const read = (type: "error" | "warn" | "log" = "log") => {
      const consoleIsAvailable =
        (environment === "dev" && process.env.NODE_ENV === "development") || environment === "prod"
      if (consoleIsAvailable && isEnabled) {
        switch (type) {
          case "error":
            console.error(log.join(" "))
            break
          case "warn":
            console.warn(log.join(" "))
            break
          default:
            console.log(log.join(" "))
            break
        }
      }
    }

    /**
     * Add a new log
     * @param {string} logMessage - Message to add
     */
    const add = (logMessage) => {
      log.push(formatLog(logMessage))
    }

    /**
     * Remove a log
     * @param {number} index - Index of the log to remove
     */
    const remove = (index) => {
      log.splice(index, 1)
    }

    /**
     * Reset all logs
     */
    const reset = () => {
      log.length = 0
    }

    const logger = {
      read,
      add,
      remove,
      reset
    }

    return logger
  }

  return {
    createLogger
  }
}

export default useLogger
