import useWeb3AuthContext from "../useWeb3AuthContext"
import {
  Web3AuthProvidersEnum,
  Web3AuthStatusEnum,
  Web3AuthStatusState,
  Web3SelectedAuthProviderState
} from "../web3AuthContext"

interface DetectWeb3HookProps extends Pick<Web3AuthStatusState, "setWeb3AuthStatus"> {
  web3Provider: Web3AuthProvidersEnum
}

/**
 * Detects if the user has the selected web3 provider installed
 * @param web3Provider - The web3 provider to detect
 * @description - If the web3 provider is installed, it will return false to continue the propagation of the event
 * @returns {mustStopOtherProceeds: boolean} - If the web3 provider is not installed, it will return true to stop the propagation of the event
 */

type DetectProvidersMethodMap = {
  [key in Web3AuthProvidersEnum]: () => any
}

const detectWeb3Provider = ({ web3Provider, setWeb3AuthStatus }: DetectWeb3HookProps) => {
  const { Fail, Pending, Idle } = Web3AuthStatusEnum

  const returnResult = { mustStopOtherProceeds: false }

  const providersMethod: DetectProvidersMethodMap = {
    MetaMask: () => {
      return ethereum.isMetaMask
    }
  }

  setWeb3AuthStatus(Idle)

  const { ethereum } = window

  if (ethereum) {
    const selectedProviderMethod = providersMethod[web3Provider]

    if (selectedProviderMethod) {
      if (selectedProviderMethod()) {
        setWeb3AuthStatus(Pending)
        return returnResult
      }
    }
  }

  setWeb3AuthStatus(Fail)
  returnResult.mustStopOtherProceeds = true
  return returnResult
}

export default detectWeb3Provider
