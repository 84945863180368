import classNames from "classnames"
import { DisplaySourceTypeProps } from "."
import { getIpfsImageUrl } from "utility/ipfs"

interface DisplaySourceVideoProps extends DisplaySourceTypeProps {}

const DisplaySourceVideo = ({ src, displayClassName, displayStyle }: DisplaySourceVideoProps) => {
  return (
    <video
      muted
      playsInline
      loop
      autoPlay
      className={classNames("display-source-video", { [displayClassName]: displayClassName })}
      style={displayStyle}
      src={getIpfsImageUrl(src, 1024)}
    />
  )
}

export default DisplaySourceVideo
