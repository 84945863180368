import { useEffect, useMemo, useState } from "react"
import CustomDomainContext, { CustomDomainContextI } from "./customDomainContext"
import { useLocation, useNavigate } from "react-router-dom"
import usePWASign from "services/hooks/PWA/usePWA"
import useFetchCustomManifest from "./hooks/fetchCustomManifest"
import useFetchCompanyCustomDomain from "./hooks/fetchCompanyCustomDomain"
import useFetchDefaultMarketPlace from "./hooks/fetchDefaultMarketPlace"
import { useAppSelector } from "redux/hooks"
import useLogger from "utility/hooks/logger/useLogger"
import changeLocationMarketPlaceCustomDomainEffect from "./effectsCallbacks/changeLocation"
import pwaAlertMarketPlaceCustomDomainEffect from "./effectsCallbacks/pwaAlert"
import defaultMarketPlaceCustomDomainEffect from "./effectsCallbacks/default"
import changeManifestMarketPlaceCustomDomainEffect from "./effectsCallbacks/manifest"

interface CustomDomainProps {
  children: React.ReactNode
}

const CustomDomainProvider = ({ children }: CustomDomainProps) => {
  const { Provider } = CustomDomainContext

  const location = useLocation()
  const actualPath = location?.pathname === "/" ? "" : location?.pathname
  const params = location?.pathname.split("/")
  const navigate = useNavigate()

  const shouldFetchCustomDomainRef = !params[1]
  const { createLogger } = useLogger(true)
  const [handlePWAAlert] = usePWASign()
  const { whiteLabelIsLoading } = useAppSelector((state) => state.whitelabelConfig)

  const [defaultRoute, setDefaultRoute] = useState("/token")
  const [defaultMarketPlaceLink, setDefaultMarketPlaceLink] = useState(actualPath)
  const [hasPWAForDownload, setHasPWAForDownload] = useState(false)
  const [shouldFetchCustomDomain, setShouldFetchCustomDomain] = useState(shouldFetchCustomDomainRef)

  const {
    customDomainCompanyData: customDomainCompanyId,
    customDomainCompanyIsLoading,
    customDomainCompanyError
  } = useFetchCompanyCustomDomain({ shouldFetchCustomDomain })

  const { customManifestData, customManifestIsLoading } = useFetchCustomManifest({
    customDomainCompanyId
  })

  const { defaultMarketPlaceData, defaultMarketPlaceIsLoading } = useFetchDefaultMarketPlace({
    customDomainCompanyId
  })

  const marketplaceLink = defaultRoute

  useEffect(
    () =>
      defaultMarketPlaceCustomDomainEffect({
        createLogger,
        customDomainCompanyError,
        customDomainCompanyId,
        defaultMarketPlaceData,
        defaultMarketPlaceIsLoading,
        marketplaceLink,
        setDefaultMarketPlaceLink,
        setDefaultRoute,
        shouldFetchCustomDomain
      }),
    [customDomainCompanyId, defaultMarketPlaceIsLoading, customDomainCompanyError, params]
  )

  useEffect(() => {
    if (customDomainCompanyError) {
      setShouldFetchCustomDomain(false)
      setDefaultMarketPlaceLink("/token")
    }
  }, [customDomainCompanyError])

  //Somente para testes!!!
  useEffect(() => {
    const logger = createLogger("CustomDomainProvider => Default Effect")
    if (defaultRoute === "/token" && defaultMarketPlaceLink !== "" && !shouldFetchCustomDomain) {
      logger.add("- Não deve buscar o dominio customizado")
      logger.add("-- CustomDomainProvider => Loaded")
      logger.read()
    }
  }, [shouldFetchCustomDomain])

  useEffect(
    () =>
      changeManifestMarketPlaceCustomDomainEffect({
        createLogger,
        customDomainCompanyIsLoading,
        customManifestData,
        customManifestIsLoading,
        setHasPWAForDownload,
        shouldFetchCustomDomain
      }),
    [customDomainCompanyIsLoading, customManifestIsLoading]
  )

  useEffect(
    () =>
      pwaAlertMarketPlaceCustomDomainEffect({
        createLogger,
        handlePWAAlert,
        hasPWAForDownload,
        whiteLabelIsLoading
      }),
    [hasPWAForDownload, whiteLabelIsLoading]
  )

  useEffect(
    () =>
      changeLocationMarketPlaceCustomDomainEffect({
        createLogger,
        defaultMarketPlaceLink,
        defaultRoute,
        setShouldFetchCustomDomain,
        navigate,
        actualPath
      }),
    [defaultRoute, defaultMarketPlaceLink]
  )

  const values: CustomDomainContextI = {
    defaultRoute,
    setDefaultRoute,
    defaultMarketPlaceLink,
    setDefaultMarketPlaceLink,
    hasPWAForDownload,
    setHasPWAForDownload,
    shouldFetchCustomDomain,
    setShouldFetchCustomDomain
  }
  const providerValues = useMemo<CustomDomainContextI>(() => values, [...Object.values(values)])

  return <Provider value={providerValues}>{children}</Provider>
}

export default CustomDomainProvider
