import React from "react"
import KycDetailedProceedButtonRoot from "../../../components/button/root"
import KycIcon from "../../../components/kycIcon"
import { Md10K } from "react-icons/md"
import { HiOutlineOfficeBuilding } from "react-icons/hi"
import { IoMdArrowRoundForward } from "react-icons/io"
import { KycPersonTypeI } from "../../../context/kycFormHooks/initialState"
import { UserIdentityDocumentsEnum } from "interfaces/user"

interface ProceedToJuridicFormsProps {
  onClick: (personType: KycPersonTypeI["identityDocument"]["document"]) => void
}

const ProceedToJuridicForms: React.FC<ProceedToJuridicFormsProps> = ({ onClick }) => {
  const { Container, Title, SubTitle } = KycDetailedProceedButtonRoot

  return (
    <>
      <Container
        LeftIcon={<KycIcon icon={HiOutlineOfficeBuilding} size={"lg"} />}
        RightIcon={<KycIcon icon={IoMdArrowRoundForward} />}
        onClick={() => onClick(UserIdentityDocumentsEnum.CNPJ)}
      >
        <Title text="Juridic_Person" />
        <SubTitle text="Juridic_Person_Description" />
      </Container>
    </>
  )
}

export default ProceedToJuridicForms
