import { fetchJWTMetamask } from "services/store/metamask"
import { MetaMaskAuthStatusState, MetaMaskStatusEnum } from "../metamaskAuthContext"

interface FetchJwtMetaMaskProps extends Pick<MetaMaskAuthStatusState, "setMetaMaskAuthStatus"> {
  dispatch: any
  address: string
  message: string
  network: string
  signature: string
  setIsUserLoggedIn: (value: boolean) => void
}

const handleFetchMetaMaskJwt = ({
  dispatch,
  address,
  message,
  network,
  signature,
  setMetaMaskAuthStatus,
  setIsUserLoggedIn
}: FetchJwtMetaMaskProps) => {
  try {
    dispatch(
      fetchJWTMetamask({
        address,
        blockchain: "polygon",
        message,
        network,
        signature
      })
    )

    setMetaMaskAuthStatus(MetaMaskStatusEnum.Connected)
    setIsUserLoggedIn(true)
  } catch (err) {
    console.log(err)
  }
}

export default handleFetchMetaMaskJwt