import { createSlice } from "@reduxjs/toolkit"

export const refreshSlice = createSlice({
  name: "refresh",
  initialState: {
    refresh: false
  },
  reducers: {
    setRefresh: (state, action) => {
      state.refresh = action.payload
    }
  }
})

export const { setRefresh } = refreshSlice.actions

export default refreshSlice.reducer
