import SwiperRoot from "@core/components/swiper"
import WeispaceLoginSwiperSlideContent from "web-weispace/views/login/components/swiper/slideContent"

export interface LoginSwiperProps {
  swiperData: any
}

const LoginSwiper = ({ swiperData }: LoginSwiperProps) => {
  const { Container, Slide, Swiper } = SwiperRoot

  return (
    <Container className="h-100">
      <Swiper.Pagination
        className="pagination-login-swiper d-flex flex-column h-100"
        swiperOptions={{
          navigation: true
        }}
      >
        {swiperData.map((item, index) => {
          return (
            <Slide key={index} className="d-flex flex-column h-100 gap-2 p-1 px-2 ">
              <WeispaceLoginSwiperSlideContent banner={item.banner} />
            </Slide>
          )
        })}
      </Swiper.Pagination>
    </Container>
  )
}

export default LoginSwiper
