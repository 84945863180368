import { Dispatch, SetStateAction } from "react"
import { Web3DetectHandlerStatus } from "./detect"

export type AvailableWeb3Providers = "metamask"

export enum Web3MainHandlerStatus {
  Connected = "connected",
  Disconnected = "disconnected",
  Unavailable = "unavailable",
  Failed = "failed"
}

export type Web3HandlerStatus = Web3MainHandlerStatus | Web3DetectHandlerStatus

export interface Web3HandlerStatusInterface {
  web3Provider: AvailableWeb3Providers
  changeWeb3HandlerStatus: Dispatch<SetStateAction<Web3HandlerStatus>>
}
