import { getCollectionBlindMint } from "./actions"
import { BlindMintCollectionData } from "../../../../web-marketplace/views/collection/pages/blindmint/interfaces"

const collectionBlindMintState = {
  collectionBlindMint: {} as BlindMintCollectionData,
  collectionBlindMintLoading: true,
  collectionBlindMintError: undefined
}

const collectionBlindMintReducers = {
  setCollectionBlindMint: (state: any, { payload }: any) => {
    state.collectionBlindMint = payload
  },
  setCollectionBlindMintLoading: (state: any, { payload }: any) => {
    state.collectionBlindMintLoading = payload
  }
}

const collectionBlindMintExtraReducers = {
  pending: {
    action: getCollectionBlindMint.pending,
    reducer: (state: any) => {
      state.collectionBlindMintLoading = true
    }
  },
  fulfilled: {
    action: getCollectionBlindMint.fulfilled,
    reducer: (state: any, { payload }: any) => {
      state.collectionBlindMint = payload
      state.collectionBlindMintLoading = false
    }
  },
  rejected: {
    action: getCollectionBlindMint.rejected,
    reducer: (state: any, { error }: any) => {
      state.collectionBlindMintError = error
      state.collectionBlindMintLoading = false
    }
  }
}

export { collectionBlindMintState, collectionBlindMintReducers, collectionBlindMintExtraReducers }
