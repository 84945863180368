import { fetchCompany } from "./../../web-marketplace/views/token/store/index"
import { AxiosResponse } from "axios"
import { CreatedTokenOffer, TokenOffer } from "interfaces/store"
import { axiosBase } from "../axiosBase"

export const getTokenOfferById = async (
  jwtAccessToken: string,
  tokenId: string
): Promise<AxiosResponse<CreatedTokenOffer>> => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwtAccessToken}`
    }
  }
  return await axiosBase.get(`store/token/${tokenId}`, config)
}

export const createMarketplaceTokenOffer = async (
  jwtAccessToken: string,
  tokenId: string,
  tokenOffer: TokenOffer
): Promise<AxiosResponse<CreatedTokenOffer>> => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwtAccessToken}`
    }
  }
  return await axiosBase.patch(`marketplace/token/${tokenId}/manage`, tokenOffer, config)
}

export const getMarketPlaceTokenOffersByTokenId = async (jwtAccessToken: string, tokenId: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${jwtAccessToken}`
    }
  }
  return await axiosBase.get(`marketplace/token/${tokenId}`, config)
}

export const fetchCollectionsByCommunity = async (communityId: string) => {
  return (await axiosBase.get(`store/community/${communityId}/collections/active`)).data
}

export const fetchCollectionsById = async (collectionId: string) => {
  return (await axiosBase.get(`store/collection/${collectionId}/tokens/active`)).data
}

export const fetchAllTokensByCompany = async (companyId: string) => {
  return (await axiosBase.get(`store/company/${companyId}/tokens`)).data
}

export const fetchCompanyTokens = async ({
  companyId,
  limit,
  skip
}: {
  companyId: string
  limit: number
  skip: number
}) => {
  const { data: tokensOffersData } = await axiosBase.get(
    `/store/company/${companyId}/tokens/active?limit=${limit}&skip=${skip}`
  )
  return tokensOffersData
}


export const fetchCommunityTokens = async ({
  communityId,
  limit,
  skip
}: {
  communityId: string
  limit: number
  skip: number
}) => {
  const { data: tokensOffersData } = await axiosBase.get(
    `/store/community/${communityId}/tokens/active?limit=${limit}&skip=${skip}`
  )
  return tokensOffersData
}

export const fetchCollectionTokens = async ({
  collectionId,
  limit,
  skip
}: {
  collectionId: string
  limit: number
  skip: number
}) => {
  const { data: tokensOffersData } = await axiosBase.get(
    `/store/collection/${collectionId}/tokens/active?limit=${limit}&skip=${skip}`
  )
  return tokensOffersData
}
