import { Dispatch, SetStateAction, createContext } from "react"

export interface MarketPlaceKycGuardStateI {
  isMarketPlaceKycGuardEnabled: boolean
  setIsMarketPlaceKycGuardEnabled: Dispatch<SetStateAction<boolean>>
}

export interface MarketPlaceKycGuardShieldStateI {
  isMarketPlaceKycGuardShieldEnabled: boolean
  setIsMarketPlaceKycGuardShieldEnabled: Dispatch<SetStateAction<boolean>>
}

export interface MarketPlaceKycGuardContextI extends MarketPlaceKycGuardStateI, MarketPlaceKycGuardShieldStateI {}

const MarketPlaceKycGuardContext = createContext({} as MarketPlaceKycGuardContextI)

export default MarketPlaceKycGuardContext
