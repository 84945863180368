import { CreateLogger } from "utility/hooks/logger/useLogger"
import { MarketPlaceKycGuardShieldStateI, MarketPlaceKycGuardStateI } from "../marketPlaceKycGuardContext"

interface MarketPlaceKycGuardToggleShieldEffectProps {
  createLogger: CreateLogger
  isGuardEnabled: MarketPlaceKycGuardStateI["isMarketPlaceKycGuardEnabled"]
  shieldMustBeEnabled: boolean
  isShieldEnabled: MarketPlaceKycGuardShieldStateI["isMarketPlaceKycGuardShieldEnabled"]
  setIsShieldEnabled: MarketPlaceKycGuardShieldStateI["setIsMarketPlaceKycGuardShieldEnabled"]
}

/**
 * Responsável por alternar o shield do guard

 */
const toggleMarketPlaceKycGuardShield = ({
  createLogger,
  isGuardEnabled,
  isShieldEnabled,
  setIsShieldEnabled,
  shieldMustBeEnabled
}: MarketPlaceKycGuardToggleShieldEffectProps) => {
  const logger = createLogger("MarketPlaceKycGuardProvider => Toggle Shield Effect")

  if (isGuardEnabled) {
    logger.add("- Guard Ativo")
    logger.add("-- Verificando se shield deve ser ativado")
    if (shieldMustBeEnabled) {
      logger.add("--- Shield deve ser ativado")
      if (isShieldEnabled) {
        logger.add("---- Shield já está ativado")
      } else {
        logger.add("---- Ativando shield")
        setIsShieldEnabled(true)
      }
    } else {
      logger.add("--- Shield não deve ser ativado")
      setIsShieldEnabled(false)
    }
    logger.read()
  }

  // if (isUserLoggedIn) {
  //   if (isGuardEnabled && isShieldEnabled) {
  //     logger.add("- Guard e Shield Ativos")
  //     if (userHaveAccessPermission) {
  //       logger.add("-- Usuário tem permissão")
  //       if (shouldUserDoKyc) {
  //         logger.add("--- Usuário precisa fazer KYC")
  //         logger.add("---- Redirecionando para KYC")
  //         setCurrentAuthStageShowing(AuthModalsEnum.KYC)
  //       } else {
  //         logger.add("--- Usuário não precisa fazer KYC")
  //         logger.add("---- Desativando shield")
  //         setIsShieldEnabled(false)
  //       }
  //     } else {
  //       logger.add("-- Usuário não tem permissão")
  //       logger.add("-- Redirecionando para tela de acesso negado")
  //       setCurrentAuthStageShowing(AuthModalsEnum.ACCESS_DENIED)
  //       setIsShieldEnabled(true)
  //     }
  //   }
  //   logger.read()
  // } else {
  //   logger.add("- Usuário não está logado")
  //   if (isKycRequired && isGuardEnabled) {
  //     logger.add("-- Ativando Shield")
  //     setIsShieldEnabled(true)
  //   }
  //   logger.read()
  // }
}

export default toggleMarketPlaceKycGuardShield
