import classNames from "classnames"

interface LoginInputContentContainerProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

/**
 * Just use when you need to put something inside the input our use LeftSideContent
 */

const LoginInputContentContainer = ({ children, className, ...rest }: LoginInputContentContainerProps) => {
  return (
    <div className={classNames("position-relative w-100", className)} {...rest}>
      {children}
    </div>
  )
}

export default LoginInputContentContainer
