import Button from "@core/components/button"
import Container from "@core/components/container"
import Icon from "@core/components/icon"
import TabItem from "@core/components/tabs/tabItem"
import Typography from "@core/components/typography"
import React, { Dispatch, SetStateAction, useState } from "react"
import { FiRefreshCcw } from "react-icons/fi"
import { GrFormRefresh } from "react-icons/gr"
import { IoRefreshOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { Nav } from "reactstrap"
import { challengesPaths } from "web-weispace/views/challenges/router"

interface ChallengeTabsProps {
  redirectToRoute?: boolean
  activeTab: ChallengeTabsEnum
  setActiveTab: Dispatch<SetStateAction<ChallengeTabsEnum>>
  isDisabled?: boolean
  refresh?: Function
}

export enum ChallengeTabsEnum {
  Challenge = "Challenges",
  Participating = "Participating",
  Finalized = "Finalized"
}

interface ChallengeTabsItemI {
  route: string
}

type ChallengeTabsT = {
  [key in ChallengeTabsEnum]: ChallengeTabsItemI
}

const ChallengeTabs: React.FC<ChallengeTabsProps> = ({
  redirectToRoute = false,
  activeTab = ChallengeTabsEnum.Challenge,
  setActiveTab,
  isDisabled,
  refresh
}) => {
  const navigate = useNavigate()

  const tabs: ChallengeTabsT = {
    Challenges: {
      route: challengesPaths["home"]
    },
    Participating: {
      route: challengesPaths["participating"]
    },
    Finalized: {
      route: challengesPaths["finalized"]
    }
  }

  const handleChangeTab = (tab: ChallengeTabsEnum) => {
    setActiveTab(tab)
  }

  const handleRedirect = (tab: ChallengeTabsEnum) => {
    navigate(tabs[tab].route)
  }

  const handleOnClick = (tab: ChallengeTabsEnum) => {
    handleChangeTab(tab)
    if (redirectToRoute) {
      handleRedirect(tab)
    }
  }

  const handleRefresh = (e) => {
    e.preventDefault()
    refresh()
  }

  return (
    <Nav tabs className="border-bottom m-0 d-flex justify-content-between align-items-center">
      <Container className="d-flex flex-wrap">
        {Object.keys(tabs).map((key) => {
          const isActive = key === activeTab

          return (
            <TabItem
              disabled={isDisabled}
              isActive={isActive}
              handleOnClick={() => handleOnClick(key as ChallengeTabsEnum)}
              key={key}
            >
              <Typography darkMuted={!isActive}>{key}</Typography>
            </TabItem>
          )
        })}
      </Container>
      {refresh && (
        <Button className="action-button-75 pill d-flex nav-link" aria-checked={false} onClick={handleRefresh}>
          <Icon icon={IoRefreshOutline} size={"1.5rem"} noShrink />
          <Typography bold>Refresh</Typography>
        </Button>
      )}
    </Nav>
  )
}

export default ChallengeTabs
