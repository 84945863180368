import { MdOutlineSimCardAlert } from "react-icons/md"
import KycCaption from "../../components/kycCaption"
import KycHeaderLayout from "../../header"
import KycBasicInfosForm from "./forms"
import useFormContext from "utility/context/formContext/useFormContext"
import { useEffect } from "react"
import useKycContext from "../../context/useKycContext"
import { KycStatusStageEnum } from "../../context/KycContext"

const KycBasicInfosStageSlide = () => {
  const { formData } = useFormContext()
  const { setCurrentStatusStage } = useKycContext()

  return (
    <>
      <KycHeaderLayout
        title="KYC_Basic_Info"
        description="KYC_Basic_Info_Description"
        src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
      />
      <KycBasicInfosForm />
      <KycCaption icon={MdOutlineSimCardAlert} text="KYC_Basic_Info_Caption" />
    </>
  )
}

export default KycBasicInfosStageSlide
