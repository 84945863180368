import { useQuery } from "react-query"
import { fetchCompanyCustomDomainV2 } from "services/customDomain"
import { onError } from "utility/onError"

interface FetchCompanyCustomDomainProps {
  shouldFetchCustomDomain: boolean
  enabled?: boolean
}

const useFetchCompanyCustomDomain = ({ shouldFetchCustomDomain, enabled = true }: FetchCompanyCustomDomainProps) => {
  const {
    data: customDomainCompanyData,
    isLoading: customDomainCompanyIsLoading,
    error: customDomainCompanyError
  } = useQuery(
    ["fetchCompanyCustomDomain", shouldFetchCustomDomain],
    () => fetchCompanyCustomDomainV2().then((response) => response.data),
    {
      onError,
      enabled: shouldFetchCustomDomain && enabled,
      refetchOnWindowFocus: false,
      retry: false
    }
  )

  return { customDomainCompanyData, customDomainCompanyIsLoading, customDomainCompanyError }
}

export default useFetchCompanyCustomDomain
