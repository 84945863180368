import { Dispatch, SetStateAction, createContext } from "react"
import useAuthContext from "./AuthInContext"
import AuthProvider from "./AuthProvider"
import { Chain } from "wagmi"
import useAuthNetwork from "./hooks/useAuthNetwork"
import useMetamaskAuth from "./hooks/useMetamaskAuth"
import useFirebaseAuth from "./hooks/useFirebaseAuth"
import useWagmi from "./hooks/useWagmi"

export enum FirebaseSocialNetworkAuthEnum {
  GOOGLE = "google",
  FACEBOOK = "facebook",
  APPLE = "apple",
  MICROSOFT = "microsoft"
}

export enum MetamaskAuthEnum {
  METAMASK = "metamask"
}

export enum AuthModalsEnum {
  LOGIN = "login",
  WEB3_LOGIN = "web3",
  WEB3_UNAVAILABLE = "web3_unavailable",
  OTP_VERIFICATION = "otp_verification",
  KYC = "kyc",
  ACCESS_DENIED = "access_denied"
}

export type AuthMethods = "Google" | "Microsoft" | "Apple" | "Facebook" | "Metamask"
export type AuthMethods2 = "Socials" | "Web3" | "Email"

export interface AuthLoginStage {
  header?: JSX.Element
  body: JSX.Element
  footer?: JSX.Element
}

export type AuthLoginStages = {
  [key in AuthModalsEnum]: AuthLoginStage
}

export type AuthNetworksEnum = FirebaseSocialNetworkAuthEnum | MetamaskAuthEnum

export type AuthContextType = {
  handleFirebaseAuth: (socialNetwork: FirebaseSocialNetworkAuthEnum) => Promise<void>
  isAuthModalOpen: boolean
  setIsAuthModalOpen: Dispatch<SetStateAction<boolean>>
  chain: Chain & {
    unsupported?: boolean
  }
  isUserLoggedIn: boolean
  setIsUserLoggedIn: Dispatch<SetStateAction<boolean>>
  voucherCode: string
  setVoucherCode: Dispatch<SetStateAction<string>>
  referralCode: string
  setReferralCode: Dispatch<SetStateAction<string>>
  currentAuthStageShowing: AuthModalsEnum
  setCurrentAuthStageShowing: Dispatch<SetStateAction<AuthModalsEnum>>
  methodInProgress: AuthMethods
  wagmiConfigs: ReturnType<typeof useWagmi>
  handleLoginSuccess: () => void
} & Pick<ReturnType<typeof useAuthNetwork>, "expectedChain" | "expectedChainName" | "networkMap"> &
  ReturnType<typeof useMetamaskAuth> &
  ReturnType<typeof useFirebaseAuth>

const AuthContext = createContext({} as AuthContextType)

export default AuthContext
export { useAuthContext, AuthProvider }
