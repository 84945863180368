import { isMobile } from "react-device-detect"
import GetStartedProviderCard from "../../components/card/getStartedProviderCard"
import CreateOrImportWalletCard from "../../components/card/createOrImportWalletCard"
import { t } from "i18next"
import Web3ButtonHandler from "../../components/buttons/handler"
import ProviderFAQButton from "../../components/buttons/providerFAQButton"
import Web3InstructionCard from "../../components/card/variants/instructionCard"
import { useTranslation } from "react-i18next"

const Web3MobileIdleConnect = () => {
  if (isMobile) {
    const { t } = useTranslation()

    return (
      // <div
      //   className="d-flex flex-column h-100 align-items-center mx-auto pt-1 justify-content-between"
      //   style={{ maxWidth: "320px", gap: "2.5rem" }}
      // >
      //   <div className="d-flex flex-column h-100 gap-2 mx-auto justify-content-center">
      //     <GetStartedProviderCard provider={selectedWeb3AuthProvider} getStaredFrom="app" />
      //     <CreateOrImportWalletCard />
      //     <Web3InstructionCard title="Tap_the_scan_button" subtitle="Tap_the_scan_button_description" image={ScanIlu} />
      //   </div>
      //   <div className="d-flex flex-column gap-50">
      //     <Web3ButtonHandler onClick={handleConnect} id={`web3-browser-connect`}>
      //       {t("Connect")}
      //     </Web3ButtonHandler>
      //     <ProviderFAQButton provider={selectedWeb3AuthProvider} />
      //   </div>
      // </div>
      <></>
    )
  }

  return null
}

export default Web3MobileIdleConnect
