import AuthInputCaption from "@core/components/login/components/authInputCaption"

import classNames from "classnames"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"
import OTPInput from "react-otp-input"

const OtpInputRow = () => {
  const {
    isEmailAuthLoading,
    handleInputChange,
    formData: { otpCode },
    errors,
    isSubmit
  } = useEmailAuthContext()

  const isLoading = isEmailAuthLoading
  const isInputDisabled = isLoading
  const showError = errors?.otpCode && isSubmit

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center w-100 gap-25">
        <OTPInput
          value={otpCode}
          onChange={(otp) => handleInputChange({ target: { name: "otpCode", value: otp } })}
          numInputs={6}
          inputStyle={{
            width: "3rem",
            height: "3.5rem",
            margin: "0 0.3rem",
            borderRadius: 4,
            border: "1px solid #d8d6de",
            fontSize: "1.8rem",
            color: "#5e5873"
          }}
          inputType="number"
          renderInput={(props, index) => {
            return (
              <>
                <input
                  {...props}
                  id={`OPTInput-${index + 1}`}
                  disabled={isLoading}
                  className={classNames("", {
                    "border-danger": showError
                  })}
                />
              </>
            )
          }}
        />
        <AuthInputCaption errorText={errors?.otpCode} showError={showError} />
      </div>
    </>
  )
}

export default OtpInputRow
