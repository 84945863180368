import { AuthMethods, AuthNetworksEnum } from "contexts/auth/AuthContext"
import AuthButtonContainer, { AuthButtonContainerProps } from "./container"
import AuthButtonText from "./text"
import AuthButtonLogo, { AuthButtonLogoProps } from "./logo"

const AuthButtonRoot = {
  Container: AuthButtonContainer,
  Logo: AuthButtonLogo,
  Text: AuthButtonText
}

export interface AuthButtonProps extends Omit<AuthButtonContainerProps, "children" | "authMethod">, AuthButtonLogoProps {
  authMethod: AuthMethods
}

export default AuthButtonRoot
