import { useContext } from "react"
import MarketPlaceLoginGuardContext from "./marketplaceLoginGuardContext"

const useMarketPlaceLoginGuardContext = () => {
  const context = useContext(MarketPlaceLoginGuardContext)

  if (!context) {
    throw new Error("useMarketPlaceLoginGuardContext must be used within a MarketPlaceLoginGuardProvider")
  }

  return context
}


export default useMarketPlaceLoginGuardContext