import classNames from "classnames"
import React, { Dispatch, SetStateAction, useEffect } from "react"
import { Swiper, SwiperSlide, useSwiper } from "swiper/react/swiper-react"
import { SwiperVariantsRootProps } from "."
import { v4 as uuiv4 } from "uuid"

// ** Third Party Components
import SwiperCore, {
  Grid,
  Lazy,
  Virtual,
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow
} from "swiper"

// ** Styles
import "@core/scss/react/libs/swiper/swiper.scss"

// ** Init Swiper Functions
SwiperCore.use([Navigation, Grid, Pagination, EffectFade, EffectCube, EffectCoverflow, Autoplay, Lazy, Virtual])

interface StandardSwiperProps extends SwiperVariantsRootProps {
  id?: string
  setSwiperHook?: Dispatch<SetStateAction<any>>
}

interface SwiperHandlerHelperI extends Pick<StandardSwiperProps, "setSwiperHook"> {}

const SwiperHandlerHelper = ({ setSwiperHook }: SwiperHandlerHelperI) => {
  const swiper = useSwiper()

  useEffect(() => {
    setSwiperHook(swiper)
  }, [swiper])

  return <></>
}

const StandardSwiper = ({
  children,
  className,
  swiperOptions,
  slideClassName,
  slideStyle = {},
  swiperProps,
  setSwiperHook,
  id,
  ...rest
}: StandardSwiperProps) => {
  return (
    <Swiper id={id} className={classNames("", className)} {...{ ...rest, ...swiperOptions, ...swiperProps }}>
      {React.Children.map(children, (child) => {
        // @ts-ignore-next-line
        const isHidden = child?.props?.isHidden ?? false
        if (child) {
          return (
            <SwiperSlide
              className={classNames("w-100", {
                [slideClassName]: slideClassName,
                "d-none": isHidden
              })}
              style={slideStyle}
              key={uuiv4()}
            >
              {child}
            </SwiperSlide>
          )
        }
      })}

      {setSwiperHook && <SwiperHandlerHelper setSwiperHook={setSwiperHook} />}
    </Swiper>
  )
}

export default StandardSwiper
