import { validateFormErrors } from "validations/validateFormErrors"
import { KycFormData } from "./initialState"
import { kycBasicInfosFormDataValidator } from "@core/components/login/stages/kyc/stages/basic_infos/forms/hooks/validate"

export type KycFormDataValidator = {
  [key in keyof any]: (formData: KycFormData) => string | null | undefined
}

export const kycFormDataValidator: KycFormDataValidator = {
  identityDocument(formData) {
    if (!formData.identityDocument?.document) return "PersonType_is_required"
    return null
  },
  ...kycBasicInfosFormDataValidator
}

const useKycFormDataValidator = (data, singleDataName?) => {
  return validateFormErrors(data, kycFormDataValidator, singleDataName)
}

export default useKycFormDataValidator
