import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { Label } from "reactstrap"

interface LoginInputLabelProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const LoginInputLabel = ({ text, textProps, className, style }: LoginInputLabelProps) => {
  const { t } = useTranslation()

  return (
    <Label className={classNames("fw-bolder ml-1", className)} style={{ fontSize: 14, ...style }}>
      {t(text, textProps)}
    </Label>
  )
}

export default LoginInputLabel
