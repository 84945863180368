import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { Dispatch, SetStateAction } from "react"
import { MemberPermissions } from "services/store"
import { CreateLogger } from "utility/hooks/logger/useLogger"

interface ToggleMarketPlaceAuthGuardGateLockEffectProps {
  createLogger: CreateLogger
  authShields: {
    isMarketPlaceLoginGuardShieldEnabled: boolean
    isPrivateMarketPlaceGuardShieldEnabled: boolean
    isMarketPlaceKycGuardShieldEnabled: boolean
  }
  isGateLocked: boolean
  setIsGateLocked: Dispatch<SetStateAction<boolean>>
  setCurrentAuthStageShowing: Dispatch<SetStateAction<AuthModalsEnum>>
  memberPermissions: MemberPermissions
}

const toggleMarketPlaceAuthGuardGateLock = ({
  createLogger,
  isGateLocked,
  authShields,
  setIsGateLocked,
  setCurrentAuthStageShowing,
  memberPermissions
}: ToggleMarketPlaceAuthGuardGateLockEffectProps) => {
  const logger = createLogger("MarketPlaceAuthGuardGate => Toggle Gate Lock Effect")
  const {
    isPrivateMarketPlaceGuardShieldEnabled,
    isMarketPlaceKycGuardShieldEnabled,
    isMarketPlaceLoginGuardShieldEnabled
  } = authShields || {}
  const isAnyShieldEnabled =
    isPrivateMarketPlaceGuardShieldEnabled || isMarketPlaceKycGuardShieldEnabled || isMarketPlaceLoginGuardShieldEnabled
  if (isAnyShieldEnabled) {
    logger.add("- Auth Gate is Locked")

    if (isMarketPlaceLoginGuardShieldEnabled) {
      logger.add("-- LoginGuard está habilitado")
      logger.add("--- Mostrando tela de Login")
      setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    } else if (isPrivateMarketPlaceGuardShieldEnabled) {
      logger.add("-- PrivateGuard está habilitado")
      logger.add("--- Usuário não é membro da company")
      logger.add("--- Mostrando tela de acesso negado")
      setCurrentAuthStageShowing(AuthModalsEnum.ACCESS_DENIED)
    } else if (isMarketPlaceKycGuardShieldEnabled) {
      logger.add("-- KycGuard está habilitado")
      logger.add("--- Verificando se é membro da coleção")
      if (Object.keys(memberPermissions).length !== 0) {
        if (memberPermissions?.accessLevel === "no-access") {
          logger.add("---- Não é membro da coleção")
          logger.add("---- Mostrando tela de acesso negado")
          setCurrentAuthStageShowing(AuthModalsEnum.ACCESS_DENIED)
        } else {
          logger.add("---- É membro da coleção")
          logger.add("---- Mostrando tela de KYC")
          setCurrentAuthStageShowing(AuthModalsEnum.KYC)
        }
      }
    }

    if (!isGateLocked) setIsGateLocked(true)
  } else {
    logger.add("-Auth gate is unlocked")
    if (isGateLocked) setIsGateLocked(false)
  }
  logger.read()
}

export default toggleMarketPlaceAuthGuardGateLock
