import { Dispatch, SetStateAction, createContext } from "react"

export interface PrivateMarketPlaceGuardStateI {
  isPrivateMarketPlaceGuardEnabled: boolean
  setIsPrivateMarketPlaceGuardEnabled: Dispatch<SetStateAction<boolean>>
}

export interface PrivateMarketPlaceGuardShieldStateI {
  isPrivateMarketPlaceGuardShieldEnabled: boolean
  setIsPrivateMarketPlaceGuardShieldEnabled: Dispatch<SetStateAction<boolean>>
}

export interface PrivateMarketPlaceGuardContextI extends PrivateMarketPlaceGuardStateI, PrivateMarketPlaceGuardShieldStateI {
}

const PrivateMarketPlaceGuardContext = createContext({} as PrivateMarketPlaceGuardContextI)

export default PrivateMarketPlaceGuardContext