import { setShowLoginModal } from "@core/components/login-modal/store"
import useAuthContext from "contexts/auth/AuthInContext"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { removeSession, resetUserKycAndPermissionProcess, setShouldUserDoKyc } from "services/store"
import { removeSessionMetamask } from "services/store/metamask"
import WeispaceAuthContext, { WeispaceAuthContextI } from "./weispaceAuthContext"
import { setActiveTokenMenu } from "web-marketplace/views/token/store"

interface WeispaceAuthProviderProps {
  children: React.ReactNode
}

const WeispaceAuthProvider = ({ children }: WeispaceAuthProviderProps) => {
  const { Provider } = WeispaceAuthContext

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { isUserLoggedIn, setIsUserLoggedIn, setIsAuthModalOpen, handleLoginSuccess } =
    useAuthContext()

  const handleAuthLogout = () => {
    dispatch(removeSession(null))
    dispatch(removeSessionMetamask(null))
    dispatch(resetUserKycAndPermissionProcess(null))
    setIsUserLoggedIn(false)
    dispatch(setActiveTokenMenu("information"))
    navigate(location.pathname, { replace: true })
  }

  useEffect(() => {
    if (isUserLoggedIn) {
      handleLoginSuccess()
      setIsAuthModalOpen(false)
    }
  }, [isUserLoggedIn])

  const providerValues: WeispaceAuthContextI = {
    handleAuthLogout
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default WeispaceAuthProvider
