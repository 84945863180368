import { lazy } from "react"

const LazyTransactions = lazy(() => import("./pages/transactions"))
const LazyTransfers = lazy(() => import("./pages/transfers"))

const mainTransactionsPath = "/weispace/transactions"

export const transactionsPaths = {
  home: mainTransactionsPath,
  transfer: `${mainTransactionsPath}/transfers`
}

const transactionsRoutes = [
  {
    path: transactionsPaths.home,
    element: <LazyTransactions />
  },
  {
    path: transactionsPaths.transfer,
    element: <LazyTransfers />
  }
]

export default transactionsRoutes
