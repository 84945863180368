import { axiosBase } from "../axiosBase"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { CreatedCompany } from "interfaces/company"
import { CollectionWhiteListKYCStatusEnum, CreatedCollection } from "interfaces/collection"
import { CreatedCommunity } from "utility/request-interfaces/community-types"
import { CreatedToken } from "utility/request-interfaces/collection-types"

export const getCompanyBySlug = createAsyncThunk<CreatedCompany, string>("company/getBySlug", async (slug: string) => {
  try {
    return (await axiosBase.get<CreatedCompany>(`/companies/slug/${slug}`))?.data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const getCommunityBySlug = createAsyncThunk<CreatedCommunity, string>(
  "community/getBySlug",
  async (slug: string) => {
    try {
      return (await axiosBase.get<CreatedCommunity>(`/communities/slug/${slug}`))?.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

interface GetCollectionBySlugI extends CreatedCollection {
  haveAccess: boolean
  memberAccessStatus: CollectionWhiteListKYCStatusEnum
}
export const getCollectionBySlug = createAsyncThunk<GetCollectionBySlugI, { slug: string; jwtAccessToken: string }>(
  "collection/getBySlug",
  async ({ jwtAccessToken, slug }) => {
    try {
      return (
        await axiosBase.get<GetCollectionBySlugI>(`/collections/slug/${slug}`, {
          headers: {
            Authorization: `Bearer ${jwtAccessToken}`
          }
        })
      )?.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)

export const getTokenBySlug = createAsyncThunk<CreatedToken, string>("token/getBySlug", async (slug: string) => {
  try {
    return (await axiosBase.get<CreatedToken>(`/tokens/slug/${slug}`))?.data
  } catch (error) {
    throw new Error(error.response.data)
  }
})
