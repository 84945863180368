import { CreateLogger } from "utility/hooks/logger/useLogger"
import { MarketPlaceLoginGuardShieldState, MarketPlaceLoginGuardState } from "../marketplaceLoginGuardContext"
import { isUserLoggedIn } from "utility/Utils"

interface MarketPlaceLoginGuardToggleShieldEffectProps {
  createLogger: CreateLogger
  isGuardEnabled: MarketPlaceLoginGuardState["isMarketPlaceLoginGuardEnabled"]
  isUserLoggedIn: boolean
  isShieldEnabled: MarketPlaceLoginGuardShieldState["isMarketPlaceLoginGuardShieldEnabled"]
  setIsShieldEnabled: MarketPlaceLoginGuardShieldState["setIsMarketPlaceLoginGuardShieldEnabled"]
}

const toggleMarketPlaceLoginGuardShield = ({
  createLogger,
  isGuardEnabled,
  isShieldEnabled,
  isUserLoggedIn,
  setIsShieldEnabled
}: MarketPlaceLoginGuardToggleShieldEffectProps) => {
  const logger = createLogger("MarketPlaceLoginGuardProvider => Toggle Shield Effect")
  if (isUserLoggedIn && isGuardEnabled) {
    logger.add("-- Usuário está logado")
    if (isShieldEnabled) {
      logger.add("--- Desativando Shield")
      setIsShieldEnabled(false)
    } else {
      logger.add("--- Shield já está desativado")
    }
    logger.read()
  } else if (!isUserLoggedIn && isGuardEnabled) {
    logger.add("-- Usuário não está logado")
    if (isShieldEnabled) {
      logger.add("--- Shield já está ativo")
    } else {
      logger.add("--- Ativando Shield")
      setIsShieldEnabled(true)
    }
    logger.read()
  } else if (!isGuardEnabled && isUserLoggedIn) {
    //So para não dar logg
  } else {
    if (isShieldEnabled) {
      logger.add("-- Desativando Shield")
      setIsShieldEnabled(false)
    } else {
      logger.add("-- Shield já está desativado")
    }
    logger.read()
  }
}

export default toggleMarketPlaceLoginGuardShield
