import classNames from "classnames"
import DisplaySource from "components/displaySource"
import { AuthMethods } from "contexts/auth/AuthContext"
import { useTranslation } from "react-i18next"
import useBreakpoint from "utility/hooks/useMatchBreakpoint"

interface AuthButtonTextProps {
  children?: React.ReactNode
  text?: string
}

const AuthButtonText = ({ text = "Connect With", children }: AuthButtonTextProps) => {
  const { t } = useTranslation()

  return (
    <p className={"m-1 my-0 d-flex align-items-center fs-6"}>
      {t(text)} {children && children}
    </p>
  )
}

export default AuthButtonText
