import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface KycDetailedProceedButtonSubTitleProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const KycDetailedProceedButtonSubTitle = ({
  className,
  style,
  text,
  textProps,
  ...rest
}: KycDetailedProceedButtonSubTitleProps) => {
  const {t} = useTranslation()
  return (
    <p className={classNames("m-0", className)} {...rest} style={{...style, color: "#939393"}}>
      {t(text, textProps)}
    </p>
  )
}

export default KycDetailedProceedButtonSubTitle
