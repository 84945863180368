import { UserIdentityDocumentsEnum } from "interfaces/user"
import { KycFormData, KycPersonTypeI, KycPersonalInfosI } from "../../../../context/kycFormHooks/initialState"
import { cnpjMask } from "../components/content/cnpj"
import { cpfMask } from "../components/content/cpf"

interface KycBasicInfosInitialValuesProps {
  kycFormData?: KycFormData
}

const kycBasicInfosInitialValues = ({ kycFormData }: KycBasicInfosInitialValuesProps) => {
  const { fullName, email, birthDate, phone, identityDocument } = kycFormData || {}

  const initialValues: KycPersonalInfosI & KycPersonTypeI = {
    fullName: fullName || "",
    phone: phone || "",
    birthDate: birthDate || "",
    identityDocument,
    email: email || ""
  }

  if (identityDocument?.document === UserIdentityDocumentsEnum.CNPJ) {
    delete initialValues.birthDate
  }

  return initialValues
}

export default kycBasicInfosInitialValues
