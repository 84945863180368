import { MetaMaskAuthStatusState, MetaMaskStatusEnum } from "../metamaskAuthContext"

export enum MetamaskErrors {
  ACTION_REJECTED = "ACTION_REJECTED",
  ChainNotConfigured = "ChainNotConfigured",
  UserRejectedRequestError = "UserRejectedRequestError",
  ResourceUnavailable = "ResourceUnavailable"
}

type KnownErrors = {
  [key in MetamaskErrors]: {
    callback: (
      changeAuthStatus: MetaMaskAuthStatusState["setMetaMaskAuthStatus"],
      authStatus: MetaMaskStatusEnum
    ) => void,
    status: 'dismiss' | 'stack' | MetaMaskStatusEnum
  }
}

const handleMetaMaskError = (error: MetamaskErrors) => {
  const mappedMetaMaskErrors : KnownErrors = {
    ACTION_REJECTED: {
      callback: (changeAuthStatus, authStatus) => changeAuthStatus(authStatus),
      status: 'dismiss'
    },
    ChainNotConfigured: {
      callback: (changeAuthStatus, authStatus) => changeAuthStatus(authStatus),
      status: MetaMaskStatusEnum.RequestedNetwork_Pending
    },
    UserRejectedRequestError: {
      callback: (changeAuthStatus, authStatus) => changeAuthStatus(authStatus),
      status: 'dismiss'
    },
    ResourceUnavailable: {
      callback: (changeAuthStatus, authStatus) => changeAuthStatus(authStatus),
      status: 'stack'
    }
  }

  console.log(error)

  const mappedError = mappedMetaMaskErrors[error]

  if (mappedError) {
    return mappedError
  } else {
    return null
  }
}

export default handleMetaMaskError