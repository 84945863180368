// ** React Imports
import { lazy, useEffect } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { ErrorBoundary } from "react-error-boundary"

// ** Redux Imports
import { store } from "./redux/store"
import { Provider } from "react-redux"

// ** ThemeColors Context

import { ThemeContext } from "./utility/context/ThemeColors"

// ** ThemeConfig
import themeConfig from "./configs/themeConfig"

// ** Toast
import { Toaster, ToastPosition } from "react-hot-toast"

// ** Spinner (Splash Screen)
import Spinner from "./@core/components/spinner/Fallback-spinner"

// ** Ripple Button
import "./@core/components/ripple-button"

// ** PrismJS
import "prismjs"
import "prismjs/themes/prism-tomorrow.css"
import "prismjs/components/prism-jsx.min"

// ** React Perfect Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

// ** React Hot Toast Styles
import "@styles/react/libs/react-hot-toasts/react-hot-toasts.scss"

// ** Core styles
import "./@core/assets/fonts/feather/iconfont.css"
import "./@core/scss/core.scss"
import "./assets/scss/style.scss"

// ** Service Worker
// import * as serviceWorker from "./service-worker"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import { ContextProvider } from "utility/hooks/useContext"

import { HelmetProvider } from "react-helmet-async"

// ** Lazy load app
const LazyMarketplaceApp = lazy(() => import("./web-marketplace/App"))
const LazyWeispaceApp = lazy(() => import("./web-weispace/App"))
const LazyExploreView = lazy(() => import("./views/explore"))
const LazyCampaignRedirect = lazy(() => import("./web-marketplace/views/campaign/pages/redirect/index"))

import { WagmiConfig, createClient, configureChains, chain, Chain } from "wagmi"
import { publicProvider } from "wagmi/providers/public"
import { CookieExpiracyProvider } from "contexts/CookieExpiracyContext"
import "./configs/i18n"
import { AgeGateContext } from "contexts/AgeGateContext"
import { LoadingContext } from "contexts/LoadingContext"
import { CustomDomainContext } from "contexts/CustomDomainContext"
import { defaultWeispaceRoute } from "web-weispace/views"
import WeispaceProviders from "web-weispace/providers"
import AuthProvider from "contexts/auth/AuthProvider"
import MarketPlaceProviders from "web-marketplace/provider/index"
import GenericErrorPage from "views/GenericErrorPage"

const polygonAmoyChain: Chain = {
  id: 80002,
  name: "Polygon Amoy",
  network: "polygon",
  rpcUrls: {
    default: "https://polygon-amoy-bor-rpc.publicnode.com"
  },
  blockExplorers: {
    default: {
      name: "Amoy Explorer",
      url: "https://www.oklink.com/amoy"
    }
  },
  testnet: true
}

const { provider } = configureChains(
  [chain.polygon, chain.polygonMumbai, chain.mainnet, polygonAmoyChain],
  [publicProvider()]
)

const client = createClient({
  autoConnect: true,
  provider
})

const container = document.getElementById("root")
const root = createRoot(container)

const queryClient = new QueryClient()

const GuardApp = ({ children }) => {
  const currentParams = window.location.pathname.split("/")[1]

  if (`/${currentParams}` === defaultWeispaceRoute) {
    return (
      <>
        <WeispaceProviders>
          <LazyWeispaceApp />
          {children}
        </WeispaceProviders>
      </>
    )
  } else if (`/${currentParams}` === "/explore") {
    return <LazyExploreView />
  } else {
    return (
      <MarketPlaceProviders>
        <LazyMarketplaceApp />
        {children}
      </MarketPlaceProviders>
    )
  }
}

const helmetContext = {}
root.render(
  <ErrorBoundary fallbackRender={(error) => <GenericErrorPage error={error} />}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <WagmiConfig client={client}>
          <Provider store={store}>
            <AuthProvider>
              <CookieExpiracyProvider>
                <ContextProvider>
                  <CustomDomainContext>
                    <AgeGateContext>
                      <LoadingContext>
                        <HelmetProvider context={helmetContext}>
                          <ThemeContext>
                            <GuardApp>
                              <Toaster
                                position={
                                  (themeConfig.layout.toastPosition ||
                                    process.env.REACT_APP_TOAST_POSITION) as ToastPosition
                                }
                                toastOptions={{ className: "react-hot-toast text-break" }}
                              />
                            </GuardApp>
                          </ThemeContext>
                        </HelmetProvider>
                      </LoadingContext>
                    </AgeGateContext>
                  </CustomDomainContext>
                </ContextProvider>
              </CookieExpiracyProvider>
            </AuthProvider>
          </Provider>
        </WagmiConfig>
      </QueryClientProvider>
    </BrowserRouter>
  </ErrorBoundary>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register()
// serviceWorkerRegistration.register()
// serviceWorker.register({
//   onUpdate: registration => {
//     // Lógica para notificar o usuário sobre a atualização disponível
//     console.log('Há uma nova versão disponível. Por favor, atualize a página.')
//   },
//   onSuccess: registration => {
//     // Lógica para lidar com o registro bem-sucedido do service worker
//     console.log('Service worker registrado com sucesso.')
//   }
// })
