import classNames from "classnames"
import React from "react"
import { NavItem, NavItemProps, NavLink } from "reactstrap"

interface TabItemProps extends NavItemProps {
  handleOnClick: Function
  isActive: boolean
  children: React.ReactNode
  className?: string
  disabled?: boolean
  style?: React.CSSProperties
}

const TabItem: React.FC<TabItemProps> = ({ children, handleOnClick, className, isActive, disabled, ...rest }) => {
  const handleNavItemClick = (e) => {
    e.preventDefault()
    if (disabled) return
    handleOnClick()
  }

  return (
    <NavItem
      onClick={handleNavItemClick}
      className={classNames("action-button-75", className)}
      aria-checked={isActive || disabled}
      {...rest}
    >
      <NavLink active={isActive}>{children}</NavLink>
    </NavItem>
  )
}

export default TabItem
