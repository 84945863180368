import AuthInputCaption from "@core/components/login/components/authInputCaption"
import LoginInputRoot from "@core/components/login/components/inputs/root"
import useFormContext from "utility/context/formContext/useFormContext"

const KycEmailInput = () => {
  const {
    Container,
    Label,
    Input: { Container: InputContainer, Input, RightSideIcon }
  } = LoginInputRoot
  const { formData, errors, isSubmit, isFormLoading, handleInputChange } = useFormContext()

  const value = formData?.email
  const error = errors?.email
  const showError = isSubmit && error

  return (
    <Container>
      <Label text="Email" />
      <InputContainer>
        <Input
          disabled={true}
          id="kyc_email"
          name="email"
          type="email"
          value={value}
          placeholder="example@email.io"
          showError={showError}
          onChange={handleInputChange}
        />
        <RightSideIcon isLoading={isFormLoading} haveError={showError} size={"1.5rem"} />
      </InputContainer>
      <AuthInputCaption errorText={error} showError={showError} />
    </Container>
  )
}

export default KycEmailInput
