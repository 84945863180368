import AuthInputCaption from "@core/components/login/components/authInputCaption"
import LoginInputRoot from "@core/components/login/components/inputs/root"
import useFormContext from "utility/context/formContext/useFormContext"

const KycBirthDateInput = () => {
  const {
    Container,
    Label,
    Input: { Container: InputContainer, Input, RightSideIcon }
  } = LoginInputRoot
  const { formData, errors, isSubmit, isFormLoading, handleInputChange } = useFormContext()

  const value = formData?.birthDate
  const error = errors?.birthDate
  const showError = isSubmit && error


  return (
    <Container>
      <Label text="BirthDate" />
      <InputContainer>
        <Input
          id="kyc_BirthDate"
          name="birthDate"
          type="date"
          value={value}
          showError={showError}
          onChange={handleInputChange}
        />
      </InputContainer>
      <AuthInputCaption errorText={error} showError={showError} />
    </Container>
  )
}

export default KycBirthDateInput
