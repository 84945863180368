import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface Web3CardTitleProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const Web3CardTitle = ({ className, text, style, textProps = {}, ...rest }: Web3CardTitleProps) => {
  const { t } = useTranslation()
  return (
    <p className={classNames("m-0 fw-bolder fs-6", className)} {...rest} style={style}>
      {t(text, textProps)}
    </p>
  )
}

export default Web3CardTitle
