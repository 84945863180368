import classNames from "classnames"
import { generateGradientColor } from "utility/Utils"

export interface DisplaySourceSkeletonProps {
  containerClassName?: string
  placeholderClassName?: string
  containerStyle?: React.CSSProperties
  placeholderStyle?: React.CSSProperties
  skeletonType?: "glow" | "gradient"
}

const DisplaySourceSkeleton = ({
  containerClassName,
  containerStyle,
  placeholderClassName,
  placeholderStyle,
  skeletonType
}: DisplaySourceSkeletonProps) => {
  return (
    <div
      className={classNames("placeholder-glow w-100 h-100 position-absolute rounded", {
        [containerClassName]: containerClassName,
        "placeholder-glow": skeletonType === "glow"
      })}
      style={{
        background: skeletonType === "gradient" && generateGradientColor(),
        ...containerStyle
      }}
    >
      {skeletonType !== "gradient" && (
        <span
          className={classNames("placeholder w-100 h-100", { [placeholderClassName]: placeholderClassName })}
          style={placeholderStyle}
        />
      )}
    </div>
  )
}

export default DisplaySourceSkeleton
