export const resaleModalFormValidations = (values: any, token, userId) => {
  const ownerData = token?.owners?.find((owner) => owner?.userId === userId)

  const errors: any = {}
  if (values.newPrice === "") {
    errors.newPrice = "Required"
  } else if (+values.newPrice < 0) {
    errors.newPrice = "Must_be_positive"
  } else if (+values.newPrice < 100) {
    errors.newPrice = "Must_be_bigger_than_100"
  }

  if (!values.amount) {
    errors.amount = "Required"
  } else if (+values.amount < 1) {
    errors.amount = "Must_be_greater_than_0"
  } else if (+values.amount > +ownerData?.quantity) {
    errors.amount = "Must_be_less_or_equal_to_owned_amount"
  }
  return errors
}
