import classNames from "classnames"
import Web3CardRoot, { Web3CardRootProps } from "./root"
import { useTranslation } from "react-i18next"

interface Web3CallToActionCardProps extends Web3CardRootProps {
  comingSoon?: boolean
}

const Web3CallToActionCard = ({
  id,
  onClick,
  image,
  subtitle,
  title,
  buttonText,
  titleProps = {},
  subtitleProps = {},
  comingSoon = false
}: Web3CallToActionCardProps) => {
  const { Container, ContentWrapper, ButtonHandler, Image, SubTitle, Title } = Web3CardRoot
  const { t } = useTranslation()

  return (
    <Container
      className={classNames("bg-light rounded-2 ", {
        "opacity-50 position-relative p-1 py-2": comingSoon,
        "p-1": !comingSoon
      })}
      column
    >
      {comingSoon && (
        <div
          className="d-flex align-items-center position-absolute top-0 end-0 bg-info"
          style={{ borderRadius: "0 0.357rem 0 0.357rem", padding: "0.1rem 0.5rem" }}
        >
          <span className="m-0 text-white" style={{ fontSize: "0.9rem" }}>
            {t("Coming Soon")}
          </span>
        </div>
      )}
      <ContentWrapper>
        <Image src={image} />
        <div className="d-flex flex-column">
          <Title text={title} textProps={titleProps} />
          <SubTitle text={subtitle} textProps={subtitleProps} />
        </div>
      </ContentWrapper>
      {onClick && (
        <ButtonHandler onClick={onClick} isDisabled={comingSoon} id={id}>
          {buttonText}
        </ButtonHandler>
      )}
    </Container>
  )
}

export default Web3CallToActionCard
