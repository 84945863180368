import React from "react"
import { useAppSelector } from "redux/hooks"
import MainAuthButton from "../../components/buttons/mainAuthButton"
import { AuthMethods, FirebaseSocialNetworkAuthEnum, useAuthContext } from "contexts/auth/AuthContext"
import classNames from "classnames"

import GoogleLogo from "assets/images/logo/google.png"
import FacebookLogo from "assets/images/logo/facebook.png"
import MicrosoftLogo from "assets/images/logo/microsoft.png"
import AppleLogo from "assets/images/logo/apple.png"
import CompactAuthButton from "../../components/buttons/compactAuthButton"

import useLoginContentContext from "@core/components/login/context/useLoginContentContext"

interface AuthMethodsProps {
  name: AuthMethods
  enabled: boolean
  logo: string
  priority: number
  handleLogin: Function
}

const SocialLogins: React.FC = () => {
  const { isAppleLoginEnabled, isFacebookLoginEnabled, isGoogleLoginEnabled, isMicrosoftLoginEnabled, isMetamaskLoginEnabled } =
    useLoginContentContext()
  const { handleFirebaseAuth } = useAuthContext()

  const authMethodProps: AuthMethodsProps[] = [
    {
      name: "Google",
      enabled: isGoogleLoginEnabled,
      logo: GoogleLogo,
      priority: 1,
      handleLogin: () => handleFirebaseAuth(FirebaseSocialNetworkAuthEnum.GOOGLE)
    },
    {
      name: "Apple",
      enabled: isAppleLoginEnabled,
      logo: AppleLogo,
      priority: 2,
      handleLogin: () => handleFirebaseAuth(FirebaseSocialNetworkAuthEnum.APPLE)
    },
    {
      name: "Facebook",
      enabled: isFacebookLoginEnabled,
      logo: FacebookLogo,
      priority: 3,
      handleLogin: () => handleFirebaseAuth(FirebaseSocialNetworkAuthEnum.FACEBOOK)
    },
    {
      name: "Microsoft",
      enabled: isMicrosoftLoginEnabled,
      logo: MicrosoftLogo,
      priority: 4,
      handleLogin: () => handleFirebaseAuth(FirebaseSocialNetworkAuthEnum.MICROSOFT)
    }
  ]

  const enabledAuthMethods = authMethodProps.filter((authMethod) => authMethod.enabled)

  if (enabledAuthMethods.length === 0) return null

  const sortedAuthMethods = enabledAuthMethods.sort((a, b) => a.priority - b.priority)

  let highlightedMethod
  if (sortedAuthMethods.length > 0) {
    highlightedMethod = sortedAuthMethods[0]
  }

  const MainButtons = () => {
    const mainAuthButton = sortedAuthMethods[0]

    const secondaryMainAuthButton = sortedAuthMethods?.length === 2 && sortedAuthMethods[1]

    return (
      <>
        <MainAuthButton
          authMethod={mainAuthButton?.name}
          handleClickAction={mainAuthButton?.handleLogin}
          src={mainAuthButton?.logo}
        />
        {sortedAuthMethods?.length === 2 && (
          <MainAuthButton
            authMethod={secondaryMainAuthButton?.name}
            handleClickAction={secondaryMainAuthButton?.handleLogin}
            src={secondaryMainAuthButton?.logo}
          />
        )}
      </>
    )
  }

  const SecondaryButtons = () => {
    if (sortedAuthMethods.length <= 2) return null
    const secondaryAuthMethods = sortedAuthMethods.slice(1)
    return (
      <>
        <div className="d-flex gap-75">
          {secondaryAuthMethods.map(({ handleLogin, logo, name }) => {
            return (
              <CompactAuthButton
                authMethod={name}
                src={logo}
                handleClickAction={handleLogin}
                key={name}
                id={`connect-with-${name.toLowerCase()}-button`}
              />
            )
          })}
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className={classNames("d-flex flex-column gap-1 w-100", {
          "justify-content-center h-100": !isMetamaskLoginEnabled
        })}
      >
        <MainButtons />
        <SecondaryButtons />
      </div>
    </>
  )
}

export default SocialLogins
