import FireFoxLogo from "assets/images/logo/Firefox-logo.png"
import OperaLogo from "assets/images/logo/Opera-logo.png"
import ChromeLogo from "assets/images/logo/Chrome-logo.png"
import EdgeLogo from "assets/images/logo/Edge-logo.png"
import BraveLogo from "assets/images/logo/Brave-logo.png"

export enum BrowserNames {
  Firefox = "Firefox",
  Opera = "Opera",
  Chrome = "Chrome",
  Edge = "Edge",
  Brave = "Brave",
  Chromium = "Chromium",
  EdgeChromium = "EdgeChromium",
  Ie = "Ie",
  InternetExplorer = "InternetExplorer",
  MIUI = "MIUI",
  MobileSafari = "MobileSafari",
  Safari = "Safari",
  SamsungBrowser = "SamsungBrowser",
  Yandex = "Yandex"
}

type BrowserLogosMap = {
  [key in BrowserNames]: string
}

const useGetBrowserData = (browserName: BrowserNames) => {
  const browsersLogos: BrowserLogosMap = {
    [BrowserNames.Firefox]: FireFoxLogo,
    [BrowserNames.Opera]: OperaLogo,
    [BrowserNames.Chrome]: ChromeLogo,
    [BrowserNames.Edge]: EdgeLogo,
    [BrowserNames.Brave]: BraveLogo,
    [BrowserNames.Chromium]: "",
    [BrowserNames.EdgeChromium]: "",
    [BrowserNames.Ie]: "",
    [BrowserNames.InternetExplorer]: "",
    [BrowserNames.MIUI]: "",
    [BrowserNames.MobileSafari]: "",
    [BrowserNames.Safari]: "",
    [BrowserNames.SamsungBrowser]: "",
    [BrowserNames.Yandex]: ""
  }

  return {
    logo: browsersLogos[browserName],
    name: browserName
  }
}

export default useGetBrowserData
