import { createSlice } from "@reduxjs/toolkit"
import { CompanyPublicData } from "interfaces/company"
import { getPublicCompanyData } from "./companyPublicDataActions"

const companyPublicDataState = {
  companyPublicData: {} as CompanyPublicData,
  companyPublicLoading: true,
  companyPublicError: undefined
}

const companyPublicDataSlice = createSlice({
  name: "companyPublicData",
  initialState: companyPublicDataState,
  reducers: {
    setCompanyPublicData: (state, { payload }) => {
      state.companyPublicData = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicCompanyData.pending, (state) => {
      state.companyPublicLoading = true
    })
    builder.addCase(getPublicCompanyData.fulfilled, (state, { payload }) => {
      state.companyPublicData = payload
      state.companyPublicLoading = false
    })
    builder.addCase(getPublicCompanyData.rejected, (state, { error }) => {
      state.companyPublicError = error
      state.companyPublicLoading = false
    })
  }
})

export default companyPublicDataSlice.reducer
