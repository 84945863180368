import { useAppSelector } from "redux/hooks"

const useWebStoreWhitelabel = () => {
  const { whitelabel, companyWhitelabel, companyWhitelabelLoading, loading } = useAppSelector(
    (state) => state.whitelabelConfig
  )
  const { isCommunityWhitelabel } = useAppSelector((state) => state.layout)

  //** Is Community Whitelabel ?
  const isWhiteLabelLoading = isCommunityWhitelabel ? loading : companyWhitelabelLoading
  const layout = isCommunityWhitelabel ? whitelabel?.layout : companyWhitelabel?.layout

  //** WebStore Properties
  const layoutWebStore = layout?.webStore
  const webStore = {
    webStoreTitle: layoutWebStore?.title,
    webStoreFavicon: layoutWebStore?.favicon,
    webStoreMetaDescription: layoutWebStore?.metaDescription
  }

  //** Webstore Header
  const webStoreHeader = layoutWebStore?.header
  const header = {
    headerButtonColor: webStoreHeader?.buttonColor,
    headerHoverTextColor: webStoreHeader?.hoverTextColor,
    headerTextColor: webStoreHeader?.textColor,
    headerBackgroundColor: webStoreHeader?.backgroundColor
  }

  //** WebStore Body Properties
  const webStoreBody = layoutWebStore?.body
  const body = {
    bodyTextColor: webStoreBody?.textColor,
    bodyBackgroundColor: webStoreBody?.backgroundColor,
    bodyBackgroundImage: webStoreBody?.backgroundImage,
    bodyButtonColor: webStoreBody?.buttonColor,
    bodyButtonTextColor: webStoreBody?.buttonTextColor,
    bodyInformationBoxes: webStoreBody?.informationBoxes,
    bodyTextColorInTheBox: webStoreBody?.textColorInTheBox,
    bodyExternalLinkColor: webStoreBody?.externalLinkColor,
    bodyCardBackgroundColor: webStoreBody?.cardBackgroundColor,
    bodyCardBadgeColor: webStoreBody?.cardBadgeColor,
    bodyCardTextBadgeColor: webStoreBody?.cardTextBadgeColor,
    bodyCardTextColor: webStoreBody?.cardTextColor,
    bodyCardBackgroundFooterColor: webStoreBody?.cardBackgroundFooterColor,
    bodyIsTextBanner: webStoreBody?.isTextBanner,
    bodyTextBannerPosition: webStoreBody?.textBannerPosition,
    bodyTitleTextBanner: webStoreBody?.titleTextBanner,
    bodyTitleTextBannerDescription: webStoreBody?.titleTextBannerDescription,
    bodyTextBannerColor: webStoreBody?.textBannerColor
  }

  //** WebStore Footer Properties
  const webStoreFooter = layoutWebStore?.footer
  const footer = {
    footerBackgroundColor: webStoreFooter?.backgroundColor,
    footerHoverTextColor: webStoreFooter?.hoverTextColor,
    footerLogo: webStoreFooter?.logo,
    footerTextColor: webStoreFooter?.textColor,
    footerPrivacyPolicyEnabled: webStoreFooter?.isPrivacyPolicyEnabled,
    footerTermsOfUseEnabled: webStoreFooter?.isTermsOfUseEnabled
  }

  // Whitelabel Id properties
  const communityId = whitelabel?.communityId
  const companyId = companyWhitelabel?.companyId ?? whitelabel?.companyId

  return {
    isWhiteLabelLoading,
    body,
    footer,
    webStore,
    header,
    companyId,
    communityId
  }
}

export default useWebStoreWhitelabel
