import classNames from "classnames"
import DisplaySource from "components/displaySource"
import { useTranslation } from "react-i18next"
import useBreakpoint from "utility/hooks/useMatchBreakpoint"

interface LoginStageHeaderProps {
  logo: string
}

const LoginStageHeader = ({ logo }: LoginStageHeaderProps) => {
  return (
    <div className="login-wrapper  align-items-center justify-content-center h-100 w-100" style={{maxHeight: "100px"}}>
      {logo && (
        <>
          <DisplaySource
            src={logo}
            centralized
            contained
            size={{ maxWidth: "18rem" }}
            displayProportion={{ width: 18, height: 6 }}
            containerClassName="px-2"
          />
        </>
      )}
    </div>
  )
}

export default LoginStageHeader
