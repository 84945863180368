import { Input, Label, Spinner } from "reactstrap"
import ButtonHandler from "../../components/buttons/buttonHandler"
import useAuthContext from "contexts/auth/AuthInContext"
import classNames from "classnames"
import Icon from "@core/components/icon"
import { PiWarningCircle } from "react-icons/pi"
import { useTranslation } from "react-i18next"
import AuthInputCaption from "../../components/authInputCaption"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"
import { useEffect, useRef } from "react"
import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { useQuery } from "react-query"
import { getPublicCompanyDataAxios } from "services/companyRequests"
import { onError } from "utility/onError"
import { useAppSelector } from "redux/hooks"

const EmailLogin = () => {
  const { t } = useTranslation()
  const { isFirebaseAuthLoading, isMetamaskAuthLoading, currentAuthStageShowing } = useAuthContext()
  const { formData, handleInputChange, handleSubmit, errors, isSubmit, isEmailAuthLoading, resetEmailStep } =
    useEmailAuthContext()

  const { whitelabel, companyWhitelabel } = useAppSelector((state) => state.whitelabelConfig)
  const companyId = whitelabel?.companyId || companyWhitelabel?.companyId

  const { data: blockDomainsWalletsData, isLoading: blockDomainsWalletsDataIsLoading } = useQuery(
    ["company", companyId],
    () => getPublicCompanyDataAxios(companyId).then((res) => res.data.blockDomainsWallets),
    {
      onError,
      onSuccess: (blockDomainsWalletsData) => {
        handleInputChange({ target: { name: "blockDomainsWalletsData", value: blockDomainsWalletsData }})
      },
      enabled: !!companyId,
      refetchOnWindowFocus: true
    }
  )

  const galoButtonCondition = companyId === "88bf0223-8d5a-41bd-9ed2-4ec36eb9ace4" || companyId === "b82050d1-f35d-4cb8-adba-320b495875a7"
  
  const { email: emailValue } = formData

  const isLoading = isFirebaseAuthLoading || isMetamaskAuthLoading || isEmailAuthLoading
  const isButtonDisabled = isLoading || emailValue?.length === 0
  const isInputDisabled = isLoading
  const showError = errors?.email && isSubmit

  useEffect(() => {
    if (currentAuthStageShowing === AuthModalsEnum.LOGIN) {
      resetEmailStep()
    }
  }, [currentAuthStageShowing])

  return (
    <>
      <form
        onSubmit={handleSubmit}
        style={{ minHeight: "100px" }}
        className="d-flex flex-column gap-25 justify-content-center h-100"
      >
        <div className="d-flex flex-column ">
          <Label className="fw-bolder ml-1" style={{ fontSize: 14 }}>
            {galoButtonCondition ? "Para sócios GNV" : "E-mail"}
          </Label>
          <div className="d-flex flex-column gap-25 w-100">
            <div className="position-relative w-100">
              <Input
                id={"type-email-login-mode"}
                type="text"
                name="email"
                value={emailValue}
                placeholder={galoButtonCondition ? "Preencha com seu e-mail do Galo na Veia" : "example@domain.com"}
                onChange={handleInputChange}
                disabled={isInputDisabled}
                className={classNames("position-relative", {
                  "border-danger": showError,
                  "border-light": !showError
                })}
              />
              <div
                className={classNames("position-absolute", {
                  "d-none": !isEmailAuthLoading && !showError
                })}
                style={{
                  top: "calc(50% - 1rem)",
                  right: "0.4rem"
                }}
              >
                {isEmailAuthLoading && <Spinner size={"2rem"} />}
                {showError && <Icon icon={PiWarningCircle} className="text-danger" size={"2rem"} />}
              </div>
            </div>
            <AuthInputCaption showError={showError} errorText={errors?.email} />
          </div>
        </div>
        <ButtonHandler
          id="buttonHandler-Send"
          type="submit"
          companyId={companyId}
          text={galoButtonCondition ? "Sou sócio GNV" : "Send"}
          disabled={isButtonDisabled} />
      </form>
    </>
  )
}

export default EmailLogin
