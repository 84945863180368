import { createSlice } from "@reduxjs/toolkit"
import { getProducts, getProduct, checkoutProduct, fetchCheckoutTransactionRequestStatus } from "./ecommerceActions"
import { CreatedProduct } from "../product"


interface EcommerceState {
  params: { [x: string]: any } | void
  products: CreatedProduct[]
  productsError: any
  productsIsLoading: boolean
  totalProducts: number
  productDetail: CreatedProduct
  productDetailError: any
  productDetailIsLoading: boolean
  checkoutResponse: any
  checkoutError: any
  checkoutResponseLoading: boolean
  checkoutResponseErrorMessage: any
  checkoutTransactionRequestStatus: string
  checkoutTransactionRequestStatusLoading: boolean
  checkoutTransactionRequestStatusErrorMessage: any
  checkoutPaymentStatus: string
  isPolling: boolean
}
const initialState: EcommerceState = {
  params: {},
  products: [],
  productsError: null,
  productsIsLoading: false,
  totalProducts: 0,

  productDetail: null,
  productDetailError: null,
  productDetailIsLoading: false,

  checkoutError: null,

  checkoutResponse: {
    transactionId: "",
    status: ""
  },
  checkoutResponseLoading: false,
  checkoutResponseErrorMessage: undefined,

  checkoutTransactionRequestStatus: "",
  checkoutTransactionRequestStatusLoading: false,
  checkoutTransactionRequestStatusErrorMessage: undefined,
  checkoutPaymentStatus: "",

  isPolling: false
}

export const appEcommerceSlice = createSlice({
  name: "appEcommerce",
  initialState,
  reducers: {
    setCheckoutResponseErrorMessage(state, { payload }) {
      state.checkoutResponseErrorMessage = payload
    },
    setIsPolling: (state, { payload }) => {
      state.isPolling = payload
    },

    clearCheckoutResponse: (state) => {
      state.checkoutResponse = {
        transactionId: "",
        status: ""
      }
    },
    setCheckoutTransactionRequestStatus: (state, { payload }) => {
      state.checkoutTransactionRequestStatus = payload
    },
    setCheckoutPaymentStatus: (state, { payload }) => {
      state.checkoutPaymentStatus = payload
    },
    resetStates(state) {
      state.checkoutError = null
      state.checkoutResponse = {
        transactionId: "",
        status: ""
      }
      state.checkoutResponseLoading = false
      state.checkoutResponseErrorMessage = undefined
      state.checkoutTransactionRequestStatus = ""
      state.checkoutTransactionRequestStatusLoading = false
      state.checkoutTransactionRequestStatusErrorMessage = undefined
      state.checkoutPaymentStatus = ""
      state.isPolling = false
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProducts.pending, (state, action) => {
        state.productsIsLoading = true
        state.productDetailError = null
      })
      .addCase(getProducts.fulfilled, (state: EcommerceState, action) => {
        //state.params = action.payload.params
        state.products = action.payload.data
        state.totalProducts = action.payload.data.total
        state.productsIsLoading = false
      })
      .addCase(getProducts.rejected, (state, action) => {
        state.productsError = action.payload
        state.productsIsLoading = false
      })
      .addCase(getProduct.pending, (state) => {
        state.productDetailIsLoading = true
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload
        state.productDetailError = null
        state.productDetailIsLoading = false
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.productDetailError = action.payload
        state.productDetailIsLoading = false
      })
      .addCase(checkoutProduct.fulfilled, (state, action) => {
        state.checkoutResponse = action.payload
        state.checkoutError = null
      })
      .addCase(checkoutProduct.rejected, (state, action) => {
        state.checkoutError = action.payload
      })
      .addCase(fetchCheckoutTransactionRequestStatus.pending, (state) => {
        state.checkoutTransactionRequestStatusLoading = true
      })
      .addCase(fetchCheckoutTransactionRequestStatus.fulfilled, (state, { payload }: any) => {
        state.checkoutTransactionRequestStatusLoading = false
        state.checkoutTransactionRequestStatus = payload
      })
      .addCase(fetchCheckoutTransactionRequestStatus.rejected, (state, { payload }) => {
        state.checkoutTransactionRequestStatusLoading = false
        state.checkoutTransactionRequestStatusErrorMessage = payload
      })
  }
})

export const {
  setCheckoutResponseErrorMessage,
  setIsPolling,
  clearCheckoutResponse,
  setCheckoutTransactionRequestStatus,
  setCheckoutPaymentStatus,
  resetStates
} = appEcommerceSlice.actions

export default appEcommerceSlice.reducer
