import axios from "axios"

export const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_WEISPACE_API_URL
})

export const getAxiosConfig = (JWT: string) => {
  return {
    headers: {
      Authorization: `Bearer ${JWT}`
    }
  }
}
