import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import DisplaySource from "components/displaySource"
import { useTranslation } from "react-i18next"
import PolygonLogo from "@core/assets/PolygonLogo.png"
import { Card, Col, Row } from "reactstrap"
import useMetaMaskAuthContext from "contexts/auth/methods/web3/providers/metamask/useMetamaskAuthContext"

interface ChainCardProps {
  chainHex: string
  chainDecimal: string
  currency: string
  chainName: string
  chainLogo: string
}

interface InfoTagProps {
  label: string
  value: string
}

const ChainCard = ({ chainDecimal, chainHex, currency, chainName, chainLogo }: ChainCardProps) => {
  const { t } = useTranslation()
  const { isMetaMaskAuthLoading, handleMetaMaskLogin } = useMetaMaskAuthContext()

  const InfoTag = ({ label, value }: InfoTagProps) => {
    return (
      <Col xs={6} className="d-flex flex-column gap-25">
        <h5 className="m-0 text-muted">{t(label)}</h5>
        <h6 className="m-0 fw-bolder">
          <b>{value}</b>
        </h6>
      </Col>
    )
  }

  return (
    <>

      <div className="d-flex flex-column gap-1 flex-fill align-items-center">
        <h5 className="text-center m-0" style={{ maxWidth: "90%" }}>
          {t("Need_To_Add_Chain_To_MetaMask")}
        </h5>
        <Card
          className="d-flex flex-row gap-1 p-2 w-100 rounded-2 m-0"
          style={{
            backgroundColor: "#F6F6F6",
            border: "1px solid #E1E1E1",
            maxWidth: "320px"
          }}
        >
          <DisplaySource
            src={chainLogo}
            size={{ maxWidth: "2.5rem" }}
            containerClassName="rounded-50"
            containerStyle={{ flexShrink: 0 }}
          />
          <div className="d-flex flex-column gap-1 w-100 py-50">
            <h4 className="m-0 fw-bolder">{chainName}</h4>
            <div className="d-flex gap-1">
              <InfoTag label="ChainId" value={`${chainDecimal} (${chainHex})`} />
              <InfoTag label="Currency" value={currency} />
            </div>
          </div>
        </Card>
      </div>
      <div className="d-flex flex-column align-items-center gap-50">
        <ButtonHandler
          text="Add_To_MetaMask"
          handleClickAction={() => handleMetaMaskLogin()}
          isLoading={isMetaMaskAuthLoading}
        />
        <h6 className="text-center m-0 text-muted" style={{ maxWidth: "80%" }}>
          {t("If_Already_Added_Switch_The_Network")}
        </h6>
      </div>
    </>
  )
}

export default ChainCard
