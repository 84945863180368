import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getTokenBySlug } from "./slugsActions"
import { CreatedToken } from "utility/request-interfaces/collection-types"
import { RootState } from "redux/store"
import axios from "axios"

export const fetchMyWayTokenBenefits = createAsyncThunk<any, any, { state: RootState; rejectValue: any }>(
  "token/benefits",
  async ({ tokenId }: any, thunkAPI) => {
    try {

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/myway/benefits/token/${tokenId}`)
      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

export const fetchMyWayTokenBenefitsByUser = createAsyncThunk<any, any, { state: RootState; rejectValue: any }>(
  "token/benefits/user",
  async ({ tokenId, accessToken }: any, thunkAPI) => {
    try {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/myway/user-benefits/token/${tokenId}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      return data
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data)
    }
  }
)

const tokenBySlugState = {
  tokenBySlug: {} as CreatedToken,
  tokenBySlugLoading: true,
  tokenBySlugError: undefined,

  tokenBenefits: {
    conditions: [],
    formattedConditions: [],
    discounts: []
  },
  tokenBenefitsLoading: true,
  tokenBenefitsError: undefined,

  tokenBenefitsWithUser: {
    conditions: [],
    formattedConditions: [],
    discounts: []
  },
  tokenBenefitsWithUserLoading: true,
  tokenBenefitsWithUserError: undefined
}

const tokenBySlugSlice = createSlice({
  name: "tokenBySlug",
  initialState: tokenBySlugState,
  reducers: {
    settokenBySlug: (state, { payload }) => {
      state.tokenBySlug = payload
    },
    setTokenBenefits: (state, { payload }) => {
      state.tokenBenefits = payload
    },
    resetTokenBySlug: (state) => {
      state.tokenBySlug = {} as CreatedToken
    },
    clearTokenBenefits: (state) => {
      state.tokenBenefits = {
        conditions: [],
        formattedConditions: [],
        discounts: []
      }
    },
    clearTokenBenefitsWithUser: (state) => {
      state.tokenBenefitsWithUser = {
        conditions: [],
        formattedConditions: [],
        discounts: []
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTokenBySlug.pending, (state) => {
      state.tokenBySlugLoading = true
    })
    builder.addCase(getTokenBySlug.fulfilled, (state, { payload }) => {
      state.tokenBySlug = payload
      state.tokenBySlugLoading = false
    })
    builder.addCase(getTokenBySlug.rejected, (state, { error }) => {
      state.tokenBySlugError = error
      state.tokenBySlugLoading = false
    })
    builder.addCase(fetchMyWayTokenBenefits.pending, (state) => {
      state.tokenBenefitsLoading = true
    })
    builder.addCase(fetchMyWayTokenBenefits.fulfilled, (state, { payload }) => {
      const allConditions = payload?.conditions?.filter((condition: any) => condition?.conditions?.length > 0)
      const allFormattedConditions = []
      allConditions.forEach((condition: any) => {
        allFormattedConditions.push(...condition?.formattedConditions)
      })

      state.tokenBenefits = {
        conditions: payload.conditions,
        formattedConditions: allFormattedConditions,
        discounts: payload.discounts
      }

      state.tokenBenefitsLoading = false
    })
    builder.addCase(fetchMyWayTokenBenefits.rejected, (state, { error }) => {
      state.tokenBenefitsError = error
      state.tokenBenefits = {
        conditions: [],
        formattedConditions: [],
        discounts: []
      }
      state.tokenBenefitsLoading = false
    })
    builder.addCase(fetchMyWayTokenBenefitsByUser.pending, (state) => {
      state.tokenBenefitsWithUserLoading = true
    })
    builder.addCase(fetchMyWayTokenBenefitsByUser.fulfilled, (state, { payload }) => {
      const allConditions = payload?.conditions?.filter((condition: any) => condition?.formattedConditions?.length > 0)
      const allFormattedConditions = []
      allConditions.forEach((condition: any) => {
        allFormattedConditions.push(...condition?.formattedConditions)
      })

      state.tokenBenefitsWithUser = {
        conditions: payload.conditions,
        formattedConditions: allFormattedConditions,
        discounts: payload.discounts
      }

      state.tokenBenefitsWithUserLoading = false
    })
    builder.addCase(fetchMyWayTokenBenefitsByUser.rejected, (state, { error }) => {
      state.tokenBenefitsWithUserError = error
      state.tokenBenefitsWithUser = {
        conditions: [],
        formattedConditions: [],
        discounts: []
      }
      state.tokenBenefitsWithUserLoading = false
    })
  }
})

export const { settokenBySlug, setTokenBenefits, clearTokenBenefits, clearTokenBenefitsWithUser, resetTokenBySlug } =
  tokenBySlugSlice.actions

export default tokenBySlugSlice.reducer
