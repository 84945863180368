import classNames from "classnames"
import { forwardRef } from "react"

export interface ContainerProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  Tag?: React.ElementType
}

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, Tag = "div", style, ...rest }, ref) => {
    return (
      <Tag
        ref={ref}
        className={classNames("", {
          [className]: className
        })}
        style={style}
        {...rest}
      >
        {children}
      </Tag>
    )
  }
)

export default Container
