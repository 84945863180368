import { Dispatch, SetStateAction, createContext } from "react"

export enum KycStatusStageEnum {
  START = "START",
  BASIC_INFOS = "BASIC_INFOS",
  CONCLUDED = "CONCLUDED"
}

export enum KycStatusConcludedStagesEnum {
  APPROVED = "APPROVED",
  REFUSED = "REFUSED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  PERMISSION_DENIED = "PERMISSION_DENIED"
}

export interface KycCurrentStatusStageState {
  currentStatusStage: KycStatusStageEnum
  setCurrentStatusStage: Dispatch<SetStateAction<KycStatusStageEnum>>
}

export interface KycCurrentConcludedStatusStageState {
  currentConcludedStatusStage: KycStatusConcludedStagesEnum
  setCurrentConcludedStatusStage: Dispatch<SetStateAction<KycStatusConcludedStagesEnum>>
}

export interface KycSubmissionCreationState {
  submissionCreationIsEnabled: boolean
  setSubmissionCreationIsEnabled: Dispatch<SetStateAction<boolean>>
}

export interface UseKycHookState {
  useKycSwiper: any
  setUseKycSwiper: Dispatch<SetStateAction<any>>
}

export interface KycContextValues
  extends KycCurrentStatusStageState,
    UseKycHookState,
    KycCurrentConcludedStatusStageState,
    KycSubmissionCreationState {
  companyKycStages: object
}

const KycContext = createContext({} as KycContextValues)

export default KycContext
