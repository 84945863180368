import { t } from "i18next"
import toast, { Toast } from "react-hot-toast"

export const onError = (error) => {
  const toastConfig: Partial<
    Pick<Toast, "id" | "icon" | "duration" | "ariaProps" | "className" | "style" | "position" | "iconTheme">
  > = {
    position: "top-center",
    duration: 5000,
    id: "error-toast"
  }
  const status = error?.response ? error.response.status : 500

  const errorMap = {
    400: "ERR_400",
    401: "ERR_401",
    403: "ERR_403",
    404: "ERR_404"
  }

  if (status in errorMap) {
    toast.error(t(errorMap[status]), toastConfig)
    console.error(t(errorMap[status]), error)
    return
  }

  toast.error(t("Generic_toast_error_message"), toastConfig)
  console.error("⚠ Something went wrong: ", error)
}
