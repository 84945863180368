import { Button, ButtonProps } from "reactstrap"

interface KycDetailedProceedButtonContainerProps extends ButtonProps {
  children: React.ReactNode
  LeftIcon?: React.ReactNode
  RightIcon?: React.ReactNode
}

const KycDetailedProceedButtonContainer = ({
  children,
  LeftIcon,
  RightIcon,
  ...rest
}: KycDetailedProceedButtonContainerProps) => {
  return (
    <Button
      color=""
      className="d-flex align-items-center gap-1 w-100 rounded-3"
      style={{
        padding: "1rem 1.25rem",
        border: "2px solid #F1F2F5"
      }}
      {...rest}
    >
      {LeftIcon && <div className="d-flex flex-shrink-1">{LeftIcon}</div>}
      <div className="d-flex flex-column gap-25 text-start">{children}</div>
      {RightIcon && <div className="d-flex flex-shrink-1">{RightIcon}</div>}
    </Button>
  )
}

export default KycDetailedProceedButtonContainer
