import MarketPlaceLoginGuardProvider from "./login/marketplaceLoginGuardProvider"
import MarketPlaceKycGuardProvider from "./kyc/marketPlaceKycGuardProvider"
import PrivateMarketplaceGuardProvider from "./privateMarketplace/privateMarketplaceGuardProvider"
import MarketPlaceAuthGuardGateProvider from "./gate/marketPlaceAuthGuardGateProvider"

interface MarketPlaceAuthGuardProviderProps {
  children: React.ReactNode
}

const MarketPlaceAuthGuardProvider = ({ children }: MarketPlaceAuthGuardProviderProps) => {
  return (
    // <PrivateMarketplaceGuardProvider>
    <MarketPlaceLoginGuardProvider>
      <MarketPlaceKycGuardProvider>
        <MarketPlaceAuthGuardGateProvider>{children}</MarketPlaceAuthGuardGateProvider>
      </MarketPlaceKycGuardProvider>
    </MarketPlaceLoginGuardProvider>
    // </PrivateMarketplaceGuardProvider>
  )
}

export default MarketPlaceAuthGuardProvider
