import { useAppDispatch } from "redux/hooks"
import { CreateLogger } from "utility/hooks/logger/useLogger"
import { MarketPlaceKycGuardStateI, MarketPlaceKycGuardShieldStateI } from "../marketPlaceKycGuardContext"
import { checkIfUserIsAMember, checkUserAccessPermission } from "services/store"

interface CheckUserMarketPlaceGuardKycEffectProps {
  createLogger: CreateLogger
  dispatch: ReturnType<typeof useAppDispatch>
  isUserLoggedIn: boolean
  isGuardEnabled: MarketPlaceKycGuardStateI["isMarketPlaceKycGuardEnabled"]
  isShieldEnabled: MarketPlaceKycGuardShieldStateI["isMarketPlaceKycGuardShieldEnabled"]
  checkData: {
    accessToken: string
    slug: string
  }
  isCollectionParams: boolean
  isTokenParams: boolean
}

const checkUserMarketPlaceGuardKycEffect = ({
  checkData,
  createLogger,
  dispatch,
  isGuardEnabled,
  isShieldEnabled,
  isUserLoggedIn,
  isCollectionParams,
  isTokenParams
}: CheckUserMarketPlaceGuardKycEffectProps) => {
  if (isGuardEnabled && isUserLoggedIn) {
    const logger = createLogger("MarketPlaceKycGuardProvider => Check User Permission")
    logger.add("- Guard está ativo")
    if (isShieldEnabled && (isCollectionParams || isTokenParams)) {
      logger.add("-- Shield está ativo")
      logger.add("-- Checando permissão")
      if (isCollectionParams) {
        dispatch(checkIfUserIsAMember({ ...checkData, origin: "collection" }))
      } else if (isTokenParams) {
        dispatch(checkIfUserIsAMember({ ...checkData, origin: "token" }))
      }
    } else {
      logger.add("-- Shield está desativado")
      logger.add("-- Não é necessário checar permissão")
    }
    logger.read()
  }
}

export default checkUserMarketPlaceGuardKycEffect
