import { lazy } from "react"

const LazyPerfil = lazy(() => import("web-weispace/views/Perfil"))

const mainPerfilPath = "/weispace/profile"

export const perfilPaths = {
  home: mainPerfilPath
}

const perfilRoutes = [
  {
    path: perfilPaths.home,
    element: <LazyPerfil />
  }
]

export default perfilRoutes
