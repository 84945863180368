interface IValidateUserNetwork {
  userChainId: number
  userAddress: string
  expectedChain: number
}

const validateUserNetwork = ({ expectedChain, userAddress, userChainId }: IValidateUserNetwork) => {
  const result = {
    stopProceed: false,
    userAddress,
    userChainId
  }

  if (userChainId !== expectedChain) {
    result.stopProceed = true
    return result
  }
  return result
}

export default validateUserNetwork