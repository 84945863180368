import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { WhiteLabel } from "interfaces/store/whiteLabel"
import { GuardStateEnum } from "interfaces/store/whitelabel-guard"

export const defaultWhiteLabel = {} as WhiteLabel

export const defaultWhiteLabelGuard = {
  private: {
    state: GuardStateEnum["DISABLED"]
  },
  auth: {
    state: GuardStateEnum["DISABLED"],
    kyc: {
      state: GuardStateEnum["DISABLED"]
    }
  }
} as WhiteLabel["guard"]

export const defaultWhiteLabelAuth = {
  auth: {},
  loginLogo: ""
} as { auth: WhiteLabel["auth"]; loginLogo: string }

export const fetchWhiteLabel = createAsyncThunk("whitelabel", async ({ communityId }: WhiteLabel) => {
  try {
    if (!communityId) {
      return {} as WhiteLabel
    }
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/white-label/community/${communityId}`)

    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchCompanyWhiteLabel = createAsyncThunk("whitelabelCompany", async (companyId: string) => {
  try {
    if (!companyId) {
      return {} as WhiteLabel
    }
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/white-label/company/${companyId}`)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchDefaultMarketplace = createAsyncThunk("company/fetchDefaultMarketplace", async (id: string) => {
  if (id) {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${id}/default-marketplace`)
    return data
  }
})

export const fetchCompanyIdByDomain = createAsyncThunk("company/fetchCompanyIdByDomain", async () => {
  const hostname = window.location.hostname

  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/companies/getId/findByCustomDomain?customDomain=${hostname}`
  )
  return data
})

export const fetchDefaultWhitelabel = createAsyncThunk("company/fetchDefaultWhitelabel", async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/white-label/templates/default`)
  return data
})

export enum TableRouteIdentifierEnum {
  COMPANY = "company",
  COMMUNITY = "community",
  COLLECTION = "collection",
  TOKEN = "token"
}

export const fetchMountedCompanyWhiteLabel = createAsyncThunk(
  "whitelabel/fetchMountedCompanyWhiteLabel",
  async ({ tableIdentifier, slug }: { tableIdentifier: TableRouteIdentifierEnum; slug: string }) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/white-label/${tableIdentifier}/${slug}/guard`)
    return data
  }
)

export const whitelabelConfigSlice = createSlice({
  name: "whitelabelConfig",
  initialState: {
    defaultWhiteLabel,
    defaultWhiteLabelLoading: true,

    whitelabel: defaultWhiteLabel,
    loading: true,
    errorMessage: undefined,
    companyWhitelabel: defaultWhiteLabel,
    companyWhitelabelLoading: true,
    companyWhitelabelErrorMessage: undefined,
    companyWhitelabelError: null,

    companyIdByDomain: undefined,
    companyIdByDomainError: false,
    companyIdByDomainErrorMessage: undefined,

    companyDefaultMarketplaceId: undefined,
    companyDefaultMarketplaceType: undefined,
    companyDefaultMarketplaceLoading: false,
    companyDefaultMarketplaceError: false,
    companyDefaultMarketplaceErrorMessage: undefined,

    whiteLabelGuard: defaultWhiteLabelGuard,
    whiteLabelAuth: defaultWhiteLabelAuth,
    whiteLabelIsLoading: true,
    whiteLabelGuardError: false,
    whiteLabelGuardIsComplete: false
  },
  reducers: {
    setWhiteLabelIsLoading: (state, { payload }) => {
      state.whiteLabelIsLoading = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWhiteLabel.pending, (state) => {
        state.loading = true
      })
      .addCase(fetchWhiteLabel.fulfilled, (state, { payload }) => {
        state.loading = false
        state.whitelabel = payload
      })
      .addCase(fetchWhiteLabel.rejected, (state, { payload }) => {
        state.loading = false
        state.errorMessage = payload
      })
      .addCase(fetchCompanyWhiteLabel.pending, (state) => {
        state.companyWhitelabelLoading = true
      })
      .addCase(fetchCompanyWhiteLabel.fulfilled, (state, { payload }) => {
        state.companyWhitelabelLoading = false
        state.companyWhitelabel = payload
      })
      .addCase(fetchCompanyWhiteLabel.rejected, (state, { payload }) => {
        state.companyWhitelabelLoading = false
        state.companyWhitelabelErrorMessage = payload
      })

      .addCase(fetchDefaultMarketplace.pending, (state, { payload }) => {
        state.companyDefaultMarketplaceLoading = true
        state.companyDefaultMarketplaceError = false
      })
      .addCase(fetchDefaultMarketplace.fulfilled, (state, { payload }) => {
        state.companyDefaultMarketplaceLoading = false
        state.companyDefaultMarketplaceError = false
        state.companyDefaultMarketplaceId = payload?.id
        state.companyDefaultMarketplaceType = payload?.type
      })
      .addCase(fetchDefaultMarketplace.rejected, (state, { payload }) => {
        state.companyDefaultMarketplaceLoading = false
        state.companyDefaultMarketplaceError = true
        state.companyDefaultMarketplaceErrorMessage = payload
      })
      .addCase(fetchCompanyIdByDomain.fulfilled, (state, { payload }) => {
        if (payload) {
          state.companyIdByDomainError = false
        } else {
          state.companyIdByDomainError = true
        }
        state.companyIdByDomain = payload
      })
      .addCase(fetchCompanyIdByDomain.rejected, (state, { payload }) => {
        state.companyIdByDomainError = true
        state.companyIdByDomainErrorMessage = payload
      })

      .addCase(fetchDefaultWhitelabel.fulfilled, (state, { payload }) => {
        state.defaultWhiteLabelLoading = false
        state.defaultWhiteLabel = payload
      })

      .addCase(fetchMountedCompanyWhiteLabel.pending, (state) => {
        state.whiteLabelIsLoading = true
        state.whiteLabelGuardError = false
        state.companyWhitelabelError = null
      })
      .addCase(fetchMountedCompanyWhiteLabel.fulfilled, (state, { payload }) => {
        state.companyWhitelabel = payload
        state.whiteLabelIsLoading = false
        state.whiteLabelGuardError = false
        state.whiteLabelGuardIsComplete = true
      })
      .addCase(fetchMountedCompanyWhiteLabel.rejected, (state, { payload }) => {
        state.companyWhitelabelError = true
        state.whiteLabelIsLoading = false
        state.whiteLabelGuardError = true
        state.whiteLabelGuardIsComplete = true
      })
  }
})

export const { setWhiteLabelIsLoading } = whitelabelConfigSlice.actions
export type WhiteLabelConfigState = ReturnType<typeof whitelabelConfigSlice.reducer>
export default whitelabelConfigSlice.reducer
