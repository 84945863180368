import { initializeApp, getApp, getApps } from "@firebase/app"
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from "@firebase/auth"

export enum SignInResponseStatusEnum {
  SUCCESS = "success",
  ERROR = "error"
}

export type SignInResponseData = {
  email?: string
  idToken: string
  socialLoginId: string
}

export type SignInResponseSuccess = {
  status: SignInResponseStatusEnum.SUCCESS
  data: SignInResponseData
}

export type SignInResponseError = {
  status: SignInResponseStatusEnum.ERROR
  data: any
}

export type SignInResponse = SignInResponseSuccess | SignInResponseError

const googleProvider = new GoogleAuthProvider()
const facebookProvider = new FacebookAuthProvider()
const microsoftProvider = new OAuthProvider("microsoft.com")

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  // authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
}

const initialize = (provider) => {
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApp()

  provider.setCustomParameters({ prompt: "select_account" })

  let authFire
  try {
    authFire = getAuth()
  } catch (e) {
    throw e
  }
  return authFire
}

const googleAuth = initialize(googleProvider)
const facebookAuth = initialize(facebookProvider)

function handleSocialSignIn(socialAuth, socialProvider) {
  return signInWithPopup(socialAuth, socialProvider)
    .then(async (result) => {
      const email = result.user.email
      const idToken = await result.user.getIdToken()
      const socialLoginId = result.user.uid

      if (email) {
        return {
          status: SignInResponseStatusEnum.SUCCESS,
          data: {
            email,
            idToken,
            socialLoginId
          }
        }
      }

      return {
        status: SignInResponseStatusEnum.SUCCESS,
        data: {
          idToken,
          socialLoginId
        }
      }
    })
    .catch((error) => {
      return { status: SignInResponseStatusEnum.ERROR, data: error }
    })
}

export const firebaseGoogleSignIn = () => {
  return handleSocialSignIn(googleAuth, googleProvider)
}

export const firebaseFacebookSignIn = () => {
  return handleSocialSignIn(facebookAuth, facebookProvider)
}

export const firebaseAppleSignIn = () => {
  const appleProvider = new OAuthProvider("apple.com")
  appleProvider.addScope("email")
  appleProvider.addScope("name")
  const appleAuth = initialize(appleProvider)
  return handleSocialSignIn(appleAuth, appleProvider)
}

export const firebaseMicrosoftSignIn = () => {
  const microsoftProvider = new OAuthProvider("microsoft.com")
  microsoftProvider.setCustomParameters({
    prompt: "consent",
    tenant: "common",
    scope: "openid offline_access profile email User.Read"
  })
  const microsoftAuth = initialize(microsoftProvider)
  return handleSocialSignIn(microsoftAuth, microsoftProvider)
}
