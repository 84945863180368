import classNames from "classnames"

interface WeispaceLoginBannerContainerProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const WeispaceLoginBannerContainer = ({ children, className, ...rest }: WeispaceLoginBannerContainerProps) => {
  return (
    <div className={classNames("d-flex flex-column align-items-center justify-content-center px-2 h-100", className)} {...rest}>
      {children}
    </div>
  )
}

export default WeispaceLoginBannerContainer
