import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import MarketPlaceLoginGuardContext, { MarketPlaceLoginGuardContextI } from "./marketplaceLoginGuardContext"
import { useAppSelector } from "redux/hooks"
import useAuthContext from "contexts/auth/AuthInContext"
import defaultMarketPlaceLoginGuardEffect from "./effectsCallbacks/default"
import useLogger from "utility/hooks/logger/useLogger"
import toggleMarketPlaceLoginGuardShield from "./effectsCallbacks/toggleShield"
import { GuardStateEnum } from "interfaces/store/whitelabel-guard"

interface MarketPlaceLoginGuardProviderProps {
  children: React.ReactNode
}

const MarketPlaceLoginGuardProvider = ({ children }: MarketPlaceLoginGuardProviderProps) => {
  const { Provider } = MarketPlaceLoginGuardContext
  const { isUserLoggedIn } = useAuthContext()
  const { createLogger } = useLogger(true)
  const { companyWhitelabel } = useAppSelector((state) => state.whitelabelConfig)

  const isLoginRequired = companyWhitelabel?.guard?.auth?.state === GuardStateEnum["REQUIRED"] || false

  const [isGuardEnabled, setIsGuardEnabled] = useState<boolean>(isLoginRequired)
  const [isShieldEnabled, setIsShieldEnabled] = useState<boolean | null>(isLoginRequired && !isUserLoggedIn)
  const [isLayoutRender, setIsLayoutRender] = useState<boolean>(true)

  useLayoutEffect(() => {
    defaultMarketPlaceLoginGuardEffect({
      createLogger,
      isGuardEnabled,
      isLoginRequired,
      isShieldEnabled,
      setIsGuardEnabled,
      setIsShieldEnabled,
      isUserLoggedIn
    })

    setIsLayoutRender(false)
  }, [companyWhitelabel])

  useEffect(() => {
    if (!isLayoutRender) {
      toggleMarketPlaceLoginGuardShield({
        createLogger,
        isGuardEnabled,
        isShieldEnabled,
        isUserLoggedIn,
        setIsShieldEnabled
      })
    }
  }, [isUserLoggedIn])

  const values: MarketPlaceLoginGuardContextI = {
    isMarketPlaceLoginGuardEnabled: isGuardEnabled,
    setIsMarketPlaceLoginGuardEnabled: setIsGuardEnabled,
    isMarketPlaceLoginGuardShieldEnabled: isShieldEnabled,
    setIsMarketPlaceLoginGuardShieldEnabled: setIsShieldEnabled
  }

  const providerValues = useMemo(() => values, [Object.values(values)])

  return <Provider value={providerValues}>{children}</Provider>
}

export default MarketPlaceLoginGuardProvider
