import classNames from "classnames"
import { DisplaySourceTypeProps } from "."
import { getIpfsImageUrl } from "utility/ipfs"

interface DisplaySourceImageProps extends DisplaySourceTypeProps {}

const DisplaySourceImage = ({ src, displayClassName, displayStyle, alt }: DisplaySourceImageProps) => {
  return (
    <img
      alt={alt}
      src={getIpfsImageUrl(src, 1024)}
      className={classNames("display-source-image", { [displayClassName]: displayClassName })}
      style={{
        ...displayStyle
      }}
    />
  )
}

export default DisplaySourceImage
