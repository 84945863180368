import WeispaceLoginBannerRoot from "./root"
import WelcomeImage from "web-weispace/assets/images/pages/login/welcome.png"

const WeispaceLoginWelcomeBanner = () => {
  const { Description, Image, Title, Container } = WeispaceLoginBannerRoot

  return (
    <Container>
      <Image src={WelcomeImage} />
      <div className="d-flex flex-column gap-1 w-100">
        <Title text="Weispace_Login_Banner_Welcome_Title" />
        <Description text="Weispace_Login_Banner_Welcome_Description" />
      </div>
    </Container>
  )
}

export default WeispaceLoginWelcomeBanner
