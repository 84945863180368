import classNames from "classnames"
import { SwiperVariantsRootProps } from "."
import { SwiperOptions } from "swiper"
import StandardSwiper from "./standard"
import { AutoplayOptions, PaginationOptions } from "swiper/types"

interface PaginationSwiperOptionsProps
  extends Omit<SwiperOptions, "pagination" | "loop" | "autoplay" | "slidesPerView"> {
  pagination?: Omit<PaginationOptions, "clickable">
  autoplay?: Pick<AutoplayOptions, "waitForTransition" | "reverseDirection">
}

interface PaginationSwiperProps extends SwiperVariantsRootProps {
  clickable?: boolean
  loop?: boolean
  delay?: number
  disableOnInteraction?: boolean
  pauseOnMouseEnter?: boolean
  stopOnLastSlide?: boolean
  slidesPerView?: number
  swiperOptions?: PaginationSwiperOptionsProps
}

  /**
   * Props for the PaginationSwiper component.
   *
   * @property {React.ReactNode} children - The content to be displayed inside the Swiper.
   * @property {string} [className] - Additional CSS class for styling.
   * @property {React.CSSProperties} [style] - Inline styles for the Swiper.
   * @property {SwiperOptions} [swiperOptions] - Configuration options for the Swiper.
   * @property {string} [slideClassName] - Additional CSS class for styling individual slides.
   * @property {React.CSSProperties} [slideStyle] - Inline styles for individual slides.
   * @property {boolean} [clickable] - Enable clickable pagination.
   * @property {boolean} [loop] - Enable or disable the loop mode.
   * @property {number} [delay] - Autoplay delay in milliseconds.
   * @property {boolean} [disableOnInteraction] - Disable autoplay on interaction.
   * @property {boolean} [pauseOnMouseEnter] - Pause autoplay on mouse enter.
   * @property {boolean} [stopOnLastSlide] - Stop autoplay on the last slide.
   * @property {number} [slidesPerView] - Number of slides per view.
   */
const PaginationSwiper = ({
  children,
  className,
  clickable = true,
  loop = false,
  delay = 8000,
  disableOnInteraction = true,
  pauseOnMouseEnter = true,
  stopOnLastSlide = true,
  slidesPerView = 1,
  swiperOptions,
  ...rest
}: PaginationSwiperProps) => {
  const { pagination, autoplay, ...swiperOptionsRest } = swiperOptions || {}

  const paginationSwiperOptions: SwiperOptions = {
    ...swiperOptionsRest,
    slidesPerView,
    pagination: {
      clickable,
      ...pagination
    },
    loop,
    autoplay: {
      delay,
      disableOnInteraction,
      pauseOnMouseEnter,
      stopOnLastSlide,
      ...autoplay
    }
  }

  return (
    <StandardSwiper swiperOptions={paginationSwiperOptions} className={classNames("", className)} {...rest}>
      {children}
    </StandardSwiper>
  )
}

export default PaginationSwiper
