import Web3CardRoot, { Web3CardRootProps } from "./root"

interface Web3InstructionCardProps extends Web3CardRootProps {}

const Web3InstructionCard = ({ image, subtitle, title, subtitleProps, titleProps }: Web3InstructionCardProps) => {
  const { Container, Image, SubTitle, Title } = Web3CardRoot

  return (
    <Container gap="gap-1">
      <Image
        src={image}
        containerClassName={"bg-light rounded-3 p-50 overflow-hidden "}
        containerStyle={{
          border: ""
        }}
      />
      <div className="d-flex flex-column border-">
        <Title text={title} textProps={titleProps} />
        <SubTitle text={subtitle} textProps={subtitleProps} />
      </div>
    </Container>
  )
}

export default Web3InstructionCard
