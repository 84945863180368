import { lazy } from "react"

const LazyBenefits = lazy(() => import("./pages/benefits"))
const LazyBenefitDetails = lazy(() => import("./pages/details/benefit-details"))

const mainBenefitsPath = "/weispace/benefits"

export const benefitsPaths = {
  home: mainBenefitsPath,
  details: (benefitId) => `${mainBenefitsPath}/${benefitId}`
}

const benefitsRoutes = [
  {
    path: benefitsPaths.home,
    element: <LazyBenefits />
  },
  {
    path: benefitsPaths.details(":benefitId"),
    element: <LazyBenefitDetails />
  }
]

export default benefitsRoutes
