import GetWeb3DesktopWallet from "./desktop"
import GetWeb3MobileWallet from "./mobile"

const GetWeb3Wallet = () => {
  return (
    <div className="login-wrapper h-100 justify-content-center">
      <GetWeb3DesktopWallet />
      <GetWeb3MobileWallet />
    </div>
  )
}

export default GetWeb3Wallet