import classNames from "classnames"

interface Web3CardContainerProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  column?: boolean
  gap?: string
  [key: string]: any
}

const Web3CardContainer = ({
  children,
  className,
  gap = "gap-50",
  column = false,
  style,
  ...rest
}: Web3CardContainerProps) => {
  return (
    <div
      className={classNames("d-flex align-items-center", {
        [className]: className,
        "flex-column": column,
        [gap]: gap
      })}
      style={style}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Web3CardContainer
