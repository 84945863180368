import classNames from "classnames"
import React from "react"
import { useTranslation } from "react-i18next"
import { validate } from "validations"

type TypographyTag = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "span" | "p" | "a" | "small"
type TypographyFont = "fs-6" | "fs-5" | "fs-4" | "fs-3" | "fs-2" | "fs-1" | "fs-0"

export interface TypographyProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode
  bolder?: boolean
  bold?: boolean
  muted?: boolean
  center?: boolean
  darkMuted?: boolean
  lineHeight?: number | string
  textOptions?: { [key: string]: any }
  className?: string
  font?: TypographyFont
  style?: React.CSSProperties
  Tag?: TypographyTag
}

const Typography = ({
  Tag = "p",
  children,
  className,
  style,
  textOptions,
  bolder = false,
  bold = false,
  muted = false,
  darkMuted = false,
  center = false,
  font,
  lineHeight,
  ...rest
}: TypographyProps) => {
  const { t } = useTranslation()

  const translateChild = (child: React.ReactNode) => {
    if (typeof child === "string") {
      if (!validate.isInvalidUrl(child)) {
        return <>{child}</>
      }
      return <>{t(child, textOptions)}</>
    }
    return child
  }

  const translatedChildren = React.Children.map(children, translateChild)

  return (
    <Tag
      className={classNames("m-0", {
        [className!]: className,
        [font]: font && Tag !== "small",
        "fw-bolder": bolder,
        "fw-bold": bold,
        "text-muted": muted,
        "text-muted-dark": darkMuted,
        "text-center": center
      })}
      style={{
        lineHeight,
        ...style
      }}
      {...rest}
    >
      {translatedChildren}
    </Tag>
  )
}

export default Typography
