import MarketPlaceAuthProvider from "web-marketplace/contexts/auth/marketplaceAuthProvider"
import MarketPlaceGuardProvider from "./guard"
import MountMarketPlaceProvider from "./mountMarketPlace"

const MarketPlaceProviders = ({ children }) => {
  return (
    <>
      <MountMarketPlaceProvider>
        <MarketPlaceAuthProvider>
          <MarketPlaceGuardProvider>{children}</MarketPlaceGuardProvider>
        </MarketPlaceAuthProvider>
      </MountMarketPlaceProvider>
    </>
  )
}

export default MarketPlaceProviders
