import BackButtonHandler from "@core/components/login/components/buttons/backButtonHandler"
import { useTranslation } from "react-i18next"

interface Web3IdleHeaderProps {
  backAction: Function
  title: string
  titleOptions?: { [key: string]: string }
}

const Web3IdleHeader = ({ backAction, title, titleOptions = {} }: Web3IdleHeaderProps) => {
  const { t } = useTranslation()

  return (
    <div className="login-header w-100">
      <BackButtonHandler handleClickAction={backAction} disabled={false} />
      <h4 className="m-0 text-center fw-bolder">{t(title, titleOptions)}</h4>
    </div>
  )
}

export default Web3IdleHeader
