import { lazy } from "react"

const LazyProducts = lazy(() => import("./pages/for-sale/home"))
const LazyProductsDetails = lazy(() => import("./pages/for-sale/details"))
const LazyProductsMyPurchases = lazy(() => import("./pages/my-purchases/home"))
const LazyProductsMyPurchasesInstructions = lazy(() => import("./pages/my-purchases/instructions"))

export const mainProductsPath = `/weispace/products`

export const productPaths = {
  home: `${mainProductsPath}/for-sale`,
  details: (product) => `${mainProductsPath}/for-sale/details/${product}`,
  my_purchases: `${mainProductsPath}/my-purchases`,
  my_purchases_instructions: (product) => `${mainProductsPath}/my-purchases/instructions/${product}`
}

const productsRoutes = [
  {
    path: productPaths.home,
    element: <LazyProducts />
  },
  {
    path: productPaths.details(":product"),
    element: <LazyProductsDetails />
  },
  {
    path: productPaths.my_purchases,
    element: <LazyProductsMyPurchases />
  },
  {
    path: productPaths.my_purchases_instructions(":product"),
    element: <LazyProductsMyPurchasesInstructions />
  }
]

export default productsRoutes
