import { lazy } from "react"

const LazyStickerAlbum = lazy(() => import("./pages/home"))
const LazyStickerDetails = lazy(() => import("./pages/details"))

const mainStickerAlbumPath = "/weispace/sticker-album"

export const stickerAlbumPaths = {
  home: mainStickerAlbumPath,
 details: (tokenId) => `${mainStickerAlbumPath}/${tokenId}`
}

const stickerAlbumRoutes = [
  {
    path: stickerAlbumPaths.home,
    element: <LazyStickerAlbum />
  },
  {
    path: stickerAlbumPaths.details(":tokenId"),
    element: <LazyStickerDetails />
  }
]

export default stickerAlbumRoutes
