import { validateFormErrors } from "validations/validateFormErrors"
import { EmailAuthData, EmailAuthStepStages } from "./useEmailForm"
import { validate } from "validations"

export type EmailAuthFormDataValidator = {
  [key in keyof Pick<EmailAuthData, "email" | "otpCode" | "blockDomainsWalletsData">]: (
    formData: EmailAuthData | undefined
  ) => string | undefined | null
}

const useEmailAuthFormDataValidator = (data, singleDataName?) => {
  const emailAuthFormDataValidator: EmailAuthFormDataValidator = {
    email(formData) {
      if (formData["stepStage"] === EmailAuthStepStages.email_form) {
        if (validate.isEmpty(data["email"])) {
          return "Email_Required_Error"
        }
        if (validate.isInvalidEmail(data["email"])) {
          return "Email_Invalid_Error"
        }
        if (validate.isEmailBlockList(data["email"], data.blockDomainsWalletsData)) {
          return "Email_Block_Error"
        }
      }
    },
    otpCode(formData) {
      if (formData["stepStage"] === EmailAuthStepStages.otp_verification) {
        if (validate.isEmpty(data["otpCode"])) {
          return "Please enter your code"
        }
        // if (validate.isMinorThan(data["otpCode"], 6)) {
        //   return "Please enter a valid code"
        // }
        // if (validate.isGreaterThan(data["otpCode"], 6)) {
        //   return "Please enter a valid code"
        // }
      }
    }
  }

  return validateFormErrors(data, emailAuthFormDataValidator, singleDataName)
}

export default useEmailAuthFormDataValidator
