import { useEffect, useState } from "react"
import FallbackLoading from "../fallbackLoading"
import useLogger from "utility/hooks/logger/useLogger"
import useWhiteLabelContext from "web-marketplace/contexts/mount/whitelabel/useWhiteLabelContext"
import useCustomDomainContext from "web-marketplace/contexts/mount/customDomain/useCustomDomainContext"
import { useAppSelector } from "redux/hooks"
import ErrorPageNotFound from "views/error404"

interface MountMarketPlaceProviderProps {
  children: React.ReactNode
}

const MountMarketPlaceGuardProvider = ({ children }: MountMarketPlaceProviderProps) => {
  const { whiteLabelIsFetched: whitelabelIsMounted } = useWhiteLabelContext()
  const { shouldFetchCustomDomain } = useCustomDomainContext()
  const { createLogger } = useLogger(true)
  const { companyWhitelabelError } = useAppSelector((state) => state.whitelabelConfig)
  const [isMarketPlaceMounted, setIsMarketPlaceMounted] = useState(false)

  useEffect(() => {
    const logger = createLogger("MountMarketPlaceProvider => Default Effect")
    const customDomainIsMounted = !shouldFetchCustomDomain
    logger.add(`- WhiteLabel está carregado? : ${whitelabelIsMounted}`)
    logger.add(`- CustomDomain está carregado? : ${customDomainIsMounted}`)
    const isMounted = whitelabelIsMounted && customDomainIsMounted

    if (isMounted) {
      logger.add("- Todos providers estão carregados")
      logger.add("- MarketPlace está montado")
      setIsMarketPlaceMounted(true)
      logger.read()
    } else if (!isMounted && isMarketPlaceMounted) {
      logger.add("- MarketPlace não está montado")
      setIsMarketPlaceMounted(false)
      logger.read()
    }
  }, [whitelabelIsMounted, shouldFetchCustomDomain])

  if (isMarketPlaceMounted && !companyWhitelabelError) {
    return <>{children}</>
  } else if (isMarketPlaceMounted && companyWhitelabelError) {
    return <ErrorPageNotFound />
  } else {
    return <FallbackLoading />
  }
}

export default MountMarketPlaceGuardProvider
