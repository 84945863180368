import WeispaceLoginBannerContainer from "./container"
import WeispaceLoginBannerDescription from "./description"
import WeispaceLoginBannerImage from "./image"
import WeispaceLoginBannerTitle from "./title"

const WeispaceLoginBannerRoot = {
  Container: WeispaceLoginBannerContainer,
  Image: WeispaceLoginBannerImage,
  Title: WeispaceLoginBannerTitle,
  Description: WeispaceLoginBannerDescription
}

export default WeispaceLoginBannerRoot