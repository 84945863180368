import KycDetailedProceedButtonContainer from "./container"
import KycDetailedProceedButtonSubTitle from "./subTitle"
import KycDetailedProceedButtonTitle from "./title"

const KycDetailedProceedButtonRoot = {
  Container: KycDetailedProceedButtonContainer,
  Title: KycDetailedProceedButtonTitle,
  SubTitle: KycDetailedProceedButtonSubTitle
}

export default KycDetailedProceedButtonRoot