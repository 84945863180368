import { Dispatch, SetStateAction } from "react"
import { CreateLogger } from "utility/hooks/logger/useLogger"

interface MarketPlaceCustomDomainChangeLocationEffectProps {
  createLogger: CreateLogger
  defaultRoute: string
  defaultMarketPlaceLink: string
  setShouldFetchCustomDomain: Dispatch<SetStateAction<boolean>>
  navigate: (path: string) => void
  actualPath: string
}

/**
 * Caso o usuário acesse uma rota que não existe, ele será redirecionado para a rota padrão
 * @param createLogger - Função para criar um logger
 * @param defaultRoute - Rota padrão
 * @param defaultMarketPlaceLink - Link padrão
 * @param setShouldFetchCustomDomain - Função para alterar o estado de shouldFetchCustomDomain
 * @param navigate - Função para navegar para uma rota
 * @param actualPath - Rota atual
 */
const changeLocationMarketPlaceCustomDomainEffect = ({
  createLogger,
  defaultMarketPlaceLink,
  defaultRoute,
  setShouldFetchCustomDomain,
  navigate,
  actualPath
}: MarketPlaceCustomDomainChangeLocationEffectProps) => {

  const logger = createLogger("MarketPlaceCustomDomainProvider => Change Location Effect")
  if (defaultRoute && defaultMarketPlaceLink !== actualPath) {
    logger.add(`- Rota padrão: ${defaultRoute}`)
    logger.add(`- Link padrão: ${defaultMarketPlaceLink}`)
    logger.add("-- CustomDomainProvider => Loaded")
    navigate(defaultMarketPlaceLink)
    setShouldFetchCustomDomain(false)
    logger.read()
  }
}

export default changeLocationMarketPlaceCustomDomainEffect
