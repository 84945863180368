import { CreatedToken } from "web-weispace/services/tokens/types"

interface FilterTokensProps {
  tokens: CreatedToken[]

  selectedCommunityId?: string
  selectedCollectionId?: string
  selectedTokenType?: string
}

const useFilterTokens = ({
  tokens,
  selectedCollectionId = "",
  selectedCommunityId = "",
  selectedTokenType = ""
}: FilterTokensProps) => {
  const filteredTokens = tokens?.filter((token) => {
    const filterByCommunity = !selectedCommunityId || token?.communityId === selectedCommunityId
    const filterByTransactionType =
      (!selectedTokenType ||
        token?.transactionType === selectedTokenType ||
        (token?.transactionType === "poap_zk" && selectedTokenType === "poap")) &&
      token?.transactionType !== "utility"
    const filterByCollection = !selectedCollectionId || token?.collectionId === selectedCollectionId

    if (filterByCollection && filterByCommunity && filterByTransactionType) {
      return token
    }
  })

  return filteredTokens
}

export default useFilterTokens
