import { Dispatch, SetStateAction, createContext } from "react"

export interface MarketPlaceLoginGuardState {
  isMarketPlaceLoginGuardEnabled: boolean
  setIsMarketPlaceLoginGuardEnabled: Dispatch<SetStateAction<boolean>>
}

export interface MarketPlaceLoginGuardShieldState {
  isMarketPlaceLoginGuardShieldEnabled: boolean
  setIsMarketPlaceLoginGuardShieldEnabled: Dispatch<SetStateAction<boolean>>
}

export interface MarketPlaceLoginGuardContextI extends MarketPlaceLoginGuardState, MarketPlaceLoginGuardShieldState {}

const MarketPlaceLoginGuardContext = createContext({} as MarketPlaceLoginGuardContextI)

export default MarketPlaceLoginGuardContext
