import { createContext } from "react"

export interface WhitelabelContextProps {
  isCustomDomain: boolean
  setIsCustomDomain: (value: boolean) => void
  initialCompanyIdCookie: string | null
  initialCommunityIdCookie: string | null
  initialCompanyIdParam: string | null
  initialCommunityIdParam: string | null
  shouldFetchCustomDomain: boolean
  setShouldFetchCustomDomain: (value: boolean) => void
}

export const WhitelabelContext = createContext<WhitelabelContextProps | undefined>(undefined)
