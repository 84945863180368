import { lazy } from "react"

const LazyCampaigns = lazy(() => import("./pages/campaigns"))
const LazyCampaignsDetails = lazy(() => import("./pages/details/index"))

const mainCampaignsPath = "/weispace/campaigns"

export const campaignsPaths = {
  home: mainCampaignsPath,
  active: `${mainCampaignsPath}/actives`,
  expired: `${mainCampaignsPath}/expired`,
  details: (campaignId) => `${mainCampaignsPath}/details/${campaignId}`
}

const campaignsRoutes = [
  {
    path: campaignsPaths.home,
    element: <LazyCampaigns />
  },
  {
    path: campaignsPaths.home,
    element: <LazyCampaigns />
  },
  {
    path: campaignsPaths.home,
    element: <LazyCampaigns />
  },
  {
    path: campaignsPaths.details(":campaignId"),
    element: <LazyCampaignsDetails />
  }
]

export default campaignsRoutes
