import LoadingModal from "components/modals/loadingModal"
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react"

type LoadingContextType = {
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  loadingChildrenComponent: JSX.Element
  setLoadingChildrenComponent: Dispatch<SetStateAction<JSX.Element>>
  whitelabelLoading: any
  setWhitelabelLoading: Dispatch<SetStateAction<any>>
}

export const Loading = createContext({} as LoadingContextType)

export const LoadingContext = ({ children }: { children: ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loadingChildrenComponent, setLoadingChildrenComponent] = useState(<LoadingModal />)
  const [whitelabelLoading, setWhitelabelLoading] = useState(null)

  const contextValues = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      loadingChildrenComponent,
      setLoadingChildrenComponent,
      whitelabelLoading,
      setWhitelabelLoading
    }),
    [isLoading, loadingChildrenComponent, whitelabelLoading]
  )

  return <Loading.Provider value={contextValues}>{children}</Loading.Provider>
}

export function useLoadingContext() {
  return useContext(Loading)
}
