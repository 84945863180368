import DisplaySource from "components/displaySource"
import { QRCode } from "react-qrcode-logo"

const QrCodeLogoMask = ({ logo }: { logo: string }) => {
  return (
    <>
      <div className="position-absolute rounded-3 w-100 h-100">
        <div
          className="border rounded-3 position-absolute p-50 bg-white"
          style={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#c1c1c1",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "18%",
            height: "18%"
          }}
        >
          <DisplaySource src={logo} centralized />
        </div>
      </div>
    </>
  )
}

const Web3QrCode = ({ value, logo } : {value: string, logo?: string}) => {
  const outerSize: [number, number, number, number] = [10, 10, 10, 10]
  const innerSize: [number, number, number, number] = [5, 5, 5, 5]
  return (
    <>
      <div className="w-100 h-100 p-1">
        <div
          className="position-relative d-flex align-items-center justify-content-center bg-white full-canvas  rounded-3 p-25 mx-auto"
          style={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#c1c1c1",
            maxWidth: "375px"
          }}
        >
          <QRCode
            value={value}
            ecLevel="H"
            size={280}
            logoImage={logo && logo}
            logoPadding={logo && 15}
            logoWidth={logo && 20}
            qrStyle="squares"
            eyeRadius={[
              {
                outer: outerSize,
                inner: innerSize
              },
              {
                outer: outerSize,
                inner: innerSize
              },
              {
                outer: outerSize,
                inner: innerSize
              }
            ]}
            eyeColor={"#000000"}
            fgColor="#000000"
            removeQrCodeBehindLogo={logo && true}
          />
          {logo && <QrCodeLogoMask logo={logo} />}
        </div>
      </div>
    </>
  )
}

export default Web3QrCode
