import { useRef, useState } from "react"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"

type FormValidateT = <t>(formData: t, field?: { [key: string]: any }, singleDataName?: any) => t | null
type SubmitCallbackT<t> = (formData: t) => Promise<void>
type FailedSubmitCallbackT = (<t>(errors: t, inputRefs: t) => void) | Function
type ErrorsT<t> = { [key in keyof t]: any }
type HasChangedT<t> = { [key in keyof t]: boolean }
type FormRefsT<t> = {
  current: {
    [key in keyof t]?: React.RefObject<HTMLDivElement>
  }
}

export interface FormHookProps<t = any, r = any> {
  initialState: t
  submitCallback: SubmitCallbackT<t>
  validate: FormValidateT
  additionalData?: r
  failedSubmitCallback?: FailedSubmitCallbackT
  scrollToError?: boolean
  watchChanges?: boolean
}

export function useForm<t, r>({
  initialState,
  additionalData = {} as r,
  submitCallback,
  validate,
  failedSubmitCallback,
  scrollToError = false,
  watchChanges = false
}: FormHookProps<t, r>) {
  const { t } = useTranslation()
  const [formData, setFormData] = useState<t>(initialState)
  const [errors, setErrors] = useState<ErrorsT<t>>({} as ErrorsT<t>)
  const [isFormLoading, setIsFormLoading] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [hasChanged, setHasChanged] = useState<HasChangedT<t>>({} as HasChangedT<t>)
  const formRefs = useRef<FormRefsT<t>>({} as FormRefsT<t>)

  const handleSingleValidate = (formData, name) => {
    const fieldError = validate(formData, additionalData, name)
    if (Object.keys(fieldError).length === 0) {
      if (errors?.[name]) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors }
          delete updatedErrors[name]
          return updatedErrors
        })
      }
    } else {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors, ...fieldError }
        return updatedErrors
      })
    }
  }

  const handleScrollToError = ({ refs, errors }) => {
    let firstInvalidField = refs?.current?.[Object?.keys?.(errors)?.[0]]

    if (
      typeof firstInvalidField === "object" &&
      !(firstInvalidField instanceof HTMLElement) &&
      firstInvalidField !== null
    ) {
      firstInvalidField = firstInvalidField[Object.keys(firstInvalidField)[0]]
    }

    firstInvalidField?.scrollIntoView({
      behavior: "smooth",
      block: "center"
    })
  }

  const handleInputChange = (event: any) => {
    const { name, value } = event.target
    setFormData((prevFormData) => {
      const updatedFormData = { ...prevFormData, [name]: value }

      if (watchChanges) handleWatchChanges(name, value)

      if (isSubmit && !!validate) {
        handleSingleValidate(updatedFormData, name)
      }

      return updatedFormData
    })
  }

  const handleWatchChanges = (name, value) => {
    setHasChanged((prevHasChanged) => {
      let isChanged = false
      if (Array.isArray(value)) {
        if (value?.length !== initialState?.[name]?.length) {
          isChanged = true
        } else {
          for (let i = 0; i < value.length; i++) {
            const singleValue = value[i]
            const initialStateValue = initialState?.[name]?.[i]
            if (typeof singleValue === "object") {
              if (JSON.stringify(singleValue) !== JSON.stringify(initialStateValue)) {
                isChanged = true
                break
              }
            } else {
              if (singleValue !== initialStateValue) {
                isChanged = true
                break
              }
            }
          }
        }
      } else {
        isChanged = initialState?.[name] !== value
      }
      const isAlreadyChanged = prevHasChanged?.[name]
      if (isChanged) {
        if (!isAlreadyChanged) {
          return { ...prevHasChanged, [name]: true }
        }
      } else if (isAlreadyChanged) {
        const updatedHasChanged = { ...prevHasChanged }
        delete updatedHasChanged[name]
        return updatedHasChanged
      }

      return prevHasChanged
    })
  }

  const handleSubmit = async (event: any, syncFormData?: any) => {
    event?.preventDefault()
    event?.stopPropagation()
    setIsFormLoading(true)

    let validationErrors
    if (!!validate) {
      validationErrors = validate(syncFormData || formData, additionalData)
      setErrors(validationErrors)
    }

    const hasErrors = Object.values(validationErrors || {}).some((error) => error)
    if (hasErrors) {
      setIsSubmit(true)
      setIsFormLoading(false)

      if (!!failedSubmitCallback) {
        toast.error(t("Fill_all_mandatory_fields"), { position: "top-center" })
        failedSubmitCallback(validationErrors, formRefs.current)
      }

      if (scrollToError) handleScrollToError({ refs: formRefs, errors: validationErrors })

      return
    }

    try {
      await submitCallback(syncFormData || formData)
      setIsFormLoading(false)
    } catch (error) {
      setIsFormLoading(false)
    }
  }

  return {
    formData,
    errors,
    isFormLoading,
    additionalData,
    handleInputChange,
    handleSubmit,
    setFormData,
    setErrors,
    setIsFormLoading,
    isSubmit,
    setIsSubmit,
    haveChanges: watchChanges ? Object.entries(hasChanged).length > 0 : true,
    formRefs
  }
}
