import DefaultSwiperRoot from "@core/components/swiper"
import { SwiperOptions } from "swiper"
import KycKnowBetterStageSlide from "./know_better"
import KycBasicInfosStageSlide from "./basic_infos"
import useKycContext from "../context/useKycContext"
import { KycStatusStageEnum } from "../context/KycContext"
import classNames from "classnames"
import { useState } from "react"
import KycBackButton from "../components/kycBackButton"
import KycResultStageSlide from "./result"
import useFormContext from "utility/context/formContext/useFormContext"

const KycLoginStages = () => {
  const { formData } = useFormContext()
  const { Slide, Swiper } = DefaultSwiperRoot
  const { companyKycStages, currentStatusStage, setUseKycSwiper } = useKycContext()

  const [swiperOption, setSwiperOptions] = useState<SwiperOptions>({
    slidesPerView: 1,
    initialSlide: Object.keys(companyKycStages).indexOf(currentStatusStage),
    allowTouchMove: false
  })

  const KycSlide = ({ children, isHidden }) => {
    return (
      <>
        <Slide className={classNames("d-flex flex-column gap-1 h-100")}>{children}</Slide>
      </>
    )
  }

  return (
    <>
      <KycBackButton />
      <Swiper.Standard
        id="kyc-swiper"
        swiperOptions={swiperOption}
        className="w-100 h-100"
        setSwiperHook={setUseKycSwiper}
        slideClassName="h-100"
      >
        <KycSlide isHidden={!!formData?.identityDocument?.document}>
          <KycKnowBetterStageSlide />
        </KycSlide>

        <KycSlide isHidden={currentStatusStage !== KycStatusStageEnum.BASIC_INFOS}>
          <KycBasicInfosStageSlide />
        </KycSlide>

        <KycSlide isHidden={currentStatusStage !== KycStatusStageEnum.CONCLUDED}>
          <KycResultStageSlide />
        </KycSlide>
      </Swiper.Standard>
    </>
  )
}

export default KycLoginStages
