import AuthInputCaption from "@core/components/login/components/authInputCaption"
import LoginInputRoot from "@core/components/login/components/inputs/root"
import { useTranslation } from "react-i18next"
import useFormContext from "utility/context/formContext/useFormContext"

const KycPhoneInput = () => {
  const {
    Container,
    Label,
    Input: { Container: InputContainer, Input, RightSideIcon }
  } = LoginInputRoot
  const { formData, errors, isSubmit, isFormLoading, handleInputChange } = useFormContext()
  const { t } = useTranslation()

  const value = formData?.phone
  const error = errors?.phone
  const showError = isSubmit && error

  const phoneMask = (v: string) => {
    v = v.replace(/\D/g, "")
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2")
    v = v.replace(/(\d)(\d{4})$/, "$1-$2")
    return v
  }

  return (
    <Container>
      <Label text="Phone" />
      <InputContainer>
        <Input
          id="kyc_phone"
          name="phone"
          type="tel"
          value={phoneMask(value)}
          placeholder="(xx) xxxxx-xxxx"
          showError={showError}
          onChange={(e) => {
            const value = phoneMask(e.target.value)
            e.target.value = value
            if (value.length <= 15) handleInputChange(e)
          }}
        />
        <RightSideIcon isLoading={isFormLoading} haveError={showError} size={"1.5rem"} />
      </InputContainer>
      <AuthInputCaption errorText={error} showError={showError} />
    </Container>
  )
}

export default KycPhoneInput
