import WeispaceLoginBannerRoot from "./root"
import JoinCommunity from "web-weispace/assets/images/pages/login/join_community.png"

const WeispaceLoginJoinCommunityBanner = () => {
  const { Description, Image, Title, Container } = WeispaceLoginBannerRoot

  return (
    <Container>
      <Title text="Weispace_Login_Banner_Join_Community_Title" />
      <Image src={JoinCommunity} />
      <Description text="Weispace_Login_Banner_Join_Community_Description" />
    </Container>
  )
}

export default WeispaceLoginJoinCommunityBanner
