import { AxiosResponse } from "axios"
import { axiosBase } from "./axiosbase"

const getCommunities = (token: string): Promise<AxiosResponse> => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.get("/communities", config)
}

const getCommunityById = (token: string, communityId) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return axiosBase.get(`/communities/${communityId}`, config)
}

const getUserMembershipTierByCommmunityId = async (token: string, communityId: string) => {
  const config = { headers: { Authorization: `Bearer ${token}` } }
  return await axiosBase.get(`/communities/${communityId}/membership/tier`, config)
}

export { getCommunities, getCommunityById, getUserMembershipTierByCommmunityId }
