import { createSlice } from "@reduxjs/toolkit"

export const loginModalSlice = createSlice({
  name: "loginModalSlice",
  initialState: {
    showLoginModal: false
  },
  reducers: {
    setShowLoginModal: (state, { payload }) => {
      state.showLoginModal = payload
      window.sessionStorage.setItem("showLoginModal", payload)
    }
  }
})

export const { setShowLoginModal } = loginModalSlice.actions


export default loginModalSlice.reducer
