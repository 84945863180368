import CustomDomainProvider from "web-marketplace/contexts/mount/customDomain/customDomainProvider"
import WhiteLabelProvider from "web-marketplace/contexts/mount/whitelabel/whiteLabelProvider"
import MountMarketPlaceGuardProvider from "./guard"

interface MountMarketPlaceProviderProps {
  children: React.ReactNode
}

/**
 * Provider que monta o marketplace
 * - CustomDomainProvider: Verificar se precisar mudar o domínio
 * - WhiteLabelProvider: Realizar a configuração do whitelabel (company)
 */
const MountMarketPlaceProvider = ({ children }: MountMarketPlaceProviderProps) => {
  return (
    <>
      <CustomDomainProvider>
        <WhiteLabelProvider>
          <MountMarketPlaceGuardProvider>
            {children}
          </MountMarketPlaceGuardProvider>
        </WhiteLabelProvider>
      </CustomDomainProvider>
    </>
  )
}

export default MountMarketPlaceProvider
