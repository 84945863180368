import { useTranslation } from "react-i18next"
import Web3InstructionCard from "../../components/card/variants/instructionCard"

import RefreshIlu from "assets/images/logo/Refresh.png"

import ProviderFAQButton from "../../components/buttons/providerFAQButton"
import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import Web3ButtonHandler from "../../components/buttons/handler"
import GetStartedProviderCard from "../../components/card/getStartedProviderCard"
import CreateOrImportWalletCard from "../../components/card/createOrImportWalletCard"

const Web3IdleGetStartedForExtension = () => {
  const { t } = useTranslation()
  const { selectedWeb3AuthProvider } = useWeb3AuthContext()

  const handleBrowserRefresh = () => {
    window.location.reload()
  }

  return (
    <>
      <div className="d-flex flex-column h-100 align-items-center mx-auto pt-1 justify-content-between" style={{ maxWidth: "320px", gap: "2.5rem" }}>
        <div className="d-flex flex-column h-100 gap-2 mx-auto justify-content-center">
          <GetStartedProviderCard provider={selectedWeb3AuthProvider} />
          <CreateOrImportWalletCard />
          <Web3InstructionCard
            title="Refresh_your_browser"
            subtitle="Refresh_your_browser_description"
            image={RefreshIlu}
          />
        </div>
        <div className="d-flex flex-column gap-50">          
          <Web3ButtonHandler onClick={handleBrowserRefresh} id={`web3-browser-refresh`}>
            {t("Refresh")}
          </Web3ButtonHandler>
          <ProviderFAQButton provider={selectedWeb3AuthProvider} />
        </div>
      </div>
    </>
  )
}

export default Web3IdleGetStartedForExtension
