import { utils } from "ethers"
import { MetaMaskStatusEnum } from "../metamaskAuthContext"
import handleMetaMaskError, { MetamaskErrors } from "./handleError"

interface NetworksEnum {}

interface NetworksData {
  chainId: string
  chainName: string
  nativeCurrency: { name: string; symbol: string; decimals: number }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

const toHex = (num) => {
  return `0x${num.toString(16)}`
}

const addExpectedChain = async ({ expectedChainId, setMetaMaskAuthStatus }) => {
  const {
    RequestedNetwork_Pending: Pending,
    RequestedNetwork_Failed: Failed,
    RequestedNetwork_Dismissed: Dismissed,
    RequestedNetwork_Stack: Stack,
    RequestedNetwork_Success: Success
  } = MetaMaskStatusEnum

  const networkMaps: { [key: string]: NetworksData } = {
    137: {
      chainId: toHex(137),
      chainName: "Polygon Mainnet",
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://polygon-rpc.com"],
      blockExplorerUrls: ["https://www.polygonscan.com/"]
    },
    80001: {
      chainId: toHex(80001),
      chainName: "Mumbai",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
    },
    80002: {
      chainId: toHex(80002),
      chainName: "Amoy",
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://polygon-amoy-bor-rpc.publicnode.com"],
      blockExplorerUrls: ["https://www.oklink.com/amoy/"]
    }
  }

  try {
    setMetaMaskAuthStatus(Pending)

    const result = await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [networkMaps[expectedChainId]]
    })
    console.log(result)
    setMetaMaskAuthStatus(Success)

    return { stopProceed: false }
  } catch (err) {
    console.log(err?.code)
    const mappedMetaMaskConnectErrors = handleMetaMaskError(err?.name as MetamaskErrors)
    if (mappedMetaMaskConnectErrors) {
      const { callback, status } = mappedMetaMaskConnectErrors
      const authStatus = status === "dismiss" ? Dismissed : Stack

      callback(setMetaMaskAuthStatus, authStatus)
    } else {
      setMetaMaskAuthStatus(Failed)
    }

    return { stopProceed: true }
  }
}

export default addExpectedChain
