import useAuthContext from "contexts/auth/AuthInContext"
import { useEffect, useState } from "react"
import MetaMaskAuthContext, {
  HandleMetaMaskLogin,
  MetaMaskAuthProviderValues,
  MetaMaskLoginCallbackFrom,
  MetaMaskStatusEnum
} from "./metamaskAuthContext"

import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { isMobile } from "react-device-detect"
import handleMetaMaskMobileLogin from "./hooks/handleMobileLogin"
import connectMetaMask from "./hooks/connect"

import switchMetaMaskNetwork from "./hooks/switchNetwork"
import validateUserNetwork from "./hooks/validateNetwork"
import metaMaskSignInMessage from "./hooks/signInMessage"
import { useAppDispatch } from "redux/hooks"
import handleFetchMetaMaskJwt from "./hooks/fetchJwt"
import {
  Web3AuthLoadingState,
  Web3AuthProvidersEnum,
  Web3AuthStatusState,
  Web3SelectedAuthProviderState
} from "../../web3AuthContext"
import detectWeb3Provider from "../../hooks/detect"
import checkMissingRequestedNetwork from "../../hooks/checkMissingRequestedNetwork"
import addExpectedChain from "./hooks/addRequestedChain"

interface MetaMaskAuthProviderProps
  extends Pick<Web3SelectedAuthProviderState, "selectedWeb3AuthProvider">,
    Pick<Web3AuthLoadingState, "isWeb3AuthLoading">,
    Pick<Web3AuthStatusState, "setWeb3AuthStatus"> {
  children: React.ReactNode
}

const MetamaskAuthProvider = ({
  children,
  selectedWeb3AuthProvider,
  setWeb3AuthStatus,
  isWeb3AuthLoading
}: MetaMaskAuthProviderProps) => {
  const dispatch = useAppDispatch()
  const { Provider } = MetaMaskAuthContext
  const {
    setCurrentAuthStageShowing,
    wagmiConfigs,
    expectedChain,
    networkMap,
    setIsUserLoggedIn,
    currentAuthStageShowing
  } = useAuthContext()
  const {
    connect,
    network,
    switchNetwork,
    account,
    disconnect: { disconnect },
    signMessage
  } = wagmiConfigs

  const { chain } = network
  const { address, connector, isConnected } = account

  const [isMetaMaskAuthLoading, setIsMetaMaskAuthLoading] = useState<boolean>(false)
  const [metaMaskAuthStatus, setMetaMaskAuthStatus] = useState<MetaMaskStatusEnum>(null)
  const [shouldRestartAuthProcess, setShouldRestartAuthProcess] = useState<boolean>(false)
  const [isChainAlreadyConfigured, setIsChainAlreadyConfigured] = useState<boolean>(false)

  const stopMetaMaskAuthRequest = () => {
    setIsMetaMaskAuthLoading(false)
  }

  const handleDetectWeb3Provider = () => {
    const { mustStopOtherProceeds: shouldStopOnDetect } = detectWeb3Provider({
      web3Provider: Web3AuthProvidersEnum.METAMASK,
      setWeb3AuthStatus
    })

    if (shouldStopOnDetect) {
      setCurrentAuthStageShowing(AuthModalsEnum.WEB3_UNAVAILABLE)
      if (isMobile) {
        handleMetaMaskMobileLogin()
      } else {
        stopMetaMaskAuthRequest()
      }
    } else {
      setCurrentAuthStageShowing(AuthModalsEnum.WEB3_LOGIN)
    }

    return { shouldStopOnDetect }
  }

  const handleConnectUserMetaMask = async (proceedFrom: MetaMaskLoginCallbackFrom) => {
    const { stopProceed: shouldStopOnConnect, userData } =
      (await connectMetaMask({
        proceedFrom,
        connect,
        network,
        setMetaMaskAuthStatus,
        account
      })) || {}
    if (shouldStopOnConnect) stopMetaMaskAuthRequest()

    return { shouldStopOnConnect, userData }
  }

  const handleDetectMissingExpectedChain = async ({ userChains }: { userChains: any[] }) => {
    const { stopProceed: shouldStopOnMissingRequestedNetwork } = checkMissingRequestedNetwork({
      requestedNetwork: expectedChain,
      usersNetworks: userChains
    })

    if (shouldStopOnMissingRequestedNetwork) {
      setMetaMaskAuthStatus(MetaMaskStatusEnum.RequestedNetwork_Pending)
    }

    return { shouldStopOnMissingRequestedNetwork }
  }

  const handleAddAndSwitchNetwork = async ({
    proceedFrom,
    isChainAlreadyConfigured = false
  }: {
    proceedFrom: MetaMaskLoginCallbackFrom
    isChainAlreadyConfigured?: boolean
  }) => {
    const {
      stopProceed,
      switchedNetworkData: data,
      isChainNotConfigured
      // isChainNotConfigured,
      // switchedNetworkData,
      // stopProceed: shouldStopInSwitchNetwork
    } = (await switchMetaMaskNetwork({
      proceedFrom,
      setMetaMaskAuthStatus,
      switchNetwork,
      expectedChainId: expectedChain,
      isChainAlreadyConfigured
    })) || {}
    let shouldStopInSwitchNetwork = stopProceed
    let switchedNetworkData = data
    if (shouldStopInSwitchNetwork) {
      if (!switchNetwork?.switchNetworkAsync) {
        setShouldRestartAuthProcess(true)
      } else if (isChainNotConfigured) {
        const { shouldStopOnAddChain } = await handleAddChainToUserWallet()
        if (!shouldStopOnAddChain) {
          setIsChainAlreadyConfigured(true)
          const { shouldStopInSwitchNetwork: stopProceed, switchedNetworkData: data } = await handleAddAndSwitchNetwork(
            { proceedFrom, isChainAlreadyConfigured: true }
          )
          shouldStopInSwitchNetwork = stopProceed
          switchedNetworkData = data
        }
      } else {
        stopMetaMaskAuthRequest()
      }
    }

    return { isChainNotConfigured, shouldStopInSwitchNetwork, switchedNetworkData }
  }

  const handleAddChainToUserWallet = async () => {
    const { stopProceed: shouldStopOnAddChain } = await addExpectedChain({
      expectedChainId: expectedChain,
      setMetaMaskAuthStatus
    })

    if (shouldStopOnAddChain) stopMetaMaskAuthRequest()

    return { shouldStopOnAddChain }
  }

  const handleValidateUserNetwork = async ({ userAddress, userChainId }) => {
    const {
      stopProceed: shouldStopInNetworkValidation,
      userAddress: validatedUserAddress,
      userChainId: validatedUserChainId
    } = validateUserNetwork({
      expectedChain,
      userAddress,
      userChainId
    }) || {}

    return { shouldStopInNetworkValidation, userAddress: validatedUserAddress, userChainId: validatedUserChainId }
  }

  const handleSignInMessage = async ({ userAddress, userChainId }) => {
    const signedMessageByGoTokens = "Assine essa mensagem para garantirmos que você é o dono da carteira"
    const { stopProceed: shouldStopInSignInMessage, signedMessageData } =
      (await metaMaskSignInMessage({
        userAddress,
        userChainId,
        message: signedMessageByGoTokens,
        setMetaMaskAuthStatus,
        signMessage
      })) || {}
    if (shouldStopInSignInMessage) stopMetaMaskAuthRequest()

    return { shouldStopInSignInMessage, signedMessageData, signedMessageByGoTokens }
  }

  const handleMetaMaskLogin: HandleMetaMaskLogin = async (proceedFrom) => {
    setIsMetaMaskAuthLoading(true)
    setShouldRestartAuthProcess(false)

    const { shouldStopOnDetect } = handleDetectWeb3Provider()
    if (shouldStopOnDetect) return null

    const { shouldStopOnConnect, userData } = await handleConnectUserMetaMask(proceedFrom)
    if (shouldStopOnConnect) return null

    const { shouldStopInSwitchNetwork, switchedNetworkData, isChainNotConfigured } = await handleAddAndSwitchNetwork({
      proceedFrom,
      isChainAlreadyConfigured
    })
    if (shouldStopInSwitchNetwork) {
      return null
    }

    const { shouldStopInNetworkValidation, userAddress, userChainId } = await handleValidateUserNetwork({
      userAddress: userData?.address || address,
      userChainId: proceedFrom ? switchedNetworkData?.id : userData?.chain?.id || network?.chain?.id
    })
    if (shouldStopInNetworkValidation) return handleMetaMaskLogin("switch")

    const { shouldStopInSignInMessage, signedMessageByGoTokens, signedMessageData } = await handleSignInMessage({
      userAddress,
      userChainId
    })
    if (shouldStopInSignInMessage) return null

    handleFetchMetaMaskJwt({
      setIsUserLoggedIn,
      setMetaMaskAuthStatus,
      dispatch,
      address: userAddress || address,
      message: signedMessageByGoTokens,
      network: networkMap[userChainId || network?.chain?.id],
      signature: signedMessageData
    })

    setIsMetaMaskAuthLoading(false)
  }

  const resetMetaMaskAuth = () => {
    setMetaMaskAuthStatus(null)
    setIsMetaMaskAuthLoading(false)
    setIsChainAlreadyConfigured(false)
    disconnect()
  }

  useEffect(() => {
    if (selectedWeb3AuthProvider === Web3AuthProvidersEnum.METAMASK && isWeb3AuthLoading) {
      handleMetaMaskLogin()
    }
  }, [selectedWeb3AuthProvider, isWeb3AuthLoading])

  useEffect(() => {
    if (chain && expectedChain && shouldRestartAuthProcess) {
      handleMetaMaskLogin("switch")
    }
  }, [chain, expectedChain, shouldRestartAuthProcess])

  useEffect(() => {
    if (
      (currentAuthStageShowing === AuthModalsEnum.WEB3_LOGIN ||
        currentAuthStageShowing === AuthModalsEnum.WEB3_UNAVAILABLE) &&
      !selectedWeb3AuthProvider
    ) {
      stopMetaMaskAuthRequest()
      setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    }
  }, [currentAuthStageShowing])

  const providerValues: MetaMaskAuthProviderValues = {
    resetMetaMaskAuth,
    handleMetaMaskLogin,
    isMetaMaskAuthLoading,
    setIsMetaMaskAuthLoading,
    metaMaskAuthStatus,
    setMetaMaskAuthStatus
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default MetamaskAuthProvider
