import React, { createContext, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"

export interface IContext {
  shouldRescue: boolean
  setShouldRescue: (shouldRescue: boolean) => void
  marketplaceRouteHistory: string[]
  setMarketplaceRouteHistory: (marketplaceRouteHistory: string[]) => void
}
const Context = createContext<IContext>(null)

export const ContextProvider = ({ children }) => {
  const [shouldRescue, setShouldRescue] = useState(false)
  const [marketplaceRouteHistory, setMarketplaceRouteHistory] = useState<string[]>([])
  const location = useLocation()
  const { pathname } = location

  useEffect(() => {
    if (!pathname.includes("/weispace")) {
      setMarketplaceRouteHistory([...marketplaceRouteHistory, pathname])
    }
  }, [pathname])

  return (
    <Context.Provider
      value={{
        shouldRescue,
        setShouldRescue,
        marketplaceRouteHistory,
        setMarketplaceRouteHistory
      }}
    >
      {children}
    </Context.Provider>
  )
}

export const useGlobalContext = () => {
  const context = useContext(Context)
  if (!context) {
    throw new Error("useGlobalContext must be used within a StoreProvider")
  }
  return context
}
