import { createSlice } from "@reduxjs/toolkit"
import { fetchCommunities, fetchCommunitiyProducts } from "./communitiesActions"

const communitiesInitialState = {
  communities: [],
  communitiesLoading: true,
  communitiesError: undefined,
  communityProducts: []
}

const communitiesSlice = createSlice({
  name: "communities",
  initialState: communitiesInitialState,
  reducers: {
    setCommunities: (state, { payload }) => {
      state.communities = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommunities.pending, (state) => {
      state.communitiesLoading = true
    })
    builder.addCase(fetchCommunities.fulfilled, (state, { payload }) => {
      state.communities = payload
      state.communitiesLoading = false
    })
    builder.addCase(fetchCommunities.rejected, (state, { error }) => {
      state.communitiesError = error
      state.communitiesLoading = false
    })
  }
})

export default communitiesSlice.reducer
