import { useContext } from "react"
import FormContext, { FormContextI } from "./formContext"

const useFormContext = <t = any, r = any>() => {
  const context = useContext<FormContextI<t, r>>(FormContext)
  if (context === undefined) {
    throw new Error("useFormContext must be used within a FormProvider")
  }
  return context
}

export default useFormContext
