import LoginInputContentContainer from "./container"
import LoginInputContent from "./input"
import LoginInputRightSideIcon from "./rightSideIcon"

const LoginInputContentRoot = {
  Container: LoginInputContentContainer,
  Input: LoginInputContent,
  RightSideIcon: LoginInputRightSideIcon
}

export default LoginInputContentRoot