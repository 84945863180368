import LoginContent, { LoginComponentProps } from "@core/components/login"
import { Card } from "reactstrap"
import useBreakpoint from "utility/hooks/useMatchBreakpoint"

interface LoginContentComponentProps extends LoginComponentProps {
  ContainerTag?: React.ElementType
}

const LoginContentComponent = ({ ContainerTag, ...loginContentProps }: LoginContentComponentProps) => {
  const match = useBreakpoint({ direction: "down", breakpoint: "md" })
  const Container = ContainerTag || match ? "div" : Card

  return (
    <Container className="fullscreen-login-content">
      <LoginContent {...loginContentProps} />
    </Container>
  )
}

export default LoginContentComponent
