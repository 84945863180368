import { createSlice } from "@reduxjs/toolkit"
import { getPurchases, getPurchase } from "./purchaseActions"

export const purchaseSlice = createSlice({
  name: "purchases",
  initialState: {
    total: 1,
    params: {},
    allPurchases: [],
    allPurchasesLoading: true,
    allPurchasesError: null,

    selectedPurchase: null,
    selectedPurchaseLoading: true,
    selectedPurchaseError: null
  },
  reducers: {
    clearSelectedPurchase: (state) => {
      state.selectedPurchase = null
      state.selectedPurchaseLoading = true
      state.selectedPurchaseError = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPurchases.pending, (state, action) => {
        state.allPurchasesLoading = true
      })
      .addCase(getPurchases.fulfilled, (state, action) => {
        state.allPurchases = action.payload
        state.allPurchasesLoading = false
      })
      .addCase(getPurchases.rejected, (state, action) => {
        state.allPurchasesError = action.payload
        state.allPurchasesLoading = false
      })
      .addCase(getPurchase.pending, (state) => {
        state.selectedPurchaseLoading = true
      })
      .addCase(getPurchase.fulfilled, (state, action) => {
        state.selectedPurchase = action.payload
        state.selectedPurchaseLoading = false
      })
      .addCase(getPurchase.rejected, (state, action) => {
        state.selectedPurchase = null
        state.selectedPurchaseError = action.payload
        state.selectedPurchaseLoading = false
      })
  }
})

export const { clearSelectedPurchase } = purchaseSlice.actions

export default purchaseSlice.reducer
