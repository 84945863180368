import LoginContentContext, { LoginContentValues } from "./loginContentContext"

import Web3AuthProvider from "contexts/auth/methods/web3/web3AuthProvider"
import EmailAuthProvider from "contexts/auth/methods/email/emailAuthProvider"
import { WhiteLabelAuthI } from "interfaces/store/auth"

interface LoginContentProviderProps {
  children: React.ReactNode
  whitelabelAuth: WhiteLabelAuthI
}

const LoginContentProvider = ({ children, whitelabelAuth }: LoginContentProviderProps) => {
  const { Provider } = LoginContentContext

  const { methods } = whitelabelAuth || {}
  const { socials, web3 } = methods || {}
  const { providers: socialProviders } = socials || {}

  const { providers: web3Providers } = web3 || {}

  const providerValues: LoginContentValues = {
    isAppleLoginEnabled: false || socialProviders?.apple?.enabled,
    isFacebookLoginEnabled: false || socialProviders?.facebook?.enabled,
    isGoogleLoginEnabled: false || socialProviders?.google?.enabled,
    isMicrosoftLoginEnabled: false || socialProviders?.microsoft?.enabled,
    isMetamaskLoginEnabled: false || web3Providers?.metamask?.enabled
  }

  return (
    <Web3AuthProvider>
      <EmailAuthProvider>
        <Provider value={providerValues}>{children}</Provider>
      </EmailAuthProvider>
    </Web3AuthProvider>
  )
}

export default LoginContentProvider
