
import Web3ButtonHandler from "../../../buttons/handler"
import Web3CardContainer from "./container"
import Web3CardContentWrapper from "./contentWrapper"
import Web3CardSubtitle from "./description"
import Web3CardImage from "./image"
import Web3CardTitle from "./title"

const Web3CardRoot = {
  Container: Web3CardContainer,
  ContentWrapper: Web3CardContentWrapper,
  Image: Web3CardImage,
  Title: Web3CardTitle,
  SubTitle: Web3CardSubtitle,
  ButtonHandler: Web3ButtonHandler
}

export interface Web3CardRootProps {
  id?: string
  title?: string
  titleProps?: any
  subtitle?: string
  subtitleProps?: any
  image?: string
  onClick?: any
  buttonText?: string
}

export default Web3CardRoot
