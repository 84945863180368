import { createContext, useEffect } from "react"
import { toast, ToastPosition } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { removeSession } from "services/store"
import { removeSessionMetamask } from "services/store/metamask"
import { cookies } from "utility/cookies"
import { useAppDispatch } from "redux/hooks"
import useAuthContext from "./auth/AuthInContext"

const Context = createContext(null)

export const CookieExpiracyProvider = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { setIsUserLoggedIn } = useAuthContext()
  const dataFromCookies = cookies.get("userData")
  const data = !!dataFromCookies ? JSON.parse(dataFromCookies) : null

  useEffect(() => {
    if (data) {
      if (data.expires && new Date().toISOString() > data.expires) {
        dispatch(removeSession({}))
        dispatch(removeSessionMetamask({}))

        toast.error(t("Token Expired Toast"), {
          position: process.env.REACT_APP_TOAST_POSITION as ToastPosition,
          duration: Number(process.env.REACT_APP_TOAST_DURATION)
        })
      } else {
        setIsUserLoggedIn(true)
      }
    }
  }, [data])

  return <Context.Provider value={null}>{children}</Context.Provider>
}
