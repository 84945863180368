import classNames from "classnames"
import { useTranslation } from "react-i18next"

export interface LoginDescriptionProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  centralized?: boolean
  [key: string]: any
}

const LoginDescription = ({ className, text, style, centralized, textProps = {}, ...rest }: LoginDescriptionProps) => {
  const { t } = useTranslation()
  return (
    <p
      className={classNames("m-0 fs-6", {
        "text-center": centralized,
        [className]: className
      })}
      {...rest}
      style={style}
    >
      {t(text, textProps)}
    </p>
  )
}

export default LoginDescription
