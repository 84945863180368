import { useQuery } from "react-query"
import { fetchCustomManifest } from "services/customDomain"
import { onError } from "utility/onError"

interface FetchCustomManifestProps {
  customDomainCompanyId: string
  enabled?: boolean
}

const useFetchCustomManifest = ({ customDomainCompanyId, enabled }: FetchCustomManifestProps) => {
  const { data: customManifestData, isLoading: customManifestIsLoading } = useQuery(
    ["fetchCustomManifest", customDomainCompanyId],
    () => fetchCustomManifest(customDomainCompanyId).then((response) => response.data),
    {
      onError,
      enabled: !!customDomainCompanyId && enabled,
      refetchOnWindowFocus: false
    }
  )

  return { customManifestData, customManifestIsLoading }
}

export default useFetchCustomManifest