import { createAsyncThunk } from "@reduxjs/toolkit"
import { CompanyPublicData } from "interfaces/company"
import { axiosBase } from "services/axiosBase"

export const getPublicCompanyData = createAsyncThunk<CompanyPublicData, string>(
  "companyPublicData/getById",
  async (companyId: string) => {
    try {
      return (await axiosBase.get<CompanyPublicData>(`/companies/${companyId}/public`))?.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)
