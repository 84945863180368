import { useTranslation } from "react-i18next"
import Web3InstructionCard from "../../components/card/variants/instructionCard"

import ScanIlu from "assets/images/pages/scan.png"

import ProviderFAQButton from "../../components/buttons/providerFAQButton"
import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import Web3ButtonHandler from "../../components/buttons/handler"
import GetStartedProviderCard from "../../components/card/getStartedProviderCard"
import CreateOrImportWalletCard from "../../components/card/createOrImportWalletCard"
import { Web3IdleStages } from "contexts/auth/methods/web3/web3AuthContext"

const Web3IdleGetStartedForApp = () => {
  const { t } = useTranslation()
  const { selectedWeb3AuthProvider, setWeb3IdleStageShowing } = useWeb3AuthContext()

  const handleConnect = () => {
    setWeb3IdleStageShowing(Web3IdleStages.Connect)
  }

  return (
    <>
      <div className="d-flex flex-column h-100 align-items-center mx-auto pt-1 justify-content-between" style={{ maxWidth: "320px", gap: "2.5rem" }}>
        <div className="d-flex flex-column h-100 gap-2 mx-auto justify-content-center">
          <GetStartedProviderCard provider={selectedWeb3AuthProvider} getStaredFrom="app"/>
          <CreateOrImportWalletCard />
          <Web3InstructionCard
            title="Tap_the_scan_button"
            subtitle="Tap_the_scan_button_description"
            image={ScanIlu}
          />
        </div>
        <div className="d-flex flex-column gap-50">          
          <Web3ButtonHandler onClick={handleConnect} id={`web3-browser-connect`}>
            {t("Connect")}
          </Web3ButtonHandler>
          <ProviderFAQButton provider={selectedWeb3AuthProvider} />
        </div>
      </div>
    </>
  )
}

export default Web3IdleGetStartedForApp
