import { validate } from "validations"
import { validateFormErrors } from "validations/validateFormErrors"
import { KycPersonTypeI, KycPersonalInfosI } from "../../../../context/kycFormHooks/initialState"
import { UserIdentityDocumentsEnum } from "interfaces/user"

export type KycBasicInfosFormDataValidator = {
  [key in keyof KycPersonalInfosI & { identityDocument: KycPersonTypeI["identityDocument"] }]: (
    formData: KycPersonalInfosI | undefined
  ) => string | undefined | null
}

export const kycBasicInfosFormDataValidator: KycBasicInfosFormDataValidator = {
  fullName(formData) {
    if (validate.isEmpty(formData["fullName"])) {
      return "Required"
    }
    if (validate.isMinorThan(3, formData["fullName"])) {
      return "Name_Minimum_Error"
    }
  },
  phone(formData) {
    if (validate.isEmpty(formData["phone"])) {
      return "Required"
    }
  },
  birthDate(formData) {
    if (validate.isEmpty(formData["birthDate"])) {
      return "Required"
    }
  },
  identityDocument(formData) {
    const { identityDocument } = formData || {}
    const { document, value } = identityDocument || {}
    if (document === UserIdentityDocumentsEnum.CPF) {
      if (validate.isEmpty(value)) {
        return "Required"
      }
      if (validate.isInvalidCPF(value)) {
        return "CPF_Invalid_Error"
      }
    } else if (document === UserIdentityDocumentsEnum.CNPJ) {
      if (validate.isEmpty(value)) {
        return "Required"
      }
      if (validate.isInvalidCNPJ(value)) {
        return "CNPJ_Invalid_Error"
      }
    }
  }
}

const useKycBasicInfosFormDataValidator = (data, singleDataName?) => {
  return validateFormErrors(data, kycBasicInfosFormDataValidator, singleDataName)
}

export default useKycBasicInfosFormDataValidator
