import LoginCaption from "./typography/caption"

interface AuthInputCaptionProps {
  showError: boolean
  errorText: string
}

const AuthInputCaption = ({ errorText, showError }: AuthInputCaptionProps) => {
  return <>{showError && errorText && <LoginCaption className="text-danger text-end" text={errorText} />}</>
}

export default AuthInputCaption
