import PolygonMainnetLogo from "@core/assets/PolygonLogo.png"
import PolygonMumbaiLogo from "@core/assets/ethChainDefaultLogo.png"

const toHex = (num) => {
  return `0x${num.toString(16)}`
}

const useAuthNetwork = () => {
  const networkMap = {
    1: "ethereum_mainnet",
    5: "ethereum_goerli",
    137: "polygon_mainnet",
    80002: "polygon_amoy",
    80001: "polygon_mumbai"
  }

  const networkDataMap = {
    137: {
      chainId: 137,
      chainHex: toHex(137),
      chainName: "Polygon Mainnet",
      chainLogo: PolygonMainnetLogo,
      nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
      rpcUrls: ["https://polygon-rpc.com"],
      blockExplorerUrls: ["https://www.polygonscan.com/"]
    },
    80001: {
      chainId: 80001,
      chainHex: toHex(80001),
      chainName: "Mumbai",
      chainLogo: PolygonMumbaiLogo,
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
      blockExplorerUrls: ["https://mumbai.polygonscan.com/"]
    },
    80002: {
      chainId: 80002,
      chainHex: toHex(80002),
      chainName: "Amoy",
      chainLogo: PolygonMumbaiLogo,
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18
      },
      rpcUrls: ["https://polygon-amoy-bor-rpc.publicnode.com"],
      blockExplorerUrls: ["https://www.oklink.com/amoy/"]
    }
  }

  const {
    chainId: expectedChain,
    chainHex: expectedChainHex,
    chainName: expectedChainName,
    chainLogo: expectedChainLogo,
    nativeCurrency
  } = networkDataMap[process.env.REACT_APP_ENVIRONMENT === "production" ? 137 : 80002]

  const expectedChainCurrency = nativeCurrency.symbol

  return {
    networkMap,
    expectedChain,
    expectedChainName,
    expectedChainHex,
    expectedChainLogo,
    expectedChainCurrency
  }
}

export default useAuthNetwork
