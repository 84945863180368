import useGetBrowserData, {BrowserNames} from "@core/components/login/utils/hooks/useGetBrowserData"
import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"


interface PluginData {
  link: string
  logo: string
  name: BrowserNames
}

type ProviderPluginDataInner = {
  default: PluginData
} & { [key in BrowserNames]?: PluginData }

type ProviderPluginData = {
  [key in Web3AuthProvidersEnum]: ProviderPluginDataInner
}

interface useGetProviderPluginDataProps {
  web3Provider: Web3AuthProvidersEnum
}

const useGetProviderPluginData = ({ web3Provider }: useGetProviderPluginDataProps) => {
  const providerPluginDataMap: ProviderPluginData = {
    MetaMask: {
      default: {
        link: "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=metamask.io",
        ...useGetBrowserData(BrowserNames.Chrome)
      },
      Firefox: {
        link: "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
        ...useGetBrowserData(BrowserNames.Firefox)
      },
      Brave: {
        link: "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=metamask.io",
        ...useGetBrowserData(BrowserNames.Brave)
      },
      Opera: {
        link: "https://addons.opera.com/en-gb/extensions/details/metamask-10/",
        ...useGetBrowserData(BrowserNames.Opera)
      },
      Edge: {
        link: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US",
        ...useGetBrowserData(BrowserNames.Edge)
      },
      Chrome: {
        link: "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=metamask.io",
        ...useGetBrowserData(BrowserNames.Chrome)
      }
    }
  }

  return providerPluginDataMap?.[web3Provider]
}

export default useGetProviderPluginData
