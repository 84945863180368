import { lazy } from "react"
import { Navigate } from "react-router-dom"

const LazyTokenPage = lazy(() => import("./index"))
const mainTokensPath = `/weispace/tokens`

export const tokensPath = {
  default: mainTokensPath,
  home: `${mainTokensPath}/home`,
  poap: `${mainTokensPath}/poaps`,
  airdrop: `${mainTokensPath}/airdrops`,
  sale: `${mainTokensPath}/sales`,
  membership: `${mainTokensPath}/memberships`,
  details:(tokenId) => `${mainTokensPath}/details/${tokenId}`
}

export const TokenRoutes = [
  {
    path: tokensPath.default,
    element: <Navigate replace to={tokensPath['home']} />
  },
  {
    path: tokensPath.home,
    element: <LazyTokenPage variant="home"/>
  },
  {
    path: tokensPath.poap,
    element: <LazyTokenPage variant="poap"/>
  },
  {
    path: tokensPath.airdrop,
    element: <LazyTokenPage variant="airdrop"/>
  },
  {
    path: tokensPath.sale,
    element: <LazyTokenPage variant="sale"/>
  },
  {
    path: tokensPath.membership,
    element: <LazyTokenPage variant="membership"/>
  },
  {
    path: tokensPath.details(':tokenId'),
    element: <LazyTokenPage variant="details"/>
  }
]
