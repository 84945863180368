export * from "./resaleModalFormValidations"
export * from "./benefitValidatorModalLoginValidation"

import dayjs from "dayjs"

export const validate = {
  isAgeInvalid: (birthDate: string | undefined | null, minAgeRequirement: string | undefined | null) => {
    if (birthDate && minAgeRequirement) {
      const age = dayjs().diff(birthDate, "year")
      const isValidAge = age >= parseInt(minAgeRequirement, 10)
      return !isValidAge
    }
    return true
  },
  isEmpty: (value: string | number | undefined | null | Date | {}) => {
    const emptyValidations = {
      array: (valueCallback) => (valueCallback as [])?.length === 0,
      object: (valueCallback) => {
        return Object.keys(valueCallback as {}).length === 0
      },
      string: (valueCallback) => valueCallback === "",
      number: (valueCallback) => valueCallback === 0,
      date: (valueCallback) => valueCallback === null
    }

    if (!value) return true

    let typeOf: "string" | "number" | "bigint" | "boolean" | "symbol" | "undefined" | "object" | "function" | "array" =
      typeof value
    if (typeOf === "object") {
      if (value instanceof Array) {
        typeOf = "array"
      }
    }

    const validation = emptyValidations[typeOf]

    if (validation) {
      if (validation(value)) return true
      return false
    } else {
      return true
    }
  },
  isOutOfRange: (value: number) => {
    if (value < 0 || value > 90) {
      return true
    } else {
      return false
    }
  },
  isMinorThan: (min: number | string | undefined, max: number | string | undefined) => {
    if (typeof min === "string" && typeof max === "string") {
      min = parseInt(min)
      max = parseInt(max)
    }

    return Number(min) > Number(max)
  },
  isGreaterThan: (min: number | string | undefined, max: number | string | undefined) => {
    if (typeof min === "string" && typeof max === "string") {
      min = parseInt(min)
      max = parseInt(max)
    }

    return Number(min) > Number(max)
  },
  isInvalidEmail: (value: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (regex.test(value)) {
      return false
    }
    return true
  },
  isInvalidUrl: (value: string | undefined) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/
    return !regex.test(value)
  },
  isPastStartDate: (value: string | undefined) => {
    if (value) {
      const startDateObj = new Date(value)
      const today = new Date()
      if (startDateObj < today) {
        return true
      }
    }
    return false
  },
  isPastExpirationDate: (expirationDate: string | undefined | Date) => {
    if (expirationDate) {
      const expirationDateObj = new Date(expirationDate)
      const today = new Date()
      if (expirationDateObj < today) {
        return true
      }
    }
    return false
  },
  isExpirationBeforeStartDate: (expirationDate: string | undefined, startDate: string | undefined) => {
    if (expirationDate && startDate) {
      const expirationDateObj = new Date(expirationDate)
      const startDateObj = new Date(startDate)
      if (expirationDateObj < startDateObj) {
        return true
      }
    }
    return false
  },
  isInvalidCPF: (cpf: string | undefined) => {
    if (cpf) {
      // const cpfRegex = /^\d{3}\.\d{3}\.\d{3}\-\d{2}$/
      cpf = cpf.replace(/\D/g, "")
      const cpfRegex = /^\d{11}$/
      if (!cpfRegex.test(cpf)) {
        return true
      }
    }
    return false
  },
  isInvalidCNPJ: (cnpj: string | undefined) => {
    if (cnpj) {
      // const cnpjRegex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
      cnpj = cnpj.replace(/\D/g, "")
      const cnpjRegex = /^\d{14}$/
      if (!cnpjRegex.test(cnpj)) {
        return true
      }
    }
    return false
  },
  isInvalidWalletAddress: (value: string | undefined) => {
    if (value) {
      const regex = /^(0x)?[0-9a-fA-F]{40}$/
      return !regex.test(value)
    }
    return false
  },
  isEmailBlockList: (value: string, blockedDomains: string[]) => {
    const domain = value.split("@")[1]

    return blockedDomains?.includes(domain)
  }
}
