import classNames from "classnames"
import { Button, Spinner } from "reactstrap"

interface Web3ButtonHandler {
  id?: string
  fillIn?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  onClick?: Function
  children?: React.ReactNode
  outline?: boolean
  pClassName?: string
}

const Web3ButtonHandler = ({
  id,
  isDisabled = false,
  isLoading = false,
  fillIn,
  outline,
  onClick,
  pClassName,
  children
}: Web3ButtonHandler) => {
  return (
    <Button
      id={id}
      color={!outline && "primary"}
      className={classNames(" p-25 px-1 mx-auto", {
        "w-100": fillIn,
        "rounded-pill": !outline
      })}
      disabled={isDisabled}
      onClick={() => onClick && onClick()}
    >
      {isLoading && <Spinner />}
      {!isLoading && (
        <p className={classNames("m-0 fw-bolder", pClassName)} style={{ fontSize: "1rem" }}>
          {children}
        </p>
      )}
    </Button>
  )
}

export default Web3ButtonHandler
