import Icon, { IconProps } from "@core/components/icon"
import LoginCaption, { LoginCaptionProps } from "@core/components/login/components/typography/caption"
import KycIcon from "./kycIcon"

interface KycCaptionProps extends LoginCaptionProps {
  icon?: IconProps["icon"]
}

const KycCaption = ({ style, icon, ...rest }: KycCaptionProps) => {
  return (
    <div className="login-wrapper ">
      <span className="d-flex align-items-center justify-content-center gap-25">
        {icon && <KycIcon icon={icon} size={"sm"} />}
        <LoginCaption style={{ ...style, color: "#939393" }} {...rest} />
      </span>
    </div>
  )
}

export default KycCaption
