import { useLoadingContext } from "contexts/LoadingContext"
import { Spinner } from "reactstrap"

const LoadingModal = () => {
  const { whitelabelLoading } = useLoadingContext()


  return (
    <>
      <div
        className="modal show d-flex flex-column h-100 w-100 align-items-center justify-content-center"
        role="dialog"
        tabIndex={-1}
        style={{ display: "block" }}
      >
        <Spinner
          style={{
            color: `${whitelabelLoading?.color || "#454545"}`,
            opacity: 1
          }}
        />
      </div>
      <div className="modal-backdrop show" style={{ zIndex: "1050" }}></div>
    </>
  )
}

export default LoadingModal
