const ipfsGatewayUrl = process.env.REACT_APP_IPFS_GATEWAY_URL

export const getIpfsUrl = (url: string, params?: string) => {
  if (!url?.includes("ipfs://")) return `${url}`
  return `${url.replace("ipfs://", ipfsGatewayUrl)}${params}`
}

export const getIpfsImageUrl = (url: string, width?: number, height?: number) => {
  let params = "?"
  params += height ? `img-height=${height}&` : ""
  params += width ? `img-width=${width}` : ""
  return getIpfsUrl(url, params)
}

export const getIpfsVideoStreamUrl = (url: string) => {
  const prevUrl = url
  const newUrl = getIpfsUrl(url)
  return prevUrl === newUrl ? url : `${newUrl}?stream=true`
}
