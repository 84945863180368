import Web3InstructionCard from "./variants/instructionCard"
import PlusIllustration from "assets/images/logo/Plus.png"

const CreateOrImportWalletCard = () => {
  return (
    <>
      <Web3InstructionCard
        title="Create_or_import_your_wallet"
        subtitle="Create_or_import_your_wallet_description"
        image={PlusIllustration}
      />
    </>
  )
}

export default CreateOrImportWalletCard
