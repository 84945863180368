import { useEffect, useState } from "react"
import KycContext, { KycContextValues, KycStatusConcludedStagesEnum, KycStatusStageEnum } from "./KycContext"
import FormProvider from "utility/context/formContext/formProvider"
import kycInitialValues, { KycFormData } from "./kycFormHooks/initialState"
import useKycFormDataValidator from "./kycFormHooks/validate"
import { useAppSelector } from "redux/hooks"
import { patchChangeUserKyc } from "services/user"
import { UserIdentityDocumentsEnum, UserKyc } from "interfaces/user"
import { createNewKycSubmission } from "services/kyc-submissions"

interface KycProviderProps {
  children: React.ReactNode
}

const KycProvider = ({ children }: KycProviderProps) => {
  const { Provider } = KycContext

  const { whitelabel, companyWhitelabel } = useAppSelector((state) => state.whitelabelConfig)
  const { JWT, memberPermissions, userKycStatus } = useAppSelector((state) => state.auth)

  const userStageMap = {
    idle: {
      currentStage: KycStatusStageEnum.START,
      concludedStage: null
    },
    pending_approval: {
      currentStage: KycStatusStageEnum.CONCLUDED,
      concludedStage: KycStatusConcludedStagesEnum.PENDING_APPROVAL
    },
    refused: {
      currentStage: KycStatusStageEnum.CONCLUDED,
      concludedStage: KycStatusConcludedStagesEnum.REFUSED
    }
  }

  const [currentStatusStage, setCurrentStatusStage] = useState<KycStatusStageEnum>(
    userStageMap?.[userKycStatus]?.currentStage ||
    userStageMap[memberPermissions?.accessStatus]?.currentStage ||
    KycStatusStageEnum.START
  )
  const [currentConcludedStatusStage, setCurrentConcludedStatusStage] = useState<KycStatusConcludedStagesEnum>(
    userStageMap?.[userKycStatus]?.concludedStage ||
    userStageMap[memberPermissions?.accessStatus]?.concludedStage ||
    null
  )

  const [useKycSwiper, setUseKycSwiper] = useState(null)
  const [initialValues, setInitialValues] = useState(kycInitialValues({ userData: JWT?.user }))
  const [submissionCreationIsEnabled, setSubmissionCreationIsEnabled] = useState(false)

  const companyKycStages = {
    [KycStatusStageEnum.START]: {
      order: 1
    },
    [KycStatusStageEnum.BASIC_INFOS]: {
      order: 2
    },
    [KycStatusStageEnum.CONCLUDED]: {
      order: 3
    }
  }

  const providerValues: KycContextValues = {
    companyKycStages,
    currentStatusStage,
    setCurrentStatusStage,
    useKycSwiper,
    setUseKycSwiper,
    currentConcludedStatusStage,
    setCurrentConcludedStatusStage,
    setSubmissionCreationIsEnabled,
    submissionCreationIsEnabled
  }

  const handleKycSubmit = async (formData: KycFormData) => {
    const { email, fullName, phone, birthDate, identityDocument } = formData || {}

    const kycUserData: UserKyc = {
      email,
      fullName,
      phone: phone.replace(/\D/g, ""),
      identityDocument: {
        document: identityDocument?.document,
        value: (identityDocument?.value).replace(/\D/g, "")
      }
    }

    if (identityDocument?.document === UserIdentityDocumentsEnum.CPF) {
      kycUserData["birthDate"] = birthDate
    }

    const companyId = companyWhitelabel?.companyId || whitelabel?.companyId

    try {
      await patchChangeUserKyc({ kyc: kycUserData, accessToken: JWT?.accessToken })

      await createNewKycSubmission({
        kyc: { ...kycUserData, companyId },
        accessToken: JWT?.accessToken
      })

      setCurrentStatusStage(KycStatusStageEnum.CONCLUDED)
      setCurrentConcludedStatusStage(KycStatusConcludedStagesEnum.PENDING_APPROVAL)
      useKycSwiper.slideNext()
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (initialValues?.identityDocument?.document && currentStatusStage === KycStatusStageEnum.START) {
      setCurrentStatusStage(KycStatusStageEnum.BASIC_INFOS)
    }
  }, [initialValues])

  return (
    <>
      <Provider value={providerValues}>
        <FormProvider
          initialState={initialValues}
          submitCallback={(formData) => handleKycSubmit(formData as KycFormData)}
          validate={useKycFormDataValidator}
          failedSubmitCallback={null}
        >
          {children}
        </FormProvider>
      </Provider>
    </>
  )
}

export default KycProvider
