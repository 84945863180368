import WeispaceLoginSwiperBanners from "./banners"

interface WeispaceLoginSwiperSlideContentProps {
  banner?: keyof typeof WeispaceLoginSwiperBanners
}

const WeispaceLoginSwiperSlideContent = ({ banner }: WeispaceLoginSwiperSlideContentProps) => {
  const Banner = WeispaceLoginSwiperBanners[banner]

  return <Banner />
}

export default WeispaceLoginSwiperSlideContent
