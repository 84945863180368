import classNames from "classnames"
import { Input, InputProps } from "reactstrap"

export interface DefaultInputProps extends InputProps {
  className?: string
  showError?: boolean
  border?: string
}

const DefaultInput = ({ showError, className, border, ...rest }: DefaultInputProps) => {
  return (
    <>
      <Input
        className={classNames("", {
          "border-danger": showError,
          [border]: !showError,
          [className]: className
        })}
        {...rest}
      />
    </>
  )
}

export default DefaultInput
