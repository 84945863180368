import { lazy } from "react"

const LazyFaq = lazy(() => import("./pages/faq"))

const mainFaqPath = '/weispace/faq'

export const faqPaths = {
  home: mainFaqPath
}

const faqRoutes = [
  {
    path: faqPaths.home,
    element: <LazyFaq />
  }
]

export default faqRoutes
