import { validate } from "validations"

interface UseBuildParamsUrlProps {
  url: string
  params: { [key: string]: any }
}

const useBuildParamsUrl = ({ params, url }: UseBuildParamsUrlProps) => {
  const paramsObj = []

  Object.entries(params).forEach(([key, value]) => {
    if (!validate.isEmpty(value)) {
      paramsObj.push(`${key}=${value}`)
    }
  })

  const paramString = paramsObj.join("&")
  const separator = url.includes("?") ? "&" : "?"

  return `${url}${separator}${paramString}`
}

export default useBuildParamsUrl
