import axios from "axios"

const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const axiosBaseV2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL_V2
})

const getAxiosConfig = (JwtAccessToken: string) => {
  return {
    headers: {
      Authorization: `Bearer ${JwtAccessToken}`
    }
  }
}

export { axiosBase, axiosBaseV2, getAxiosConfig }
