import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"

import MetaMaskLogo from "assets/images/logo/metamask.png"
import { BrowserNames } from "./useGetBrowserData"

interface UseGetWeb3ProvidersLogoProps {
  provider: Web3AuthProvidersEnum
}

type ProvidersLogoData = {
  [key in Web3AuthProvidersEnum]: {
    logo: string
    name: string
    faq: string
    mobileInstallationLink: string
    desktopInstallationLink: {
      [key in BrowserNames]?: string
    }
  }
}

/**
 * Get the data of the web3 provider
 * @param provider The web3 provider
 */

const useGetWeb3ProvidersData = ({ provider }: UseGetWeb3ProvidersLogoProps) => {
  const providersLogo: ProvidersLogoData = {
    MetaMask: {
      logo: MetaMaskLogo,
      name: "MetaMask",
      faq: "https://metamask.io/faqs/",
      mobileInstallationLink: "https://metamask.io/download.html",
      desktopInstallationLink: {
        Firefox: "https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/",
        Brave:"https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=metamask.io",
        Opera: "https://addons.opera.com/en-gb/extensions/details/metamask-10/",
        Edge: "https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US",
        Chrome: "https://chromewebstore.google.com/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?utm_source=metamask.io"
      }
    }
  }

  return providersLogo?.[provider]
}

export default useGetWeb3ProvidersData
