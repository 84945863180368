import Icon, { IconProps } from "@core/components/icon"

interface KycIconProps extends IconProps {
  color?: string
  size?: "sm" | "md" | "lg"
}

const KycIcon = ({ size = "md", color = "#939393", ...iconProps }: KycIconProps) => {
  const kycIconSize = {
    sm: "16px",
    md: "1.5rem",
    lg: "2.5rem"
  }

  return <Icon {...iconProps} style={{ color, flexShrink: 0 }} size={kycIconSize[size]} className="" />
}

export default KycIcon
