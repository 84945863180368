import WeispaceLoginBannerRoot from "./root"
import TrackItAllImage from "web-weispace/assets/images/pages/login/be_coOwner.png"

const WeispaceLoginTrackItAllBanner = () => {
  const { Description, Image, Title, Container } = WeispaceLoginBannerRoot

  return (
    <Container>
      <Title text="Weispace_Login_Banner_Track_It_All_Title" />
      <Image src={TrackItAllImage} />
      <Description text="Weispace_Login_Banner_Track_It_All_Description" />
    </Container>
  )
}

export default WeispaceLoginTrackItAllBanner
