import BackButtonHandler from "@core/components/login/components/buttons/backButtonHandler"

import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import { useTranslation } from "react-i18next"

const LoginWeb3StageHeader = () => {
  const { resetWeb3Auth, selectedWeb3AuthProvider } = useWeb3AuthContext()
  const { t } = useTranslation()

  return (
    <div className="login-header w-100">
      <BackButtonHandler handleClickAction={resetWeb3Auth} disabled={true} />
      <h4 className="m-0 text-center fw-bolder" style={{maxWidth: "320px"}}>
        {t("Connecting_With")} {t(selectedWeb3AuthProvider)}
      </h4>
    </div>
  )
}

export default LoginWeb3StageHeader
