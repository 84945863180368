import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface Web3CardSubtitleProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const Web3CardSubtitle = ({ className, text, style, textProps = {}, ...rest }: Web3CardSubtitleProps) => {
  const { t } = useTranslation()
  return (
    <p className={classNames("m-0", className)} {...rest} style={{ fontSize: "13px", lineHeight: "18px", ...style }}>
      {t(text, textProps)}
    </p>
  )
}

export default Web3CardSubtitle
