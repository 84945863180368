import { useContext } from "react"
import MarketPlaceKycGuardContext from "./marketPlaceKycGuardContext"


const useMarketPlaceKycGuard = () => {
  const context = useContext(MarketPlaceKycGuardContext)

  if (!context) {
    throw new Error("useMarketPlaceKycGuard must be used within a MarketPlaceKycGuardProvider")
  }

  return context
}


export default useMarketPlaceKycGuard