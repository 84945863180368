import { createSlice } from "@reduxjs/toolkit"
import loadingInitialState from "./loadingInitialState"
import loadingReducers from "./loadingReducers"

const loadingSlice = createSlice({
  name: "loading",
  initialState: loadingInitialState,
  reducers: loadingReducers,
  extraReducers: builder => {}
})

export const {
  setPageLoading
} = loadingSlice.actions

export default loadingSlice.reducer