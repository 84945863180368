export enum GuardStateEnum {
  REQUIRED = "required",
  OPTIONAL = "optional",
  DISABLED = "disabled"
}

export interface WhiteLabelGuardStateI {
  state: GuardStateEnum
}

export interface WhiteLabelPrivateGuardI extends WhiteLabelGuardStateI {}

export interface WhiteLabelKycGuardI extends WhiteLabelGuardStateI {}

export interface WhiteLabelAuthGuardI extends WhiteLabelGuardStateI {
  kyc: WhiteLabelKycGuardI
}

export class AgeRestrictionGuardLayoutI {
  logo: string
  buttonColor: string
  backgroundColor: string
  cardBackgroundColor: string
  primaryTextColor: string
  secondaryTextColor: string
  buttonHoverColor: string
  buttonTextColor: string
}

export interface WhiteLabelAgeRestrictionGuardI  {
  enabled: boolean
  minimumAgeRequired: number
  layout: AgeRestrictionGuardLayoutI
}

export interface WhiteLabelGuardI {
  private?: WhiteLabelPrivateGuardI
  auth?: WhiteLabelAuthGuardI
}
