import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { ERCTransferBetweenMembersI } from "./interface"

export const handleTransferTransactionBetweenMembers = createAsyncThunk(
  "/transfer/betweenMembers",
  async ({
    ercTransactionDto,
    accessToken,
    tokenId
  }: {
    accessToken?: string
    ercTransactionDto: ERCTransferBetweenMembersI
    tokenId: string
  }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
    const {data} = await axios.post(
      `${process.env.REACT_APP_API_URL}/tokens/${tokenId}/member/transfer`,
      ercTransactionDto,
      config
    )
    return data
  }
)
