import classNames from "classnames"
import { IconType } from "react-icons"

export interface IconProps {
  icon: IconType
  size?: number | string
  className?: string
  noShrink?: boolean
  style?: React.CSSProperties
  [key: string]: any
}

const Icon: React.FC<IconProps> = ({ icon, size = 20, noShrink, className, ...otherProps }) => {
  const IconComponent = icon
  return (
    <IconComponent
      size={size}
      className={classNames("", {
        [className]: className
      })}
      style={{
        flexShrink: noShrink ? 0 : 1
      }}
      {...otherProps}
    />
  )
}

export default Icon
