import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { axiosBase } from "../axiosbase"
import { cookies } from "../../utility/cookies"
import { getAnyTimeFromNow } from "utility/DateTimeUtils"

export const fetchJWTGoogle = createAsyncThunk("firebase/JWT/Google", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/google", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTFacebook = createAsyncThunk("firebase/JWT/Facebook", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/facebook", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTMicrosoft = createAsyncThunk("firebase/JWT/Microsoft", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/microsoft", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchJWTApple = createAsyncThunk("firebase/JWT/Apple", async (body: object) => {
  try {
    const { data } = await axiosBase.post("/auth/apple", body)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})

export const fetchAutoLogin = createAsyncThunk("auth/jwt", async (config: object) => {
  try {
    const { data } = await axiosBase.post("/login/jwt", {}, config)
    return data
  } catch (error) {
    throw new Error(error.response.data)
  }
})
const session = () => {
  const userDataCookies = cookies.get("userData")
  const userData = !!userDataCookies ? JSON.parse(userDataCookies) : null
  if (userData && userData.type !== "Metamask") {
    return userData
  }
  return null
}
export const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    JWT: null || session(),
    JWTError: null,
    JWTLoading: false
  },
  reducers: {
    removeSession: (state, action) => {
      state.JWTLoading = false
      state.JWT = null
      cookies.remove("userData", { path: "/" })
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJWTGoogle.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTGoogle.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        const urlDomain = window.location.hostname
        const configs = { path: "/" }
        if (urlDomain?.includes("intimus.art")) {
          configs["domain"] = ".festival-intimus.art"
        }
        cookies.set(
          "userData",
          JSON.stringify({ ...payload, expires: new Date(getAnyTimeFromNow()), type: "Google" }),
          configs
        )
      })
      .addCase(fetchJWTGoogle.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTFacebook.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTFacebook.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        const urlDomain = window.location.hostname
        const configs = { path: "/" }
        if (urlDomain?.includes("intimus.art")) {
          configs["domain"] = ".festival-intimus.art"
        }
        cookies.set(
          "userData",
          JSON.stringify({ ...payload, expires: new Date(getAnyTimeFromNow()), type: "Facebook" }),
          configs
        )
      })
      .addCase(fetchJWTFacebook.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTMicrosoft.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTMicrosoft.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        const urlDomain = window.location.hostname
        const configs = { path: "/" }
        if (urlDomain?.includes("intimus.art")) {
          configs["domain"] = ".festival-intimus.art"
        }
        cookies.set(
          "userData",
          JSON.stringify({ ...payload, expires: new Date(getAnyTimeFromNow()), type: "Microsoft" }),
          configs
        )
      })
      .addCase(fetchJWTMicrosoft.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchJWTApple.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchJWTApple.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        const urlDomain = window.location.hostname
        const configs = { path: "/" }
        if (urlDomain?.includes("intimus.art")) {
          configs["domain"] = ".festival-intimus.art"
        }
        cookies.set(
          "userData",
          JSON.stringify({ ...payload, expires: new Date(getAnyTimeFromNow()), type: "Apple" }),
          configs
        )
      })
      .addCase(fetchJWTApple.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })

      .addCase(fetchAutoLogin.pending, (state) => {
        state.JWTLoading = true
      })
      .addCase(fetchAutoLogin.fulfilled, (state, { payload }) => {
        state.JWTLoading = false
        state.JWT = payload
        const urlDomain = window.location.hostname
        const configs = { path: "/" }
        if (urlDomain?.includes("intimus.art")) {
          configs["domain"] = ".festival-intimus.art"
        }
        cookies.set(
          "userData",
          JSON.stringify({ ...payload, expires: new Date(getAnyTimeFromNow()), type: "auto-login" }),
          configs
        )
      })
      .addCase(fetchAutoLogin.rejected, (state, { payload }) => {
        state.JWTLoading = false
        state.JWTError = payload
      })
  }
})

export const { removeSession } = authSlice.actions
export default authSlice.reducer
