import { createSlice } from "@reduxjs/toolkit"
import { getCommunityBySlug } from "./slugsActions"
import { CreatedCommunity } from "utility/request-interfaces/community-types"

const communityBySlugState = {
  communityBySlug: {} as CreatedCommunity,
  communityBySlugLoading: true,
  communityBySlugError: undefined
}

const communityBySlugSlice = createSlice({
  name: "communityBySlug",
  initialState: communityBySlugState,
  reducers: {
    setcommunityBySlug: (state, { payload }) => {
      state.communityBySlug = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCommunityBySlug.pending, (state) => {
      state.communityBySlugLoading = true
    })
    builder.addCase(getCommunityBySlug.fulfilled, (state, { payload }) => {
      state.communityBySlug = payload
      state.communityBySlugLoading = false
    })
    builder.addCase(getCommunityBySlug.rejected, (state, { error }) => {
      state.communityBySlugError = error
      state.communityBySlugLoading = false
    })
  }
})

export default communityBySlugSlice.reducer
