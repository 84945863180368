/**
  * Redirects the user to the MetaMask mobile app to login.
  * @returns {void}
*/

const handleMetaMaskMobileLogin = () => {
  const location = window.location
  return window.open(`https://metamask.app.link/dapp/${location?.hostname}${location?.pathname}${location?.search}`)
}

export default handleMetaMaskMobileLogin