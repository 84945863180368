import Icon, { IconProps } from "@core/components/icon"
import classNames from "classnames"
import { PiWarningCircle } from "react-icons/pi"
import { Spinner } from "reactstrap"

interface LoginInputRightSideIconProps {
  isLoading?: boolean
  haveError?: boolean
  errorIcon?: IconProps["icon"]
  size?: IconProps["size"]
}

/**
 * Add a left side icon inside the input
 * @requires LoginInputContentContainer(Container) - To position absolute works
 */

const LoginInputRightSideIcon = ({
  errorIcon = PiWarningCircle,
  isLoading = false,
  haveError = false,
  size = "2rem"
}: LoginInputRightSideIconProps) => {
  return (
    <>
      <div
        className={classNames("position-absolute d-flex align-items-center justify-content-center", {
          "d-none": isLoading && haveError
        })}
        style={{
          top: "50%",
          right: "0.4rem",
          transform: "translate(0%, -50%)"
        }}
      >
        {isLoading && <Spinner size={size} />}
        {haveError && <Icon icon={errorIcon} className="text-danger" size={size} />}
      </div>
    </>
  )
}

export default LoginInputRightSideIcon
