import DisplaySource from "components/displaySource"
import { useTranslation } from "react-i18next"
import OtpImage from "assets/images/pages/Otp_Image.png"
import BackButtonHandler from "../../components/buttons/backButtonHandler"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"
import useAuthContext from "contexts/auth/AuthInContext"
import useMarketPlaceKycGuard from "web-marketplace/contexts/guards/auth/kyc/useMarketPlaceKycGuardContext"

const LoginOtpStageHeader = () => {
  const { t } = useTranslation()

  const { resetEmailStep, isEmailAuthLoading } = useEmailAuthContext()
  const { isUserLoggedIn } = useAuthContext()
  const { isMarketPlaceKycGuardShieldEnabled } = useMarketPlaceKycGuard() || {}
  const isLoading = isEmailAuthLoading || (isMarketPlaceKycGuardShieldEnabled && isUserLoggedIn)
  return (
    <div className="login-wrapper h-100">
      <BackButtonHandler handleClickAction={resetEmailStep} disabled={isLoading} />
      <h4 className="m-0 text-center fw-bolder">{t("OTP_Title")}</h4>
      <DisplaySource src={OtpImage} centralized contained size={{ maxWidth: "240px" }} />
    </div>
  )
}

export default LoginOtpStageHeader
