import { MdOutlineEmail } from "react-icons/md"
import KycCaption from "../../../components/kycCaption"
import KycHeaderLayout from "../../../header"
import { useAppSelector } from "redux/hooks"
import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import useMarketPlaceAuthContext from "web-marketplace/contexts/auth/useMarketPlaceAuthContext"

const KycResultPendingStageSlide = () => {
  const { userKycStatus, memberPermissions } = useAppSelector((state) => state.auth)
  const { handleAuthLogout } = useMarketPlaceAuthContext()

  if (userKycStatus === "pending_approval" || memberPermissions?.accessStatus === "pending_approval") {
    return (
      <>
        <KycHeaderLayout title="KYC_Result_Pending" description="KYC_Result_Pending_Description" src="" />
        <div className="login-wrapper d-flex flex-column gap-75 h-100"></div>
        <div className="login-wrapper gap-2">
          <ButtonHandler type="button" handleClickAction={() => handleAuthLogout()} text="Logout" />
          <KycCaption icon={MdOutlineEmail} text="KYC_Result_Pending_Caption" />
        </div>
      </>
    )
  }

  return <></>
}

export default KycResultPendingStageSlide
