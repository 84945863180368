import React from "react"
import AuthButtonRoot, { AuthButtonProps } from "./root"
import useAuthContext from "contexts/auth/AuthInContext"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"

interface CompactAuthButtonProps extends AuthButtonProps {}

const CompactAuthButton: React.FC<CompactAuthButtonProps> = ({ authMethod, handleClickAction, src, id }) => {
  const { Container, Logo } = AuthButtonRoot
  const { isFirebaseAuthLoading, isMetamaskAuthLoading } = useAuthContext()

  const { isEmailAuthLoading } = useEmailAuthContext()

  const isLoading = isFirebaseAuthLoading || isMetamaskAuthLoading || isEmailAuthLoading
  return (
    <Container isLoading={isLoading} handleClickAction={handleClickAction} className="p-75 px-2 rounded-3" id={id}>
      <Logo src={src} authMethod={authMethod} />
    </Container>
  )
}

export default CompactAuthButton
