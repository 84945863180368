// ** React Imports
import { useEffect } from "react"

// ** Store Imports
import { handleSkin } from "@store/layout"
import { useDispatch, useSelector } from "react-redux"

export const useSkin = () => {
  // ** Hooks
  const dispatch = useDispatch()
  const store = useSelector((state) => state.layout)

  const setSkin = (type) => {
    dispatch(handleSkin(type))
  }

  useEffect(() => {
    // ** Get Body Tag
    const element = window.document.body

    // ** Define classnames for skins
    const classNames = {
      dark: "dark-layout",
      bordered: "bordered-layout",
      "semi-dark": "semi-dark-layout"
    }

    // ** Remove all classes from Body on mount
    element.classList.remove(...element.classList)

    // ** If skin is not light add skin class
    if (store.skin !== "light") {
      element.classList.add(classNames[store.skin])
    }
  }, [store.skin])

  const handleSkinClass = (element) => {
    const skin = store.skin
    const isDarkSkin = skin === "dark"

    const elementSkinMap = {
      button: isDarkSkin ? "secondary" : "primary",
      buttonColor: isDarkSkin ? "" : "primary",
      buttonText: isDarkSkin ? "text-primary" : "text-light",
      buttonBorder: isDarkSkin ? "light-secondary" : "light-primary",
      inputBorder: isDarkSkin ? "light-secondary " : "light-primary",
      dropzoneBorder: isDarkSkin ? "light-secondary" : "#ebe9f1",
      text: isDarkSkin ? "white" : "",
      providerLogo: isDarkSkin ? "#404040" : "#F4F4F4",
      body: isDarkSkin ? "bg-body-dark" : "bg-body",
      buttonCardColor: isDarkSkin ? "#2b2d31" : ""
    }

    return elementSkinMap[element]
  }

  return { skin: store.skin, setSkin, handleSkinClass }
}
