import WeispaceLoginEarnRewardsBanner from "./earn-rewards"
import WeispaceLoginJoinCommunityBanner from "./join_community"
import WeispaceLoginTrackItAllBanner from "./track-it-all"
import WeispaceLoginUnlockAccessBanner from "./unlock-access"
import WeispaceLoginWelcomeBanner from "./welcome"

const WeispaceLoginSwiperBanners = {
  welcome: WeispaceLoginWelcomeBanner,
  join_community: WeispaceLoginJoinCommunityBanner,
  earn_rewards: WeispaceLoginEarnRewardsBanner,
  track_it_all: WeispaceLoginTrackItAllBanner
}

export default WeispaceLoginSwiperBanners
