import classNames from "classnames"
import { SwiperRootProps } from "."

interface SwiperContainerProps extends SwiperRootProps {}

const SwiperContainer = ({ children, className, ...rest }: SwiperContainerProps) => {
  return (
    <div className={classNames("w-100 m-1", className)} {...rest}>
      {children}
    </div>
  )
}

export default SwiperContainer
