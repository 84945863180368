import ExternalWallet from "@core/components/login/stages/login/externalWallet"
import SocialLogins from "./socialLogins"
import EmailLogin from "./emailLogin"
import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "redux/hooks"

const LoginStageBody = () => {
  const { t } = useTranslation()
  const { whitelabel, companyWhitelabel } = useAppSelector((state) => state.whitelabelConfig)
  const userSpaceLayout = whitelabel?.layout?.userSpace?.loginPhrase
    ? whitelabel.layout.userSpace.loginPhrase
    : "Text Modal Login"

  const companyId = whitelabel?.companyId || companyWhitelabel?.companyId

  const galoButtonCondition = companyId === "88bf0223-8d5a-41bd-9ed2-4ec36eb9ace4" || companyId === "b82050d1-f35d-4cb8-adba-320b495875a7"

  return (
    <div className="login-wrapper">
      <p className={classNames("text-center fs-6 m-0", {})}>
        {whitelabel?.companyId !== "20137b2e-c89c-4e22-89c4-cc8e98838fe7" && t(userSpaceLayout)}
        {whitelabel?.companyId === "20137b2e-c89c-4e22-89c4-cc8e98838fe7" && t("KC_Login_Modal")}
      </p>
      <div className="d-flex flex-column gap-1">
        { galoButtonCondition ? <EmailLogin /> : <SocialLogins /> }
        { galoButtonCondition ? <SocialLogins /> : <EmailLogin /> }
        <ExternalWallet />
      </div>
    </div>
  )
}

export default LoginStageBody
