import useLogger from "utility/hooks/logger/useLogger"
import { useEffect, useLayoutEffect, useMemo, useState } from "react"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import MarketPlaceKycGuardContext, { MarketPlaceKycGuardContextI } from "./marketPlaceKycGuardContext"
import toggleMarketPlaceKycGuardShield from "./effectsCallbacks/toggleShield"
import { GuardStateEnum } from "interfaces/store/whitelabel-guard"
import useAuthContext from "contexts/auth/AuthInContext"
import checkUserMarketPlaceGuardKycEffect from "./effectsCallbacks/checkUserKyc"
import { useLocation } from "react-router-dom"
import showMarketPlaceKycGuardForm from "./effectsCallbacks/handleKycForms"
import defaultMarketPlaceKycGuardEffect from "./effectsCallbacks/default"

interface MarketPlaceKycGuardProviderProps {
  children: React.ReactNode
}

const MarketPlaceKycGuardProvider = ({ children }: MarketPlaceKycGuardProviderProps) => {
  const { Provider } = MarketPlaceKycGuardContext
  const { createLogger } = useLogger(true)
  const dispatch = useAppDispatch()

  const location = useLocation()
  const params = location?.pathname.split("/")
  const isCollectionParams = params[1] === "collection"
  const isTokenParams = params[1] === "token"

  const { companyWhitelabel } = useAppSelector((state) => state.whitelabelConfig)
  const { JWT, memberPermissions } = useAppSelector((state) => state.auth)
  const { isUserLoggedIn, setCurrentAuthStageShowing } = useAuthContext()

  const kycGuard = companyWhitelabel?.guard?.auth

  const canBeAPrivateRoute = isCollectionParams || isTokenParams

  const isKycRequired = kycGuard?.kyc?.state === GuardStateEnum["REQUIRED"] || false
  const isKycOptional = kycGuard?.kyc?.state === GuardStateEnum["OPTIONAL"] || false

  const shieldIsAutoEnabled = isKycOptional && canBeAPrivateRoute && !isUserLoggedIn
  const shieldNeedToBeEnabled =
    isKycOptional &&
    canBeAPrivateRoute &&
    (Object.keys(memberPermissions).length === 0 || memberPermissions?.accessStatus !== "approved")
  const guardMustBeEnabled = isKycRequired || isKycOptional
  const shieldMustBeEnabled = isKycRequired || shieldIsAutoEnabled || shieldNeedToBeEnabled

  const [isGuardEnabled, setIsGuardEnabled] = useState<boolean>(guardMustBeEnabled)
  const [isShieldEnabled, setIsShieldEnabled] = useState<boolean | null>(shieldMustBeEnabled)
  const [isLayoutRender, setIsLayoutRender] = useState<boolean>(true)

  useLayoutEffect(() => {
    defaultMarketPlaceKycGuardEffect({
      createLogger,
      dispatch,
      isCollectionParams,
      isTokenParams,
      isUserLoggedIn,
      memberPermissions,
      checkData: {
        accessToken: JWT?.accessToken,
        slug: params[2]
      }
    })
    setIsLayoutRender(false)
  }, [companyWhitelabel])

  useEffect(() => {
    if (!isLayoutRender) {
      toggleMarketPlaceKycGuardShield({
        createLogger,
        isGuardEnabled,
        isShieldEnabled,
        setIsShieldEnabled,
        shieldMustBeEnabled
      })
    }
  }, [shieldMustBeEnabled])

  useEffect(() => {
    if (!isLayoutRender) {
      checkUserMarketPlaceGuardKycEffect({
        checkData: {
          accessToken: JWT?.accessToken,
          slug: params[2]
        },
        createLogger,
        dispatch,
        isGuardEnabled,
        isShieldEnabled,
        isUserLoggedIn,
        isCollectionParams,
        isTokenParams
      })
    }
  }, [isUserLoggedIn, isShieldEnabled])

  const mustResetMemberPermissions = !isCollectionParams && !isTokenParams
  useEffect(() => {
    if (!isLayoutRender && isGuardEnabled) {
      showMarketPlaceKycGuardForm({
        createLogger,
        memberPermissions,
        setCurrentAuthStageShowing,
        dispatch,
        mustResetMemberPermissions,
        isUserLoggedIn
      })
    }
  }, [memberPermissions, mustResetMemberPermissions, isUserLoggedIn])

  // useEffect(
  //   () =>
  //     toggleMarketPlaceGuardEffect({
  //       createLogger,
  //       shieldMustBeEnabled,
  //       isGuardEnabled,
  //       setIsGuardEnabled,
  //       guardMustBeEnabled
  //     }),
  //   [companyWhitelabel]
  // )

  // useEffect(
  //   () =>
  //     toggleMarketPlaceKycGuardShield({
  //       createLogger,
  //       isGuardEnabled,
  //       isKycRequired,
  //       isShieldEnabled,
  //       isUserLoggedIn,
  //       setCurrentAuthStageShowing,
  //       setIsShieldEnabled,
  //       shouldUserDoKyc,
  //       userHaveAccessPermission
  //     }),
  //   [userAccessPermissionIsCompleted]
  // )

  const values: MarketPlaceKycGuardContextI = {
    isMarketPlaceKycGuardEnabled: isGuardEnabled,
    setIsMarketPlaceKycGuardEnabled: setIsGuardEnabled,
    isMarketPlaceKycGuardShieldEnabled: isShieldEnabled,
    setIsMarketPlaceKycGuardShieldEnabled: setIsShieldEnabled
  }

  const providerValues = useMemo(() => values, [Object.values(values)])

  return <Provider value={providerValues}>{children}</Provider>
}

export default MarketPlaceKycGuardProvider
