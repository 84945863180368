import { Dispatch, SetStateAction, createContext } from "react"

export interface WhiteLabelIsFetchedState {
  whiteLabelIsFetched: boolean
  setWhiteLabelIsFetched: Dispatch<SetStateAction<boolean>>
}

export interface WhitelabelContextI extends Pick<WhiteLabelIsFetchedState, "whiteLabelIsFetched"> {
  isWhiteLabelProviderLoading: boolean
}

const WhitelabelContext = createContext({} as WhitelabelContextI)

export default WhitelabelContext
