import { useWeispaceAppSelector } from "redux/hooks"


const useLoadings = () => {
  const { communitiesLoading } = useWeispaceAppSelector((state) => state.communities)
  const { collectionsLoading } = useWeispaceAppSelector((state) => state.collections)
  const { tokensLoading } = useWeispaceAppSelector((state) => state.tokens)
  const { isPageLoading } = useWeispaceAppSelector((state) => state.loading)

  return { communitiesLoading, collectionsLoading, tokensLoading, isPageLoading }
}

export default useLoadings