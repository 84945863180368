import React from "react"

export const isRunningStandalone = () => {
  return window.matchMedia("(display-mode: standalone)").matches
}
export const checkBrowser = (agent) => {
  // Detect Chrome
  let browser
  const chromeAgent = agent.indexOf("chrome") > -1 || agent.indexOf("crios") > -1
  if (chromeAgent) browser = "Chrome"
  // Detect Internet Explorer
  const IExplorerAgent = agent.indexOf("msie") > -1 || agent.indexOf("rv:") > -1
  if (IExplorerAgent) browser = "Internet Explorer"
  // Detect Firefox
  const firefoxAgent = agent.indexOf("firefox") > -1
  if (firefoxAgent) browser = "Firefox"
  // Detect Safari
  const safariAgent = agent.indexOf("safari") > -1
  if (safariAgent) browser = "Safari"
  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) {
    browser = "Chrome"
  }

  // Detect Opera
  const operaAgent = agent.indexOf("opr") || agent.indexOf("opt") > -1
  // Discard Chrome since it also matches Opera
  if (chromeAgent && operaAgent) {
    browser = "Opera"
  }
  return browser
}
export const useDiscoverDevice = () => {
  const [userAgentData, setUserAgentData] = React.useState(null)
  const [browser, setBrowser] = React.useState(null)
  const [device, setDevice] = React.useState<"iPhone" | "Android" | "Desktop">(null)

  React.useEffect(() => {
    const lowerCaseUserAgent = navigator.userAgent.toLocaleLowerCase()
    const isMobile = lowerCaseUserAgent.includes("mobi")

    if (isMobile) {
      if (lowerCaseUserAgent.includes("iphone")) {
        setDevice("iPhone")
      }
      if (lowerCaseUserAgent.includes("android")) {
        setDevice("Android")
      }
    } else {
      setDevice("Desktop")
    }
    setBrowser(checkBrowser(lowerCaseUserAgent))
    setUserAgentData(lowerCaseUserAgent)
  }, [])
  return { userAgentData, browser, device }
}
