import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface WeispaceLoginBannerDescriptionProps {
  text: string
}

const WeispaceLoginBannerDescription = ({ text }: WeispaceLoginBannerDescriptionProps) => {
  const { t } = useTranslation()
  return (
    <p
      className="m-0 text-center fs-4"
    >
      {t(text)}
    </p>
  )
}

export default WeispaceLoginBannerDescription
