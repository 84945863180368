import toast from "react-hot-toast"
import { useQuery } from "react-query"
import { axiosBase, getAxiosConfig } from "services/axiosBase"

interface FetchUserMembershipCoinsBalanceProps {
  accessToken: string
  tokenId: string
  t: any
}

const refreshUserMembershipCoinsBalance = async ({
  accessToken,
  tokenId,
  t,
  promiseToastEnabled
}: Pick<FetchUserMembershipCoinsBalanceProps, "accessToken" | "tokenId" | "t"> & { promiseToastEnabled?: boolean }) => {
  const config = getAxiosConfig(accessToken)
  const fetch = axiosBase.get(`tokens/${tokenId}/refresh/user/balanceOf/coins`, config)
  if (promiseToastEnabled) {
    toast.promise(
      fetch,
      {
        loading: t("Coins_Balance_Refreshing"),
        success: (response) => (response?.data ? t("Coins_Balance_Refreshed") : t("Coins_Balance_Already_UpToDate")),
        error: t("Coins_Balance_Refresh_Failed")
      },
      {
        position: "top-right",
        duration: 2000
      }
    )
  }

  return (await fetch).data
}

const fetchUpdatedUserMembershipCoinsBalance = ({ accessToken, tokenId, t }: FetchUserMembershipCoinsBalanceProps) => {
  const {
    data: updatedUserMembershipCoinsBalanceData,
    isLoading: isUpdatedUserMembershipCoinsBalanceLoading,
    refetch: refetchUpdatedUserMembershipCoinsBalance,
    isRefetching: isUpdatedUserMembershipCoinsBalanceRefetching,
    ...rest
  } = useQuery(
    `refresh/coins/${tokenId}`,
    () => refreshUserMembershipCoinsBalance({ accessToken, tokenId, promiseToastEnabled: true, t }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false
    }
  )

  return {
    updatedUserMembershipCoinsBalanceData,
    isUpdatedUserMembershipCoinsBalanceLoading,
    refetchUpdatedUserMembershipCoinsBalance,
    isUpdatedUserMembershipCoinsBalanceRefetching,
    more: { ...rest }
  }
}

export default fetchUpdatedUserMembershipCoinsBalance
