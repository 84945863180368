import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"
import Web3CallToActionCard from "./variants/callToActionCard"
import useGetWeb3ProvidersData from "@core/components/login/utils/hooks/useGetWeb3ProvidersData"
import { useTranslation } from "react-i18next"

interface ProviderForMobileCardProps {
  provider: Web3AuthProvidersEnum
}

const ProviderForMobileCard = ({ provider }: ProviderForMobileCardProps) => {
  const { t } = useTranslation()
  const { logo, mobileInstallationLink } = useGetWeb3ProvidersData({ provider }) || {}

  const handleOpenLink = () => {
    window.open(mobileInstallationLink, "_blank")
  }

  return (
    <Web3CallToActionCard
      id={`${provider}-mobile-plugin-added`}
      comingSoon
      onClick={handleOpenLink}
      image={logo}
      title={"{{provider}}_for_Mobile"}
      titleProps={{ provider }}
      subtitle="Provider_for_Mobile_Subtitle"
      buttonText={t("Install")}
    />
  )
}

export default ProviderForMobileCard
