import { isMobile } from "react-device-detect"
import Web3MobileQrCodeConnect from "../../components/web3MobileQrCodeConnect"
import { useTranslation } from "react-i18next"
import BrowsePluginCard from "../../components/card/browserCard"
import Web3CallToActionCard from "../../components/card/variants/callToActionCard"
import Web3InstructionCard from "../../components/card/variants/instructionCard"
import MetamaskLogo from "assets/images/logo/metamask.png"
import RefreshIlu from "assets/images/logo/Refresh.png"

const Web3DesktopIdleConnect = () => {
  const { t } = useTranslation()
  if (!isMobile) {
    return (
      <>
        {/* <div className="d-flex flex-column gap-1">
          <div className="bg-light rounded-3 text-end">
            <Web3MobileQrCodeConnect />
          </div>
          <div className="d-flex align-items-center gap-25 justify-content-between">
            <span className="fw-bolder" style={{ fontSize: "0.95rem" }}>
              {t("Dont_have_mobile_wallet")}?
            </span>
            <ButtonHandler>
              Get
            </ButtonHandler>
          </div>
        </div> */}
      </>
    )
  }

  return null
}

export default Web3DesktopIdleConnect
