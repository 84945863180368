import { Fragment } from "react"
import { useAppSelector } from "redux/hooks"
import LoginPage from "views/login/index"
import FallbackLoading from "web-marketplace/provider/fallbackLoading"
import WeispaceLoginSwiperBanners from "web-weispace/views/login/components/swiper/banners"

interface AuthGuardPageProps {
  isLoading?: boolean
}

const AuthGuardPage = ({ isLoading }: AuthGuardPageProps) => {
  const { companyWhitelabel } = useAppSelector((state) => state.whitelabelConfig)

  const swiperData: { banner: keyof typeof WeispaceLoginSwiperBanners }[] = Object.keys(WeispaceLoginSwiperBanners).map(
    (key: keyof typeof WeispaceLoginSwiperBanners) => ({
      banner: key
    })
  )

  return (
    <>
      {isLoading && <FallbackLoading />}
      {!isLoading && (
        <LoginPage
          loginHeaderLogo={companyWhitelabel?.layout?.webStore?.header?.logo}
          swiperData={swiperData}
          hideSwiper
          LoginContainerTag={"div"}
          whitelabelAuth={{
            ...companyWhitelabel?.auth,
            methods: {}
          }}
        />
      )}
    </>
  )
}

export default AuthGuardPage
