import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"
import Web3ButtonHandler from "./handler"
import { useTranslation } from "react-i18next"
import useGetWeb3ProvidersData from "@core/components/login/utils/hooks/useGetWeb3ProvidersData"

interface ProviderFAQButtonProps {
  provider: Web3AuthProvidersEnum
}

const ProviderFAQButton = ({ provider }: ProviderFAQButtonProps) => {
  const { t } = useTranslation()
  const { faq } = useGetWeb3ProvidersData({ provider }) || {}

  const handleOpenFaq = () => {
    window.open(faq, "_blank")
  }

  return (
    <Web3ButtonHandler
      id={`{${provider}}-doc-opened`}
      outline
      onClick={handleOpenFaq}
      pClassName="text-decoration-underline"
    >
      {t("Learn More")}
    </Web3ButtonHandler>
  )
}

export default ProviderFAQButton
