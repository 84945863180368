import classNames from "classnames"
import { SwiperRootProps } from "."
import Container, { ContainerProps } from "../container"

interface StandardSwiperSlideProps extends ContainerProps {}

const StandardSwiperSlide = ({ children, className, ...rest }: StandardSwiperSlideProps) => {
  return (
    <Container className={classNames("", className)} {...rest}
    >
      {children}
    </Container>
  )
}

export default StandardSwiperSlide
