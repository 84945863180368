import { createSlice } from "@reduxjs/toolkit"
import { getPublicCommunityData } from "./communityPublicDataActions"
import { CommunityPublicData } from "utility/request-interfaces/community-types"

const communityPublicDataState = {
  communityPublicData: {} as CommunityPublicData,
  communityPublicLoading: true,
  communityPublicError: undefined
}

const communityPublicDataSlice = createSlice({
  name: "communityPublicData",
  initialState: communityPublicDataState,
  reducers: {
    setCommunityPublicData: (state, { payload }) => {
      state.communityPublicData = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPublicCommunityData.pending, (state) => {
      state.communityPublicLoading = true
    })
    builder.addCase(getPublicCommunityData.fulfilled, (state, { payload }) => {
      state.communityPublicData = payload
      state.communityPublicLoading = false
    })
    builder.addCase(getPublicCommunityData.rejected, (state, { error }) => {
      state.communityPublicError = error
      state.communityPublicLoading = false
    })
  }
})

export default communityPublicDataSlice.reducer
