import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosBase } from "../axiosbase"
// ** axiosBase Imports

export const getPurchases = createAsyncThunk("ecommerce/getPurchases", async (accessToken: string) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  const response = await axiosBase.get(`/products/owned`, config)
  const cookiesCompanyId = localStorage.getItem("companyId")
  const cookiesCommunityId = localStorage.getItem("communityId")
  let data = response.data
  if (cookiesCompanyId) {
    data = data?.filter((product) => product?.details?.item?.companyId === cookiesCompanyId)
  }
  if (cookiesCommunityId) {
    data = data?.filter((product) => product?.details?.item?.communityId === cookiesCommunityId)
  }
  return data
})

export const getPurchase = createAsyncThunk(
  "ecommerce/getPurchase",
  async ({ accessToken, productId }: { accessToken: string; productId: string }) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }
    const response = await axiosBase.get(`/products/owned/${productId}`, config)
    return response.data
  }
)
