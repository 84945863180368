import { CreateLogger } from "utility/hooks/logger/useLogger"
import { MarketPlaceLoginGuardShieldState, MarketPlaceLoginGuardState } from "../marketplaceLoginGuardContext"

interface defaultMarketPlaceLoginGuardEffectProps {
  createLogger: CreateLogger
  isLoginRequired: boolean
  isShieldEnabled: MarketPlaceLoginGuardShieldState["isMarketPlaceLoginGuardShieldEnabled"]
  setIsShieldEnabled: MarketPlaceLoginGuardShieldState["setIsMarketPlaceLoginGuardShieldEnabled"]
  isGuardEnabled: MarketPlaceLoginGuardState["isMarketPlaceLoginGuardEnabled"]
  setIsGuardEnabled: MarketPlaceLoginGuardState["setIsMarketPlaceLoginGuardEnabled"]
  isUserLoggedIn: boolean
}

const defaultMarketPlaceLoginGuardEffect = ({
  createLogger,
  isLoginRequired,
  isShieldEnabled,
  setIsShieldEnabled,
  isGuardEnabled,
  setIsGuardEnabled,
  isUserLoggedIn
}: defaultMarketPlaceLoginGuardEffectProps) => {
  const logger = createLogger("MarketPlaceLoginGuardProvider => Default Effect")

  if (isLoginRequired) {
    logger.add("- Login é obrigatório")
    logger.add("-- Configurando login como obrigatório")
    if (isGuardEnabled) {
      logger.add("--- Guard já está ativo")
    } else {
      logger.add("--- Ativando Guard")
      setIsGuardEnabled(true)
    }
    if (isUserLoggedIn) {
      logger.add("-- Usuário está logado")
      if (isShieldEnabled) {
        logger.add("--- Desativando Shield")
        setIsShieldEnabled(false)
      } else {
        logger.add("--- Shield já está desativado")
      }
    } else {
      logger.add("-- Usuário não está logado")
      if (isShieldEnabled) {
        logger.add("--- Shield já está ativo")
      } else {
        logger.add("--- Ativando Shield")
        setIsShieldEnabled(true)
      }
    }
    logger.read()
  } else {
    logger.add("-- Login não é obrigatório")
    if (isGuardEnabled) {
      logger.add("-- Desativando Guard")
      setIsGuardEnabled(false)
    }
    logger.add("-- Company não requer login obrigatório")
    logger.read()
  }
}

export default defaultMarketPlaceLoginGuardEffect
