import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react"

type AgeGateContextType = {
  isAgeGateShieldOn: boolean
  setIsAgeGateShieldOn: Dispatch<SetStateAction<boolean>>
}

export const AgeGate = createContext({} as AgeGateContextType)

export const AgeGateContext = ({ children }: { children: ReactNode }) => {
  const [isAgeGateShieldOn, setIsAgeGateShieldOn] = useState(false)

  const contextValues = useMemo(
    () => ({
      isAgeGateShieldOn,
      setIsAgeGateShieldOn
    }),
    [isAgeGateShieldOn]
  )

  return <AgeGate.Provider value={contextValues}>{children}</AgeGate.Provider>
}

export function useAgeGateContext() {
  return useContext(AgeGate)
}
