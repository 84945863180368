import React, { useState, ReactNode } from "react"
import { useSearchParams } from "react-router-dom"
import { WhitelabelContext, WhitelabelContextProps } from "web-weispace/utility/context/whitelabelContext"

interface WhitelabelProviderProps {
  children: ReactNode
}

export const WhitelabelProvider: React.FC<WhitelabelProviderProps> = ({ children }) => {
  const [shouldFetchCustomDomain, setShouldFetchCustomDomain] = useState(false)
  const [isCustomDomain, setIsCustomDomain] = useState(false)

  // Busca os ids nos query params da url [Redirect da loja ou externo]
  const [searchParams] = useSearchParams()
  const initialCompanyIdParam = searchParams.get("company")
  const initialCommunityIdParam = searchParams.get("community")

  // Busca os ids no localStorage [Ao recarregar a página]
  const initialCompanyIdCookie = localStorage.getItem("companyId")
  const initialCommunityIdCookie = localStorage.getItem("communityId")

  const whitelabelContextValue: WhitelabelContextProps = {
    isCustomDomain,
    setIsCustomDomain,
    initialCompanyIdCookie,
    initialCommunityIdCookie,
    initialCompanyIdParam,
    initialCommunityIdParam,
    shouldFetchCustomDomain,
    setShouldFetchCustomDomain
  }

  return <WhitelabelContext.Provider value={whitelabelContextValue}>{children}</WhitelabelContext.Provider>
}
