import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface KycDetailedProceedButtonTitleProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const KycDetailedProceedButtonTitle = ({
  className,
  style,
  text,
  textProps,
  ...rest
}: KycDetailedProceedButtonTitleProps) => {
  const { t } = useTranslation()
  return (
    <p className={classNames("m-0 fs-4", className)} {...rest} style={{ ...style, fontWeight: "700" }}>
      {t(text, textProps)}
    </p>
  )
}

export default KycDetailedProceedButtonTitle
