import useEmailAuth from "contexts/auth/hooks/useEmailAuth"
import { createContext } from "react"

type CustomEmailAuthHook = ReturnType<typeof useEmailAuth>

export type EmailAuthProviderValues = {} & CustomEmailAuthHook["emailForm"] & Omit<CustomEmailAuthHook, "emailForm">

const EmailAuthContext = createContext({} as EmailAuthProviderValues)

export default EmailAuthContext
