import { manifestHandler } from "utility/manifestHandler"
import * as serviceWorkerRegistration from "../../../serviceWorkerRegistration"
type TManifest = {
  short_name: string
  name: string
  icons: TIcon[]
  start_url: string
  display: string
  theme_color: string
  background_color: string
  description: string
  lang: string
  related_applications: TRelatedApplication[]
}

type TIcon = {
  src: string
  sizes: string
  type: string
  purpose?: string
}

type TRelatedApplication = {
  platform: string
  url: string
}

export const changeManifestData = (customManifest: null | TManifest) => {
  let manifest: TManifest = {
    short_name: "goTokens",
    name: "goTokens",
    description: "Plataforma de compra, resgate e visualizção de NFTs.",
    lang: "pt-BR",
    icons: [
      {
        src: "https://goblockchain.io/wp-content/uploads/2022/12/logo_goBlock.png.webp",
        sizes: "64x64 32x32 24x24 16x16",
        type: "image/x-icon"
      },
      {
        src: "https://goblockchain.io/wp-content/uploads/2022/12/logo_goBlock.png.webp",
        type: "image/png",
        sizes: "192x192",
        purpose: "any maskable"
      },
      {
        src: "https://goblockchain.io/wp-content/uploads/2022/12/logo_goBlock.png.webp",
        type: "image/png",
        sizes: "512x512"
      }
    ],
    start_url: "https://app.gotokens.io",
    display: "standalone",
    theme_color: "#000000",
    background_color: "#ffffff",
    related_applications: [
      {
        platform: "webapp",
        url: "https://app.gotokens.io/manifest.json"
      }
    ]
  }
  if (customManifest) {
    manifest = customManifest
  }
  const manifestURl = manifestHandler(manifest)
  document.querySelector("#manifest-placeholder").setAttribute("href", manifestURl)
  // Precisamos mudar para fetch first, antes de usar o cache
  // serviceWorkerRegistration.register()
  serviceWorkerRegistration.unregister()
}
