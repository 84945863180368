interface Route {
  path: string
  element: JSX.Element
  meta?: {
    layout: string
  }
}

export const useAddLayoutToRoute = (routes: Route[]) => {
  const Routes = routes.map((route) => {
    route.meta = { ...route?.meta, layout: "vertical" }
    return route
  })

  return Routes
}
