import { CreateLogger, CreateLoggerReturnProps } from "utility/hooks/logger/useLogger"

interface MarketPlaceAuthAfterLoginEffectCallbackProps {
  createLogger: CreateLogger
  isUserLoggedIn: boolean
  handleAuthLogin: (logger: CreateLoggerReturnProps) => void
}

const afterMarketPlaceAuthLogin = ({
  createLogger,
  isUserLoggedIn,
  handleAuthLogin: handleAuthLoginCallback
}: MarketPlaceAuthAfterLoginEffectCallbackProps) => {
  const logger = createLogger("MarketPlaceAuthProvider => After Login Effect")
  if (isUserLoggedIn) {
    logger.add("- User is logged in")
    logger.add("- Handling login callback")
    handleAuthLoginCallback(logger)
  }
}

export default afterMarketPlaceAuthLogin
