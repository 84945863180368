import classNames from "classnames"
import { useTranslation } from "react-i18next"

interface We3IdleDescriptionProps {
  text: string
  textOptions?: { [key: string]: string }
  className?: string
}

const Web3IdleDescription = ({ text, textOptions, className }: We3IdleDescriptionProps) => {
  const { t } = useTranslation()

  return (
    <>
      <p className={classNames("m-0", className)} style={{ fontSize: "16px" }}>
        {t(text, textOptions)}
      </p>
    </>
  )
}

export default Web3IdleDescription
