import React from "react"
import { cookies } from "utility/cookies"
import { useDiscoverDevice, isRunningStandalone } from "./useDiscoverDevice"
import toast from "react-hot-toast"
import { BsPlusSquare, BsThreeDotsVertical } from "react-icons/bs"
import { RxShare2 } from "react-icons/rx"
import { getThreeHoursFromNow } from "utility/DateTimeUtils"
import { AiOutlineClose } from "react-icons/ai"
import useWebStoreWhitelabel from "utility/useWebstoreWhitelabel"
import { useTranslation } from "react-i18next"
function usePWASign() {
  const { browser, device } = useDiscoverDevice()
  const { t: translate } = useTranslation()
  const {
    body: { bodyCardBackgroundColor, bodyCardTextColor, bodyBackgroundColor, bodyTextColor }
  } = useWebStoreWhitelabel()
  const handleAlert = () => {
    const shownInTheLastDay = cookies.get("wasShownInTheLastDay")
    if (shownInTheLastDay !== "true") {
      const timer = setTimeout(() => {
        handlePWASign()
      }, 2500)
      const threeHours = getThreeHoursFromNow()
      cookies.set("wasShownInTheLastDay", "true", { expires: new Date(threeHours) })
      return () => clearTimeout(timer)
    }
  }
  const handlePWASign = () => {
    if (browser === "Safari" && device === "iPhone" && !isRunningStandalone()) {
      toast((t) => ToastIOS(t), {
        duration: 30000,
        style: {
          backgroundColor: bodyCardBackgroundColor || bodyBackgroundColor,
          color: bodyCardTextColor || bodyTextColor
        },
        position: "bottom-right"
      })
    }
    // if (browser === "Chrome" && device === "Android") {
    //   toast((t) => ToastAndroid())
    // }
  }

  const ToastIOS = (t) => {
    return (
      <div
        className="d-flex flex-column gap-1 justify-content-start w-100"
        style={{ position: "relative", backgroundColor: bodyCardBackgroundColor }}
      >
        <div
          style={{
            position: "absolute",
            right: -10,
            top: -3,
            backgroundColor: bodyCardBackgroundColor,
            cursor: "pointer"
          }}
          onClick={() => toast.dismiss(t.id)}
        >
          <AiOutlineClose color={bodyCardTextColor} />
        </div>
        <p style={{ margin: 0, color: bodyCardTextColor }}>{translate("Install_On_Home_Page")}</p>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p style={{ margin: 0, color: bodyCardTextColor }}>
            {translate("Click_On_Button")} <b>{translate("Share_Button")}</b>
          </p>
          <RxShare2 size={20} color={bodyCardTextColor} />
        </div>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p style={{ margin: 0, color: bodyCardTextColor }}>
            {translate("Then")}, <b>{translate("Add_To_Home_Page")}</b>
          </p>
          <BsPlusSquare size={18} style={{ marginLeft: "20px" }} color={bodyCardTextColor} />
        </div>
      </div>
    )
  }

  const ToastAndroid = (t) => {
    return (
      <div className="d-flex flex-column gap-1 align-items-start w-100" style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            right: -10,
            top: -3,
            backgroundColor: bodyCardBackgroundColor,
            cursor: "pointer"
          }}
          onClick={() => toast.dismiss(t.id)}
        >
          <AiOutlineClose color={bodyCardTextColor} />
        </div>
        <p style={{ margin: 0, color: bodyCardTextColor }}>{translate("Install_On_Home_Page")}</p>
        <div className="d-flex justify-content-between align-items-center w-100">
          <p style={{ margin: 0, color: bodyCardTextColor }}>
            {translate("Click_On_Button_Of")} <b>{translate("Information_Button")}</b>
          </p>
          <BsThreeDotsVertical color={bodyCardTextColor} />
        </div>
        <div className="d-flex justify-content-between align-items- w-100">
          <p style={{ margin: 0, color: bodyCardTextColor }}>
            {translate("Then")}, <b>{translate("Add_To_Home_Page")}</b>
          </p>
          <BsPlusSquare color={bodyCardTextColor} />
        </div>
      </div>
    )
  }

  return [handleAlert]
}

export default usePWASign
