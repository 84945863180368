import DisplaySource, { DisplaySourceProps } from "components/displaySource"
import LoginDescription, { LoginDescriptionProps } from "../../components/typography/description"
import LoginTitle, { LoginTitleProps } from "../../components/typography/title"

interface TitleProps extends Pick<LoginTitleProps, "textProps" | "text"> {}
interface DescriptionProps extends Pick<LoginDescriptionProps, "textProps" | "text"> {}

interface KycHeaderLayoutProps extends Omit<DisplaySourceProps, "centralized" | "contained"> {
  title: TitleProps["text"]
  titleProps?: TitleProps["textProps"]
  description: DescriptionProps["text"]
  descriptionProps?: DescriptionProps["textProps"]
}

const KycHeaderLayout = ({
  title,
  titleProps,
  description,
  descriptionProps,
  ...sourceProps
}: KycHeaderLayoutProps) => {
  return (
    <div className="login-wrapper d-flex flex-column align-items-center gap-50 pt-1">
      {/* <DisplaySource size={{ maxWidth: "216px" }} {...sourceProps} centralized /> */}
      <div className="d-flex flex-column align-items-center gap-50">
        <LoginTitle {...{ text: title, textProps: titleProps }} style={{ maxWidth: "274px" }} className="text-center" />
        <LoginDescription {...{ text: description, textProps: descriptionProps }} className="text-center" />
      </div>
    </div>
  )
}

export default KycHeaderLayout
