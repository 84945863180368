import loadingInitialState from "./loadingInitialState"

type StateType = typeof loadingInitialState

const loadingReducers = {
  setPageLoading: (state: StateType, {payload}) => {
    state.isPageLoading = payload
  }
}

export default loadingReducers
