import { lazy } from "react"
import { ChallengeTabsEnum } from "web-weispace/components/tabs/challengeTabs"

const LazyChallenges = lazy(() => import("./pages/challenges-home"))

const mainChallengesPath = "/weispace/challenges"

export const challengesPaths = {
  default: mainChallengesPath,
  home: `${mainChallengesPath}/home`,
  finalized: `${mainChallengesPath}/finalized`,
  participating: `${mainChallengesPath}/participating`
}

const challengesRoutes = [
  {
    path: challengesPaths.home,
    element: <LazyChallenges />
  }
  // {
  //   path: challengesPaths.finalized,
  //   element: <LazyChallenges initialTab={ChallengeTabsEnum.Finalized} />
  // },
  // {
  //   path: challengesPaths.participating,
  //   element: <LazyChallenges initialTab={ChallengeTabsEnum.Participating} />
  // }
]

export default challengesRoutes
