import { TransactionTypeEnum } from "interfaces/sharedEnums"
import { CreatedToken } from "utility/request-interfaces/collection-types"

/**
 * Represents the type of transaction for the blind mint collection.
 * @enum
 */
export enum BlindMintPaymentMethodEnum {
  /**
   * Represents the rescue payment method.
   * Can be used to claim the blind mint NFT with or without a code.
   */
  RESCUE = "rescue",

  /**
   * Represents the purchase payment method.
   * Can be used to purchase the blind mint NFT.
   */
  PURCHASE = "purchase"
}

/**
 * Represents the properties of a purchase bundle in the blind mint collection.
 * @interface
 */
export interface BlindMintBundlePurchaseI {
  /**
   * The price of the bundle in the blind mint collection.
   * @type {string}
   * @public
   */
  price: string

  /**
   * The currency of the price of the bundle in the blind mint collection.
   * @type {string}
   * @public
   */
  currency: string
}

/**
 * Represents the properties of a rescue bundle in the blind mint collection.
 * @interface
 */
export interface BlindMintBundleRescueI {
  /**
   * Indicates whether the blind mint collection is claimable without using a code.
   * @type {boolean}
   * @public
   * @remarks
   * The collection is claimable only if the payment method is set to "rescue".
   */
  isGiveaway?: boolean
}

/**
 * Represents properties of a blind mint bundle acquirer.
 * @interface
 */
export interface BlindMintBundleAcquirersPropertiesI {
  /**
   * The acquirer Id of the bundle
   */
  userId: string
  /**
   * Number of acquisitions made by the user on the bundle
   */
  acquisitions: number
}

/**
 * Bundle is a collection of properties that are used to represent a fake NFT to render like a Sticker Pack.
 * @interface
 */
export interface BlindMintBundlePropertiesI {
  /**
   * The name of the blind mint bundle.
   * @type {string}
   * @public
   */
  name: string

  /**
   * The description of the blind mint bundle.
   * @type {string}
   * @public
   */
  description?: string

  /**
   * The image URL of the blind mint bundle.
   * @type {string}
   * @public
   */
  image?: string

  /**
   * Track the number of acquisitions of each user on bundle in the blind mint collection.
   */
  acquirers?: BlindMintBundleAcquirersPropertiesI[]

  /**
   * The limit of Acquisitions of the bundle in the blind mint collection.
   * @type {number}
   * @public
   */
  acquisitionLimit?: number

  /**
   * The compensation of token for each acquisition of the bundle in the blind mint collection.
   * @type {number}
   * @public
   */
  grantedTokens?: number

  /**
   * The rescue properties of the blind mint bundle.
   * @type {BlindMintBundleRescueI}
   * @public
   */
  rescueAcquisition?: BlindMintBundleRescueI

  /**
   * The purchase properties of the blind mint bundle.
   * @type {BlindMintBundlePurchaseI}
   * @public
   */
  purchaseAcquisition?: BlindMintBundlePurchaseI
}

/**
 * Represents additional data for a blind mint collection.
 * @interface
 */
export interface BlindMintCollectionAdditionalDataI {
  /**
   * The method of payment for the blind mint collection.
   * @type {BlindMintPaymentMethodEnum}
   * @public
   */
  paymentMethod: BlindMintPaymentMethodEnum

  /**
   * The available token types to create and display in the blind mint collection.
   * @type {CreatedToken["transactionType"][]}
   * @public
   * @remarks For the moment only one token type is allowed.
   */
  availableTokensTypes: CreatedToken["transactionType"]

  /**
   * The bundle properties of the blind mint collection.
   * @type {BlindMintBundlePropertiesI}
   * @public
   */
  bundle: BlindMintBundlePropertiesI[]
}

export interface BlindMintCollectionCheckoutData {
  id?: string
  tokenId?: string
  collectionId: string
  type: string
  bundle: Omit<
    BlindMintBundlePropertiesI,
    "rescueAcquisition" | "purchaseAcquisition" | "acquisitionLimit" | "grantedTokens"
  > & { amount: number; inventory: number }
  paymentData: {
    method: BlindMintPaymentMethodEnum
    acquisitionData: BlindMintBundlePurchaseI | (BlindMintBundleRescueI & { voucher?: string })
    acquisitionLimit: BlindMintBundlePropertiesI["acquisitionLimit"]
    grantedTokens: BlindMintBundlePropertiesI["grantedTokens"]
  }
  user: {
    acquisitions: BlindMintBundleAcquirersPropertiesI["acquisitions"]
  }
  banner?: string
}
