export const getFoxbitPayChainId = (chain: string): any => {
  switch (chain) {
    case "eth":
      return "1"
    case "bnb":
      return "56"
    case "matic_polygon":
      return "137"
    default:
      return null
  }
}

export const getFoxbitPayChainName = (chain: string): any => {
  switch (chain) {
    case "eth":
      return "ethereum_mainnet"
    case "bnb":
      return "binance_smart_chain_mainnet"
    case "matic_polygon":
      return "polygon_mainnet"
    case "xrp":
      return "ripple_mainnet"
    case "ltc":
      return "litecoin_mainnet"
    case "btc":
      return "bitcoin_mainnet"
    default:
      return "bitcoin_lightning"
  }
}
