import { isMobile } from "react-device-detect"
import { fetchJWTMetamask } from "services/store/metamask"
import { useAppDispatch } from "redux/hooks"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import useWagmi from "./useWagmi"
import detectWeb3Provider from "./web3/detect"
import { Web3HandlerStatus, Web3HandlerStatusInterface, Web3MainHandlerStatus } from "./web3/interface"
import { AuthModalsEnum } from "../AuthContext"

interface MetamaskAuthProps {
  expectedChain: number
  networkMap: Record<number, string>
  setIsUserLoggedIn: (value: boolean) => void
  setCurrentAuthStageShowing: Dispatch<SetStateAction<AuthModalsEnum>>
  wagmiConfigs: ReturnType<typeof useWagmi>
}

const useMetamaskAuth = ({
  expectedChain,
  networkMap,
  setIsUserLoggedIn,
  setCurrentAuthStageShowing,
  wagmiConfigs
}: MetamaskAuthProps) => {
  const dispatch = useAppDispatch()

  const [isMetamaskAuthLoading, setIsMetamaskAuthLoading] = useState(false)
  const {
    account: { address, isConnected },
    network: { chain },
    signMessage: { signMessageAsync },
    disconnect: { disconnect },
    connect: { connectAsync },
    switchNetwork: { switchNetworkAsync }
  } = wagmiConfigs

  const [isUserConnecting, setIsUserConnecting] = useState<boolean>(false)
  const [metamaskAuthStatus, setMetamaskAuthStatus] = useState<Web3HandlerStatus>(Web3MainHandlerStatus.Disconnected)

  // const connectMetamask = async () => {
  //   // setMetamaskAuthStatus(Web3HandlerStatus.Connect_Pending)
  //   // try {
  //   //   const userData = await connectAsync()
  //   //   setMetamaskAuthStatus(Web3HandlerStatus.Connect_Connected)
  //   //   return userData
  //   // } catch (e) {
  //   //   setIsUserConnecting(false)
  //   //   setMetamaskAuthStatus(Web3HandlerStatus.Connect_Dismissed)
  //   // }
  // }

  const switchMetamaskNetwork = async () => {
    // setMetamaskAuthStatus(Web3HandlerStatus.Switch_Pending)
    // setIsMetamaskAuthLoading(true)
    // try {
    //   const data = await switchNetworkAsync()
    //   setMetamaskAuthStatus(Web3HandlerStatus.Switch_Connected)
    //   return data
    //   // await signMetamaskMessage({ userAddress: address, userChainId: chain?.id })
    // } catch (e) {
    //   setIsUserConnecting(false)
    //   setMetamaskAuthStatus(Web3HandlerStatus.Switch_Dismissed)
    // }
  }

  const signMetamaskMessage = async ({ userAddress, userChainId }) => {
    // setMetamaskAuthStatus(Web3HandlerStatus.Sign_Pending)
    // try {
    //   const messageToSign = "Assine essa mensagem para garantirmos que você é o dono da carteira"
    //   const signedMessage = await signMessageAsync({ message: messageToSign })
    //   setMetamaskAuthStatus(Web3HandlerStatus.Sign_Connected)
      // dispatch(
      //   fetchJWTMetamask({
      //     address: address || userAddress,
      //     blockchain: "polygon",
      //     message: messageToSign,
      //     network: networkMap[chain?.id || userChainId],
      //     signature: signedMessage
      //   })
      // )
    //   setMetamaskAuthStatus(Web3HandlerStatus.Connected)
    //   setIsUserLoggedIn(true)
    // } catch (e) {
    //   console.log(e?.code)
    //   if (e?.code === 4902) {
    //     console.log("Error")
    //   }
    //   setIsUserConnecting(false)
    //   setMetamaskAuthStatus(Web3HandlerStatus.Sign_Dismissed)
    // }
  }

  const cancelMetamaskLogin = () => {
    // setIsMetamaskAuthLoading(false)
    // setIsUserConnecting(false)
    // setMetamaskAuthStatus(Web3HandlerStatus.Disconnect)
    // disconnect()
  }

  const cancelMetamaskAuth = () => {
    setIsMetamaskAuthLoading(false)
    setIsUserConnecting(false)
    // setMetamaskAuthStatus(Web3HandlerStatus.Disconnect)
    disconnect()
  }

  const resetMetamaskAuth = () => {
    setIsMetamaskAuthLoading(false)
    setIsUserConnecting(false)
    // setMetamaskAuthStatus(Web3HandlerStatus.Disconnect)
    disconnect()
  }

  const cancelMetamaskAuthRequest = (handlerStatus: Web3HandlerStatus) => {
    setIsMetamaskAuthLoading(false)
    setIsUserConnecting(false)
    setMetamaskAuthStatus(handlerStatus)
  }

  const handleMetamaskLogin = async (retryFrom?: "connect" | "switch" | "sign") => {
    setIsUserLoggedIn(false)
    setIsMetamaskAuthLoading(true)

    const handlerProps: Web3HandlerStatusInterface = {
      changeWeb3HandlerStatus: setMetamaskAuthStatus,
      web3Provider: "metamask"
    }

    const { stopPropagation: stopInDetect } = detectWeb3Provider(handlerProps)

    if (stopInDetect) {
      setCurrentAuthStageShowing(AuthModalsEnum.WEB3_UNAVAILABLE)
      return cancelMetamaskAuthRequest(Web3MainHandlerStatus.Unavailable)
    } else {
      setCurrentAuthStageShowing(AuthModalsEnum.WEB3_LOGIN)
    }

    console.log("Success On Detect")

    if (isMobile && !window.ethereum) {
      const location = window.location
      return window.open(`https://metamask.app.link/dapp/${location?.hostname}${location?.pathname}${location?.search}`)
    }

    // const { stopPropagation: stopInConnect } = await connectMetamask()
    // if (stopInConnect) {
    //   return cancelMetamaskAuthRequest(Web3MainHandlerStatus.Disconnected)
    // }

    // const retryFromConnect = retryFrom === "connect"
    // let user
    // if (retryFromConnect || (!retryFrom && !chain)) {
    //   user = await connectMetamask()
    // }

    // const retryFromSwitch = retryFrom === "switch"
    // let switchResponse
    // if ((retryFromSwitch && switchNetworkAsync) || (!retryFrom && switchNetworkAsync)) {
    //   console.log("Switching network: inside")
    //   switchResponse = await switchMetamaskNetwork()
    // } else {
    //   setIsUserConnecting(true)
    //   return
    // }

    // const chainId = retryFromSwitch ? switchResponse?.id : user?.chain?.id || chain?.id
    // if (chainId === expectedChain) {
    //   const userChainId = chainId
    //   const userAddress = user?.account || address
    //   await signMetamaskMessage({ userAddress, userChainId })
    // }

    setIsUserConnecting(false)
  }

  const metamaskAuthRequest = {
    disconnect: cancelMetamaskLogin,
    switchNetwork: switchMetamaskNetwork,
    signMessage: signMetamaskMessage,
    connect: null,
    handleMetamaskLogin
  }

  useEffect(() => {
    if (chain && expectedChain && isUserConnecting) {
      handleMetamaskLogin("switch")
    }
  }, [chain, expectedChain, isUserConnecting])

  useEffect(() => {
    if (isConnected) {
      setIsUserConnecting(false)
    }
  }, [isConnected])

  // useEffect(() => {
  //   if (selectedWeb3AuthMethod === Web3AuthMethodsEnum.METAMASK) {
  //     handleMetamaskLogin()
  //   }
  // }, [selectedWeb3AuthMethod])

  return {
    metamaskAuthStatus,
    metamaskAuthRequest,
    resetMetamaskAuth,
    isMetamaskAuthLoading,
    setIsMetamaskAuthLoading
  }
}

export default useMetamaskAuth
