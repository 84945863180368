import { FormHookProps, useForm } from "utility/hooks/useForm"
import FormContext, { FormContextI } from "./formContext"
import { Context, useState } from "react"

interface FormProviderProps<t, r> extends FormHookProps<t, r> {
  children: React.ReactNode
}

export interface FormStepProviderProps<t, r = Object, x = Object>
  extends Pick<FormProviderProps<t, r>, "children" | "additionalData" | "watchChanges" | "initialState"> {
  handleSubmit: ({ formData }: { formData: x }) => Promise<void>
}

/**
 * This component provide the form hook and the form values to all children
 */
function FormProvider<t = any, r = any>({ children, ...formHookProps }: FormProviderProps<t, r>) {
  const { Provider } = FormContext as Context<FormContextI<t, r>>

  const formMethods = useForm<t, r>({ ...formHookProps })

  const providerValues: FormContextI<t, r> = { ...formMethods }

  return <Provider value={providerValues}>{children}</Provider>
}

export default FormProvider
