import DisplaySource, { DisplaySourceProps } from "components/displaySource"

interface WeispaceLoginBannerImageProps extends DisplaySourceProps {
  src: string
}

const WeispaceLoginBannerImage = ({ src, ...rest }: WeispaceLoginBannerImageProps) => {
  return (
    <>
      <DisplaySource src={src} {...rest} />
    </>
  )
}

export default WeispaceLoginBannerImage
