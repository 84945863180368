import { useAppDispatch } from "redux/hooks"
import { MemberPermissions, checkIfUserIsAMember } from "services/store"
import { CreateLogger } from "utility/hooks/logger/useLogger"

interface MarketPlaceKycGuardDefaultEffectProps {
  createLogger: CreateLogger
  isUserLoggedIn: boolean
  memberPermissions: MemberPermissions
  dispatch: ReturnType<typeof useAppDispatch>
  isCollectionParams: boolean
  isTokenParams: boolean
  checkData: {
    accessToken: string
    slug: string
  }
}

const defaultMarketPlaceKycGuardEffect = ({
  createLogger,
  isUserLoggedIn,
  memberPermissions,
  dispatch,
  isCollectionParams,
  isTokenParams,
  checkData
}: MarketPlaceKycGuardDefaultEffectProps) => {
  const logger = createLogger("MarketPlaceKycGuardProvider => Default Effect")

  if (isUserLoggedIn && Object.keys(memberPermissions).length === 0) {
    if (isCollectionParams) {
      dispatch(checkIfUserIsAMember({ ...checkData, origin: "collection" }))
    } else if (isTokenParams) {
      dispatch(checkIfUserIsAMember({ ...checkData, origin: "token" }))
    }
  }

  // Nada
  // if(isUserLoggedIn) {
  // logger.add("Usuário está logado")
  // logger.add("- Verificando se KYC é requerido")
  //
  //}

  // if (isCompanyKycRequired) {
  //   logger.add("- Company pode precisar de KYC")
  //   logger.add("-- Configurando KYC como potencialmente obrigatório")
  //   //dispatch(setIsKycCanBeRequired(true))
  // } else {
  //   logger.add("- Kyc não é requirido")
  //   //dispatch(setIsKycCanBeRequired(false))
  // }

  return logger.read()
}

export default defaultMarketPlaceKycGuardEffect
