import { createSlice } from "@reduxjs/toolkit"
import { fetchCollections } from "./collectionsActions"

const initialCollectionsState = {
  collections: [],
  collectionsLoading: true,
  collectionsError: undefined
}

const collectionsSlice = createSlice({
  name: "collections",
  initialState: initialCollectionsState,
  reducers: {
    setCollections: (state, { payload }) => {
      state.collections = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCollections.pending, (state) => {
      state.collectionsLoading = true
    })
    builder.addCase(fetchCollections.fulfilled, (state, { payload }) => {
      state.collections = payload
      state.collectionsLoading = false
    })
    builder.addCase(fetchCollections.rejected, (state, { error }) => {
      state.collectionsError = error
      state.collectionsLoading = false
    })
  }
})

export default collectionsSlice.reducer
