import { Dispatch, SetStateAction, createContext } from "react"

export interface CustomDomainDefaultRouteState {
  defaultRoute: string
  setDefaultRoute: Dispatch<SetStateAction<string>>
}
export interface CustomDomainDefaultMarketPlaceLinkState {
  defaultMarketPlaceLink: string
  setDefaultMarketPlaceLink: Dispatch<SetStateAction<string>>
}
export interface CustomDomainHasPWAForDownloadState {
  hasPWAForDownload: boolean
  setHasPWAForDownload: Dispatch<SetStateAction<boolean>>
}

export interface CustomDomainShouldFetchCustomDomainState {
  shouldFetchCustomDomain: boolean
  setShouldFetchCustomDomain: Dispatch<SetStateAction<boolean>>
}

export interface CustomDomainContextI
  extends CustomDomainDefaultRouteState,
    CustomDomainDefaultMarketPlaceLinkState,
    CustomDomainHasPWAForDownloadState,
    CustomDomainShouldFetchCustomDomainState {}

const CustomDomainContext = createContext({} as CustomDomainContextI)

export default CustomDomainContext
