import { useState, useEffect } from "react"

export interface BreakpointProps {
  direction: "up" | "down"
  breakpoint: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl" | number
}

const useBreakpoint = ({ direction, breakpoint }: BreakpointProps) => {
  const [matches, setMatches] = useState(false)

  const breakpointsHash = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1440,
    xxxl: 1700
  }

  const directionHash = {
    up: "min-width",
    down: "max-width"
  }

  useEffect(() => {
    let width = breakpointsHash[breakpoint]
    if (!width) {
      width = breakpoint
    }

    const mediaQueryString = `(${directionHash[direction]}: ${width}px)`

    const mediaQuery = window.matchMedia(mediaQueryString)

    const handleChange = (event) => {
      setMatches(event.matches)
    }

    mediaQuery.addEventListener("change", handleChange)
    setMatches(mediaQuery.matches)

    return () => {
      mediaQuery.removeEventListener("change", handleChange)
    }
  }, [])

  return matches
}

export default useBreakpoint
