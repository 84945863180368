import { NetworkEnum } from "interfaces/sharedEnums"
import { UserKyc } from "interfaces/user"
import { axiosBase, getAxiosConfig } from "services/axiosBase"

interface AccessToken {
  accessToken: string
}

interface UserKycData {
  kyc: UserKyc
}

const patchChangeUserKyc = async ({ accessToken, kyc }: AccessToken & UserKycData) => {
  const config = getAxiosConfig(accessToken)
  const { data } = await axiosBase.patch(`users/change-kyc`, kyc, config)

  return data
}

const getUserPublicDataById = async (id: string) => {
  const { data } = await axiosBase.get(`users/${id}/public`)

  return data
}

const getDefaultWalletBalance = async ({ accessToken, network }: { accessToken: string; network?: NetworkEnum }) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }
  let route = "/users/default-wallet/balance"
  if (network) {
    route += `?network=${network}`
  }

  return await axiosBase.get(route, config)
}

export { patchChangeUserKyc, getUserPublicDataById, getDefaultWalletBalance }
