import { useEffect, useMemo, useState } from "react"
import useMarketPlaceKycGuardContext from "../kyc/useMarketPlaceKycGuardContext"

import useLogger from "utility/hooks/logger/useLogger"
import AuthGuardPage from "web-marketplace/contexts/authGuard/guard"
import usePrivateMarketPlaceGuardContext from "../privateMarketplace/usePrivateMarketPlaceGuardContext"
import useMarketPlaceLoginGuardContext from "../login/useMarketPlaceLoginGuardContext"
import toggleMarketPlaceAuthGuardGateLock from "./effectsCallbacks/toggleGateLock"
import MarketPlaceAuthGuardGateContext from "./marketPlaceAuthGuardGateContext"
import useAuthContext from "contexts/auth/AuthInContext"
import { useAppSelector } from "redux/hooks"

interface MarketPlaceAuthGuardGateProps {
  children: React.ReactNode
}

const MarketPlaceAuthGuardGateProvider = ({ children }: MarketPlaceAuthGuardGateProps) => {
  const { Provider } = MarketPlaceAuthGuardGateContext
  const { createLogger } = useLogger(true)

  const { setCurrentAuthStageShowing, isUserLoggedIn } = useAuthContext()
  const { memberPermissions } = useAppSelector((state) => state.auth)
  const { isMarketPlaceLoginGuardShieldEnabled } = useMarketPlaceLoginGuardContext() || {}
  const { isMarketPlaceKycGuardShieldEnabled } = useMarketPlaceKycGuardContext() || {}
  const { isPrivateMarketPlaceGuardShieldEnabled } = usePrivateMarketPlaceGuardContext() || {}

  const isShieldsEnabled =
    isMarketPlaceLoginGuardShieldEnabled || isPrivateMarketPlaceGuardShieldEnabled || isMarketPlaceKycGuardShieldEnabled
  const [isGateLocked, setIsGateLocked] = useState<boolean>(isShieldsEnabled)

  useEffect(
    () =>
      toggleMarketPlaceAuthGuardGateLock({
        createLogger,
        isGateLocked,
        authShields: {
          isPrivateMarketPlaceGuardShieldEnabled,
          isMarketPlaceKycGuardShieldEnabled,
          isMarketPlaceLoginGuardShieldEnabled
        },
        setIsGateLocked,
        setCurrentAuthStageShowing,
        memberPermissions
      }),
    [isShieldsEnabled]
  )

  const values = {
    isGateLocked
  }

  const isLoading =
    (isGateLocked || isMarketPlaceKycGuardShieldEnabled) &&
    isUserLoggedIn &&
    (Object.keys(memberPermissions).length === 0 || memberPermissions?.accessStatus === "approved")
  const providerValues = useMemo(() => values, [isGateLocked])

  return (
    <Provider value={providerValues}>
      {isGateLocked && <AuthGuardPage isLoading={isLoading} />}
      {!isGateLocked && children}
    </Provider>
  )
}

export default MarketPlaceAuthGuardGateProvider
