import { ButtonProps, Spinner } from "reactstrap"
import AuthButtonRoot from "./root"

interface ButtonHandlerProps extends ButtonProps {
  handleClickAction?: Function
  text: string
  isLoading?: boolean
  disabled?: boolean
  companyId?: string
}

const ButtonHandler = ({
  handleClickAction,
  companyId,
  text,
  isLoading = false,
  disabled = false,
  ...rest
}: ButtonHandlerProps) => {
  const { Container, Text } = AuthButtonRoot
  const galoButtonCondition = companyId === "88bf0223-8d5a-41bd-9ed2-4ec36eb9ace4" || companyId === "b82050d1-f35d-4cb8-adba-320b495875a7"

  return (
    <Container
      handleClickAction={handleClickAction}
      className={galoButtonCondition ? "p-75 px-2 rounded-3 button-GNV" : "p-75 px-2 rounded-3"}
      isLoading={isLoading}
      disabled={galoButtonCondition ? false : disabled}
      {...rest}
    >
      {!isLoading && <Text text={text} />}
      {isLoading && (
        <Spinner
          style={{
            width: "1.5rem",
            height: "1.5rem"
          }}
        />
      )}
    </Container>
  )
}

export default ButtonHandler
