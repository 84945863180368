import classNames from "classnames"

interface Web3CardContentWrapperProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const Web3CardContentWrapper = ({ children, className, style, ...rest }: Web3CardContentWrapperProps) => {
  return (
    <div className={classNames("d-flex align-items-center gap-1", className)} style={style} {...rest}>
      {children}
    </div>
  )
}

export default Web3CardContentWrapper
