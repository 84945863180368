import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo, useState } from "react"

type CustomDomainContextType = {
  defaultRoute: string
  setDefaultRoute: Dispatch<SetStateAction<string>>
  defaultMarketPlaceLink: string
  setDefaultMarketPlaceLink: Dispatch<SetStateAction<string>>
  hasPWAForDownload: boolean,
  setHasPWAForDownload: Dispatch<SetStateAction<boolean>>
}

export const CustomDomain = createContext({} as CustomDomainContextType)

export const CustomDomainContext = ({ children }: { children: ReactNode }) => {
  const [defaultRoute, setDefaultRoute] = useState("/token")
  const [defaultMarketPlaceLink, setDefaultMarketPlaceLink] = useState("")
  const [hasPWAForDownload, setHasPWAForDownload] = useState(false)

  const value = useMemo(
    () => ({
      defaultRoute,
      setDefaultRoute,
      defaultMarketPlaceLink,
      setDefaultMarketPlaceLink,
      hasPWAForDownload,
      setHasPWAForDownload
    }),
    [defaultRoute, setDefaultRoute, defaultMarketPlaceLink, setDefaultMarketPlaceLink, hasPWAForDownload, setHasPWAForDownload]
  )

  return <CustomDomain.Provider value={value}>{children}</CustomDomain.Provider>
}

export function useCustomDomainContext() {
  return useContext(CustomDomain)
}
