import { createAsyncThunk } from "@reduxjs/toolkit"
import { getBlindMintCollection } from "services/collectionsRequest"

export const getCollectionBlindMint = createAsyncThunk(
  "collection/blindMint",
  async ({ slug, accessToken }: { slug: string; accessToken: string }) => {
    try {
      const { data } = await getBlindMintCollection(slug, accessToken)
      return data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)
