import useBuildParamsUrl from "utility/useBuildParamsUrl"
import { axiosBase } from "./axiosBase"

export const benefitValidatorLogin = async (data: { email: string; password: string }, companyId: string) => {
  return await axiosBase.post(`/auth/benefit-validator/company/${companyId}`, data)
}

export const validateUserEmailAndSendCode = async (data: { email: string }, network: string, company: string) => {
  const url = `/auth/email`
  const routeUrl = useBuildParamsUrl({
    url,
    params: {
      network,
      company: company || process.env.REACT_APP_DEFAULT_COMPANY_ID
    }
  })
  return await axiosBase.post(routeUrl, data)
}

export const validateUserCode = async (data: { email: string; code: string }) => {
  return await axiosBase.post(`/auth/email/confirm`, data)
}
