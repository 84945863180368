import { createSlice } from "@reduxjs/toolkit"
import { handleTransferTransactionBetweenMembers } from "./reducer"

const initialTransactionState = {
  transferBetweenMembers: {} as { id: string; gasLimit: string },
  isTransferBetweenMembersLoading: false,
  transferBetweenMembersError: null
}

const transactionSlice = createSlice({
  name: "transaction",
  initialState: initialTransactionState,
  reducers: {
    setTransferBetweenMembers: (state, { payload }) => {
      state.transferBetweenMembers = payload
    },
    resetTransferBetweenMembers: (state) => {
      state.transferBetweenMembers = initialTransactionState.transferBetweenMembers
      state.isTransferBetweenMembersLoading = initialTransactionState.isTransferBetweenMembersLoading
      state.transferBetweenMembersError = initialTransactionState.transferBetweenMembersError
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleTransferTransactionBetweenMembers.pending, (state, _action) => {
        state.isTransferBetweenMembersLoading = true
      })
      .addCase(handleTransferTransactionBetweenMembers.fulfilled, (state, { payload }) => {
        state.isTransferBetweenMembersLoading = false
        state.transferBetweenMembers = payload
      })
      .addCase(handleTransferTransactionBetweenMembers.rejected, (state, { error }) => {
        state.isTransferBetweenMembersLoading = false
        state.transferBetweenMembersError = error
      })
  }
})

export default transactionSlice.reducer
export const { setTransferBetweenMembers, resetTransferBetweenMembers } = transactionSlice.actions
