import { createContext, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { toast, ToastPosition } from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { removeSession } from "web-weispace/services/store"
import { removeSessionMetamask } from "web-weispace/services/store/metamask"
import { cookies } from "../cookies"
import { useAppDispatch } from "redux/hooks"
import { defaultWeispaceRoute, weispaceRoutes } from "web-weispace/views"
import { useGlobalContext } from "../hooks/useContext"

const Context = createContext(null)

export const CookieExpiracyProvider = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const dataCookies = cookies.get("userData")
  const data = !!dataCookies ? JSON.parse(dataCookies) : null
  const navigate = useNavigate()
  const location = useLocation()
  const [didSetWeispaceRedirectUrl, setDidSetWeispaceRedirectUrl] = useState(false)
  const { setWeispaceRedirectUrl } = useGlobalContext()

  const weispaceLoginRoute = `${defaultWeispaceRoute}/login`

  const { from } = location.state || { from: { pathname: location?.pathname, search: location.search } }

  const showTokenExpiredToast = () => {
    toast.error(t("Token Expired Toast"), {
      position: process.env.REACT_APP_TOAST_POSITION as ToastPosition,
      duration: Number(process.env.REACT_APP_TOAST_DURATION),
      id: "token-expired-toast"
    })
  }

  useEffect(() => {
    const isLoginAfterLogout = from?.pathname === weispaceLoginRoute && from?.search === ""
    if (!didSetWeispaceRedirectUrl && !isLoginAfterLogout) {
      const { pathname, search: queryParams } = from || { pathname: defaultWeispaceRoute, search: "" }
      setWeispaceRedirectUrl(`${pathname}${queryParams}`)
      setDidSetWeispaceRedirectUrl(true)

      const urlParams = new URLSearchParams(from?.search)
      if (urlParams.has("redirectEndpoint")) {
        const redirectEndpoint = urlParams.get("redirectEndpoint")
        cookies.set("redirectEndpoint", redirectEndpoint)
      }
    }
  }, [from])

  const isPublicRoute = () => {
    if (weispaceRoutes) {
      return weispaceRoutes.find((route) => {
        const routePath = route.path
        if (routePath === location.pathname) {
          return route.isPublic || false
        }
      })
    }
    return false
  }

  useEffect(() => {
    if (data) {
      if (data.expires && new Date().toISOString() > data.expires) {
        dispatch(removeSession({}))
        dispatch(removeSessionMetamask({}))
        if (!isPublicRoute()) {
          navigate(weispaceLoginRoute)
          showTokenExpiredToast()
        }
      }
    } else {
      if (location.pathname !== weispaceLoginRoute) {
        if (!isPublicRoute()) {
          navigate(weispaceLoginRoute)
          showTokenExpiredToast()
        }
      }
    }
  }, [data, from])

  return <Context.Provider value={null}>{children}</Context.Provider>
}
