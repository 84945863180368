import { CreateLogger } from "utility/hooks/logger/useLogger"

interface MarketPlaceCustomDomainPWAAlertEffectProps {
  createLogger: CreateLogger
  hasPWAForDownload: boolean
  whiteLabelIsLoading: boolean
  handlePWAAlert: () => void
}

/**
 * Mostra o alerta de PWA
 * @param createLogger - Função para criar um logger
 * @param hasPWAForDownload - Se o PWA está disponível para download
 * @param whiteLabelIsLoading - Se o whiteLabel está carregando
 * @param handlePWAAlert - Função para mostrar o alerta
 */

export const pwaAlertMarketPlaceCustomDomainEffect = ({createLogger, hasPWAForDownload, whiteLabelIsLoading, handlePWAAlert}: MarketPlaceCustomDomainPWAAlertEffectProps) => {
  if (hasPWAForDownload && !whiteLabelIsLoading) {
    const timer = setTimeout(() => {
      handlePWAAlert()
    }, 5000)
    return () => clearTimeout(timer)
  }
}

export default pwaAlertMarketPlaceCustomDomainEffect