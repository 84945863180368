import { axiosBase } from "./axiosBase"
import { AxiosResponse } from "axios"
import { CreatedCollection, CreatedToken } from "utility/request-interfaces/collection-types"
import { CollectionsTokenOffers } from "utility/request-interfaces/token-offer-types"
import { BlindMintCollectionData } from "../web-marketplace/views/collection/pages/blindmint/interfaces"

const getTokensFromCollection = (id: string): Promise<AxiosResponse<CreatedToken[]>> => {
  return axiosBase.get(`/collections/${id}/tokens?limit=10`)
}

const getCollectionPublicData = (id: string): Promise<AxiosResponse<CreatedCollection>> => {
  return axiosBase.get(`/collections/${id}`)
}

const handleGetMyCollectionsById = (id: string) => {
  return axiosBase.get(`/collections/${id}`)
}

const getTokensOffersFromCollection = (id: string): Promise<AxiosResponse<CollectionsTokenOffers>> => {
  return axiosBase.get(`store/collection/${id}`)
}

const getBlindMintCollection = async (id: string, accessToken?: string): Promise<AxiosResponse<BlindMintCollectionData>> => {
  return await axiosBase.get(`/collections/${id}/blind-mint`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

const getBlindMintGiveawayVoucher = (jwtAccessToken: string, id: string) => {
  return axiosBase.get(`/vouchers/collection/${id}/unreveal`, {
    headers: {
      Authorization: `Bearer ${jwtAccessToken}`
    }
  })
}

export {
  getTokensFromCollection,
  getCollectionPublicData,
  handleGetMyCollectionsById,
  getTokensOffersFromCollection,
  getBlindMintCollection,
  getBlindMintGiveawayVoucher
}
