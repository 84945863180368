import { CreatedToken } from "../../../../utility/request-interfaces/collection-types.d"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

import axios from "axios"
import { CreatedCollection } from "../../../../interfaces/collection"
import { CreatedCompany } from "../../../../utility/request-interfaces/company-types"
import { companyStoreCommunit } from "../../../../interfaces/community"
import {
  CreatedCompanyCollections,
  CreatedCompanyTokens
} from "../../../../utility/request-interfaces/token-offer-types"
import { axiosBase } from "services/axiosBase"
import { fetchCollectionTokens, fetchCommunityTokens, fetchCompanyTokens } from "services/store/tokenOffer"

export const fetchCompany = createAsyncThunk("company/fetchCompany", async (id: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/companies/${id}/public`)
  return data
})

export const fetchCompanyTokensOffers = createAsyncThunk(
  "company/tokens/v1",
  async (data: { companyId: string; skip: number; communityId?: string; collectionId?: string }) => {
    const { companyId, skip, communityId, collectionId } = data
    const { data: tokensOffersData } = await axios.get(
      `${process.env.REACT_APP_API_URL}/store/company/${companyId}/tokens?limit=9&skip=${skip}${
        communityId ? `&communityId=${communityId}` : ""
      } ${collectionId ? `&collectionId=${collectionId}` : ""}`
    )
    return tokensOffersData
  }
)

export const fetchCompanyCommunity = createAsyncThunk(
  "community/fetchCompanyCommunity",
  async (payload: { id: string; jwtAccessToken: string }) => {
    const { id, jwtAccessToken } = payload

    if (id) {
      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/company/${id}/community/active`, {
        headers: {
          Authorization: `Bearer ${jwtAccessToken}`
        }
      })
      return data
    }
  }
)

export const fetchCompanyCollection = createAsyncThunk(
  "community/fetchCompanyCollections",
  async (data: { companyId: string; skip: number; communityId?: string; jwtAccessToken?: string }) => {
    const { companyId, skip, communityId, jwtAccessToken } = data
    const { data: collectionsData } = await axios.get(
      `${process.env.REACT_APP_API_URL}/store/company/${companyId}/collection/active?skip=${skip}${
        communityId ? `&communityId=${communityId}` : ""
      }`,
      {
        headers: {
          Authorization: `Bearer ${data.jwtAccessToken}`
        }
      }
    )
    return collectionsData
  }
)

export const fetchToken = createAsyncThunk("token/fetchToken", async (id: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/${id}`)
  return data
})

export const fetchBenefits = createAsyncThunk("token/fetchBenefits", async (id: string) => {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/benefits/token/${id}`)
  return data
})

export const fetchCollection = createAsyncThunk(
  "token/fetchCollection",
  async ({ accessToken, id }: { id: string; accessToken: string }) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/collections/${id}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    return data
  }
)

export const fetchSpecificTokenFromUserId = createAsyncThunk(
  "token/fetchSpecificTokenFromUserId",
  async (payload: any) => {
    const { tokenUuid, jwtAccessToken } = payload
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/owned/${tokenUuid}`, {
      headers: {
        Authorization: `Bearer ${jwtAccessToken}`
      }
    })

    const { owned, quantity, processing, maximumRedemptionLimitReached, userCanRedeemByIntegration, tokenHasIntegration } = data

    return { owned, quantity, processing, maximumRedemptionLimitReached, userCanRedeemByIntegration, tokenHasIntegration }
  }
)

export const fetchBlindMintTokensFromUserIdByCollectionId = createAsyncThunk(
  "token/fetchBlindMintTokensFromUserIdByCollectionId",
  async (payload: any) => {
    const { collectionId, jwtAccessToken } = payload
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/collections/${collectionId}/user-token-count`, {
      headers: {
        Authorization: `Bearer ${jwtAccessToken}`
      }
    })
    if (data?.count === 0) {
      return { owned: false, quantity: 0 }
    }
    return { owned: true, quantity: data?.count }
  }
)

export const fetchMainTokenOfferByTokenId = createAsyncThunk(
  "token/fetchMainTokenOfferByTokenId",
  async (tokenId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/token/${tokenId}`)
    return data
  }
)

export const fetchMarketPlaceTokenOffersByTokenId = createAsyncThunk(
  "token/fetchMarketPlaceTokenOffersByTokenId",
  async (tokenId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/marketplace/token/${tokenId}`)
    return data
  }
)

export const fetchStoreCollection = createAsyncThunk(
  "collectionStore/fetchCollectionStore",
  async (collectionId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/collection/${collectionId}`)
    return data
  }
)

export const fetchCollectionRelatedMainTokenOffers = createAsyncThunk(
  "token/fetchCollectionRelatedMainTokenOffers",
  async (collectionId: string) => {
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/collection/${collectionId}?limit=5`)
    const tokenOffers = data.tokenOffers
    return tokenOffers
  }
)

export const fetchStarredTokenOffers = createAsyncThunk("token/starredTokenOffers", async (communityId: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/store/community/${communityId}/tokens/starred?limit=8`
  )
  return data
})

export const fetchCommunityRelatedMainTokenOffers = createAsyncThunk(
  "token/fetchCommunityRelatedMainTokenOffers",
  async (payload: any) => {
    const { communityId, jwtAccessToken } = payload
    const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/store/community/${communityId}?limit=8`, {
      headers: {
        Authorization: `Bearer ${jwtAccessToken}`
      }
    })
    return data
  }
)
export async function getQrcodeJWT(accessToken: string) {
  const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/tokens/qrcode/jwt`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })

  return data
}
export async function generateBenefitQrcode(request) {
  const { accessToken, tokenId, tokenBenefitId } = request

  const { data } = await axios.post(
    `${process.env.REACT_APP_API_URL}/benefits-usage/qrcode/jwt`,
    { tokenBenefitId, tokenId },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }
  )

  return data
}

// Tentando corrigir fluxo de marketplace company
export const fetchCollectionsByCommunity = createAsyncThunk("collections/byCompany", async (communityId: string) => {
  return (await axiosBase.get(`store/community/${communityId}/collections/active`)).data
})

export const fetchCompanyTokensWithPagination = createAsyncThunk(
  "company/tokens",
  async (params: { companyId: string; limit: number; skip: number }) => await fetchCompanyTokens(params)
)

export const fetchCommunityTokensWithPagination = createAsyncThunk(
  "community/tokens",
  async (params: { communityId: string; limit: number; skip: number }) => await fetchCommunityTokens(params)
)

export const fetchCollectionTokensWithPagination = createAsyncThunk(
  "collection/tokens",
  async (params: { collectionId: string; limit: number; skip: number }) => await fetchCollectionTokens(params)
)

export const fetchCompanyTokensAndAddToList = createAsyncThunk(
  "company/tokens/add",
  async (params: { companyId: string; limit: number; skip: number }) => await fetchCompanyTokens(params)
)

export const fetchCommunityTokensAndAddToList = createAsyncThunk(
  "community/tokens/add",
  async (params: { communityId: string; limit: number; skip: number }) => await fetchCommunityTokens(params)
)

export const fetchCollectionTokensAndAddToList = createAsyncThunk(
  "collection/tokens/add",
  async (params: { collectionId: string; limit: number; skip: number }) => await fetchCollectionTokens(params)
)

const initialCompanyState = {} as CreatedCompany
const initialCommunityState = [] as companyStoreCommunit[]
const initialCollectionState = {} as CreatedCollection
const initialTokenState = {} as CreatedToken
const initialCompanyTokensState = [] as CreatedCompanyTokens[]
const initialCompanyCollectionsState = [] as CreatedCompanyCollections[]

export const tokenSlice = createSlice({
  name: "tokenSlice",
  initialState: {
    activeTokenMenu: "information",

    token: initialTokenState,
    tokenLoading: true,
    tokenErrorMessage: undefined,
    owned: false,
    ownedQuantity: 0,
    tokenProcessing: false,
    maximumRedemptionLimitReached: false,
    tokenHasIntegration: true,
    userCanRedeemByIntegration: false,

    company: initialCompanyState,
    companyLoading: true,
    companyErrorMessage: undefined,

    community: initialCommunityState,
    communityLoading: true,
    communityErrorMessage: undefined,

    collectionRelatedTokenOffers: [],
    collectionStoreLoading: true,
    collectionRelatedTokenOffersErrorMessage: undefined,

    collectionStore: undefined,
    collectionRelatedTokenOffersLoading: true,
    collectionStoreErrorMessage: undefined,

    starredTokenOffers: [],
    starredTokenOffersLoading: true,
    starredTokenOffersErrorMessage: undefined,

    communityRelatedTokenOffers: undefined,
    communityRelatedTokenOffersLoading: true,
    communityRelatedTokenOffersErrorMessage: undefined,

    collection: initialCollectionState,
    collectionLoading: false,
    collectionErrorMessage: undefined,

    benefits: [],
    benefitsLoading: false,
    benefitsErrorMessage: undefined,

    tokenOffersLoading: true,
    tokenOffersErrorMessage: undefined,

    mainTokenOffer: undefined,
    mainTokenOfferLoading: true,
    mainTokenOfferErrorMessage: undefined,

    marketPlaceTokenOffers: [],
    marketPlaceTokenOffersLoading: true,
    marketPlaceTokenOffersErrorMessage: undefined,

    communityTokens: initialCompanyTokensState,
    communityTokensLoading: true,
    communityTokensErrorMessage: undefined,

    companyCollections: initialCompanyCollectionsState,
    companyCollectionsLoading: true,
    companyCollectionsErrorMessage: undefined,

    blindMintCollectionCanBePost: false,
    blindMintCollectionUserTokensLoading: false,
    blindMintCollectionErrorMessage: undefined,

    refetchTokensFromUser: true,

    modalTokenOffers: false,
    moreTokensToLoad: false
  },
  reducers: {
    setActiveTokenMenu: (state, { payload }) => {
      state.activeTokenMenu = payload
      window.sessionStorage.setItem("activeTokenMenu", payload)
    },
    setTokenInventory: (state, { payload }) => {
      state.token.inventory = payload
    },
    setTokenOwned: (state, { payload }) => {
      state.owned = payload
    },
    setTokenPriceInBrl: (state, { payload }) => {
      state.token.priceInBrl = payload
    },
    setModalTokenOffers: (state, { payload }) => {
      state.modalTokenOffers = payload
    },
    setTokenOwnedQuantity: (state, { payload }) => {
      state.ownedQuantity = payload
    },
    setCanBlindMintCollectionBePost: (state, { payload }) => {
      state.blindMintCollectionCanBePost = payload
    },
    setRefetchTokensFromUser: (state, { payload }) => {
      state.refetchTokensFromUser = payload
    },
    clearToken: (state) => {
      state.token = initialTokenState
    },
    setTokenBenefits: (state, { payload }) => {
      state.benefits = payload
    },
    clearMainTokenOffer: (state) => {
      state.mainTokenOffer = undefined
    },
    setUserTokenLoading: (state, { payload }) => {
      state.communityTokensLoading = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompany.pending, (state) => {
        state.companyLoading = true
      })
      .addCase(fetchCompany.fulfilled, (state, { payload }) => {
        state.companyLoading = false
        state.company = payload
      })
      .addCase(fetchCompany.rejected, (state, { payload }) => {
        state.companyLoading = false
        state.companyErrorMessage = payload
      })
      .addCase(fetchCompanyCommunity.pending, (state) => {
        state.communityLoading = true
      })
      .addCase(fetchCompanyCommunity.fulfilled, (state, { payload }) => {
        state.communityLoading = false
        state.community = payload
      })
      .addCase(fetchCompanyCommunity.rejected, (state, { payload }) => {
        state.communityLoading = false
        state.communityErrorMessage = payload
      })
      .addCase(fetchToken.pending, (state) => {
        state.tokenLoading = true
      })
      .addCase(fetchToken.fulfilled, (state, { payload }) => {
        state.tokenLoading = false
        state.token = payload
      })
      .addCase(fetchToken.rejected, (state, { payload }) => {
        state.tokenLoading = false
        state.tokenErrorMessage = payload
      })
      .addCase(fetchSpecificTokenFromUserId.pending, (state) => {
        state.tokenLoading = true
      })
      .addCase(fetchSpecificTokenFromUserId.fulfilled, (state, { payload }: any) => {
        state.tokenLoading = false
        state.owned = payload.owned
        state.tokenProcessing = payload.processing
        state.ownedQuantity = payload.quantity
        state.maximumRedemptionLimitReached = payload.maximumRedemptionLimitReached
        state.tokenHasIntegration = payload.tokenHasIntegration
        state.userCanRedeemByIntegration = payload.userCanRedeemByIntegration
      })
      .addCase(fetchSpecificTokenFromUserId.rejected, (state, { payload }) => {
        state.tokenLoading = false
      })
      .addCase(fetchBlindMintTokensFromUserIdByCollectionId.pending, (state) => {
        state.blindMintCollectionCanBePost = false
        state.blindMintCollectionUserTokensLoading = true
      })
      .addCase(fetchBlindMintTokensFromUserIdByCollectionId.fulfilled, (state, { payload }: any) => {
        state.blindMintCollectionCanBePost = true
        state.blindMintCollectionUserTokensLoading = false
        state.owned = payload.owned
        state.ownedQuantity = payload.quantity
      })
      .addCase(fetchBlindMintTokensFromUserIdByCollectionId.rejected, (state, { payload }) => {
        state.blindMintCollectionCanBePost = false
        state.blindMintCollectionUserTokensLoading = false
      })
      .addCase(fetchCollection.pending, (state) => {
        state.collectionLoading = true
      })
      .addCase(fetchCollection.fulfilled, (state, { payload }) => {
        state.collectionLoading = false
        state.collection = payload
      })
      .addCase(fetchCollection.rejected, (state, { payload }) => {
        state.collectionLoading = false
        state.collectionErrorMessage = payload
      })

      .addCase(fetchBenefits.pending, (state) => {
        state.benefitsLoading = true
      })
      .addCase(fetchBenefits.fulfilled, (state, { payload }) => {
        state.benefitsLoading = false
        state.benefits = payload
      })
      .addCase(fetchBenefits.rejected, (state, { payload }) => {
        state.benefitsLoading = false
        state.benefitsErrorMessage = payload
      })

      .addCase(fetchMainTokenOfferByTokenId.pending, (state) => {
        state.mainTokenOfferLoading = true
      })
      .addCase(fetchMainTokenOfferByTokenId.fulfilled, (state, { payload }) => {
        state.mainTokenOfferLoading = false
        state.mainTokenOffer = payload
      })
      .addCase(fetchMainTokenOfferByTokenId.rejected, (state, { payload }) => {
        state.mainTokenOfferLoading = false
        state.mainTokenOfferErrorMessage = payload
      })

      .addCase(fetchMarketPlaceTokenOffersByTokenId.pending, (state) => {
        state.marketPlaceTokenOffersLoading = true
      })
      .addCase(fetchMarketPlaceTokenOffersByTokenId.fulfilled, (state, { payload }) => {
        state.marketPlaceTokenOffersLoading = false
        state.marketPlaceTokenOffers = payload
      })
      .addCase(fetchMarketPlaceTokenOffersByTokenId.rejected, (state, { payload }) => {
        state.marketPlaceTokenOffersLoading = false
        state.marketPlaceTokenOffersErrorMessage = payload
      })
      .addCase(fetchStoreCollection.pending, (state) => {
        state.collectionStoreLoading = true
      })
      .addCase(fetchStoreCollection.fulfilled, (state, { payload }) => {
        state.collectionStoreLoading = false
        state.collectionStore = payload
      })
      .addCase(fetchStoreCollection.rejected, (state, { payload }) => {
        state.collectionStoreLoading = false
        state.collectionStoreErrorMessage = payload
      })
      .addCase(fetchStarredTokenOffers.pending, (state) => {
        state.starredTokenOffersLoading = true
      })
      .addCase(fetchStarredTokenOffers.fulfilled, (state, { payload }) => {
        state.starredTokenOffersLoading = false
        state.starredTokenOffers = payload
      })
      .addCase(fetchStarredTokenOffers.rejected, (state, { payload }) => {
        state.starredTokenOffersLoading = false
        state.starredTokenOffersErrorMessage = payload
      })
      .addCase(fetchCollectionRelatedMainTokenOffers.pending, (state) => {
        state.collectionRelatedTokenOffersLoading = true
      })
      .addCase(fetchCollectionRelatedMainTokenOffers.fulfilled, (state, { payload }) => {
        state.collectionRelatedTokenOffersLoading = false
        state.collectionRelatedTokenOffers = payload
      })
      .addCase(fetchCollectionRelatedMainTokenOffers.rejected, (state, { payload }) => {
        state.collectionRelatedTokenOffersLoading = false
        state.collectionRelatedTokenOffersErrorMessage = payload
      })
      .addCase(fetchCommunityRelatedMainTokenOffers.pending, (state) => {
        state.communityRelatedTokenOffersLoading = true
      })
      .addCase(fetchCommunityRelatedMainTokenOffers.fulfilled, (state, { payload }) => {
        state.communityRelatedTokenOffersLoading = false
        state.communityRelatedTokenOffers = payload
      })
      .addCase(fetchCommunityRelatedMainTokenOffers.rejected, (state, { payload }) => {
        state.communityRelatedTokenOffersLoading = false
        state.communityRelatedTokenOffersErrorMessage = payload
      })
      .addCase(fetchCompanyTokensOffers.pending, (state) => {
        state.communityTokensLoading = true
      })
      .addCase(fetchCompanyTokensOffers.fulfilled, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokens = payload
      })
      .addCase(fetchCompanyTokensOffers.rejected, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokensErrorMessage = payload
      })
      .addCase(fetchCompanyCollection.pending, (state) => {
        state.companyCollectionsLoading = true
      })
      .addCase(fetchCompanyCollection.fulfilled, (state, { payload }) => {
        state.companyCollectionsLoading = false
        state.companyCollections = payload
      })
      .addCase(fetchCompanyCollection.rejected, (state, { payload }) => {
        state.companyCollectionsLoading = false
        state.companyCollectionsErrorMessage = payload
      })
      .addCase(fetchCompanyTokensWithPagination.pending, (state) => {
        state.communityTokensLoading = true
      })
      .addCase(fetchCompanyTokensWithPagination.fulfilled, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokens = payload?.tokens
        state.moreTokensToLoad = payload?.hasMore
      })
      .addCase(fetchCompanyTokensWithPagination.rejected, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokensErrorMessage = payload
      })
      .addCase(fetchCommunityTokensWithPagination.pending, (state) => {
        state.communityTokensLoading = true
      })
      .addCase(fetchCommunityTokensWithPagination.fulfilled, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokens = payload?.tokens
        state.moreTokensToLoad = payload?.hasMore
      })
      .addCase(fetchCommunityTokensWithPagination.rejected, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokensErrorMessage = payload
      })
      .addCase(fetchCollectionTokensWithPagination.pending, (state) => {
        state.communityTokensLoading = true
      })
      .addCase(fetchCollectionTokensWithPagination.fulfilled, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokens = payload?.tokens
        state.moreTokensToLoad = payload?.hasMore
      })
      .addCase(fetchCollectionTokensWithPagination.rejected, (state, { payload }) => {
        state.communityTokensLoading = false
        state.communityTokensErrorMessage = payload
      })

      .addCase(fetchCompanyTokensAndAddToList.pending, (state) => {})
      .addCase(fetchCompanyTokensAndAddToList.fulfilled, (state, { payload }) => {
        state.communityTokens = [...state.communityTokens, ...payload?.tokens]
        state.moreTokensToLoad = payload?.hasMore
      })
      .addCase(fetchCompanyTokensAndAddToList.rejected, (state, { payload }) => {
        state.communityTokensErrorMessage = payload
      })

      .addCase(fetchCommunityTokensAndAddToList.pending, (state) => {})
      .addCase(fetchCommunityTokensAndAddToList.fulfilled, (state, { payload }) => {
        state.communityTokens = [...state.communityTokens, ...payload?.tokens]
        state.moreTokensToLoad = payload?.hasMore
      })
      .addCase(fetchCommunityTokensAndAddToList.rejected, (state, { payload }) => {
        state.communityTokensErrorMessage = payload
      })

      .addCase(fetchCollectionTokensAndAddToList.fulfilled, (state, { payload }) => {
        state.communityTokens = [...state.communityTokens, ...payload?.tokens]
        state.moreTokensToLoad = payload?.hasMore
      })
      .addCase(fetchCollectionTokensAndAddToList.rejected, (state, { payload }) => {
        state.communityTokensErrorMessage = payload
      })
  }
})

export const {
  setActiveTokenMenu,
  setTokenInventory,
  setTokenOwned,
  setTokenPriceInBrl,
  setModalTokenOffers,
  setTokenOwnedQuantity,
  clearToken,
  clearMainTokenOffer,
  setCanBlindMintCollectionBePost,
  setRefetchTokensFromUser,
  setUserTokenLoading,
  setTokenBenefits
} = tokenSlice.actions

export default tokenSlice.reducer
