import { createAsyncThunk } from "@reduxjs/toolkit"

import { getNFTBenefits } from "../getNFTBenefits"
import { axiosBase } from "../axiosbase"

import {
  CreatedTokenWithOwnedQuantity,
  CreatedTokenWithProcessingFlag,
  FetchIntegrationTokensProps,
  FetchNotEndedAuctionTokensProps,
  FetchTokensProps
} from "./types"

export const fetchTokens = createAsyncThunk<CreatedTokenWithOwnedQuantity[], FetchTokensProps>(
  "tokens/fetchTokens",
  async ({ accessToken, userId }: FetchTokensProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }

    return await axiosBase
      .get<CreatedTokenWithOwnedQuantity[]>(`/tokens/owned/user/${userId}?limit=0`, config)
      .then((response) => response.data)
  }
)

export const fetchProcessingTokens = createAsyncThunk<CreatedTokenWithProcessingFlag[], FetchTokensProps>(
  "tokens/fetchProcessingTokens",
  async ({ accessToken, userId }: FetchTokensProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }

    return await axiosBase
      .get<CreatedTokenWithProcessingFlag[]>(`/tokens/owned/user/${userId}/processing`, config)
      .then((response) => response.data)
  }
)

export const fetchNotEndedAuctionTokens = createAsyncThunk<
  CreatedTokenWithOwnedQuantity[],
  FetchNotEndedAuctionTokensProps
>("tokens/fetchNotEndedAuctionTokens", async ({ accessToken, communityId }: FetchNotEndedAuctionTokensProps) => {
  const config = { headers: { Authorization: `Bearer ${accessToken}` } }

  return await axiosBase
    .get<CreatedTokenWithOwnedQuantity[]>(`/tokens/community/${communityId}/auction-not-ended`, config)
    .then(async (response) => response.data)
})

export const fetchIntegrationTokens = createAsyncThunk<CreatedTokenWithOwnedQuantity[], FetchIntegrationTokensProps>(
  "tokens/fetchIntegrationTokens",
  async ({ accessToken, communityId }: FetchIntegrationTokensProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }

    return await axiosBase
      .get<CreatedTokenWithOwnedQuantity[]>(
        `/integrations/gnv/community/${communityId}/tokens-available-to-redemption`,
        config
      )
      .then(async (response) => {
        const nfts = response.data
        const treatedIntegrationTokens = nfts.map((nft) => {
          return { ...nft, totalUserQuantity: 0, hideUserQnt: true }
        })
        return treatedIntegrationTokens
      })
  }
)
