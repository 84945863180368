import KycProvider from "./context/KycProvider"
import KycLoginStages from "./stages"

const LoginKycStage = () => {

  return (
    <>
      <KycProvider>
        <KycLoginStages />
      </KycProvider>
    </>
  )
}

export default LoginKycStage
