import { useContext } from "react"
import MarketPlaceAuthGuardGateContext from "./marketPlaceAuthGuardGateContext"


const useMarketPlaceAuthGuardGateContext = () => {
  const context = useContext(MarketPlaceAuthGuardGateContext)

  if (!context) {
    throw new Error("useMarketPlaceAuthGuardGateContext must be used within a MarketPlaceAuthGuardGateProvider")
  }

  return context
}


export default useMarketPlaceAuthGuardGateContext