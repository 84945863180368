// ** Redux Imports
import rootReducer from "./rootReducer"
import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  },
  devTools: process.env.REACT_APP_ENVIRONMENT !== "production"
})

export { store }
export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>
