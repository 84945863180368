import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import useAuthContext from "contexts/auth/AuthInContext"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"
import useMarketPlaceAuthGuardGateContext from "web-marketplace/contexts/guards/auth/gate/useMarketPlaceAuthGuardGateContext"

const OtpSubmitButton = () => {
  const {
    isEmailAuthLoading,
    formData: { otpCode }
  } = useEmailAuthContext()
  const { isUserLoggedIn } = useAuthContext()
  const { isGateLocked } = useMarketPlaceAuthGuardGateContext() || {}

  const isLoading = isEmailAuthLoading || (isGateLocked && isUserLoggedIn)
  const isDisabled = otpCode?.length !== 6 || isLoading

  return (
    <>
      <ButtonHandler
        id="buttonHandler-Send"
        type="submit"
        text="Verify_and_Login"
        disabled={isDisabled}
        isLoading={isLoading}
      />
    </>
  )
}

export default OtpSubmitButton
