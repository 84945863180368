// ** Reactstrap Imports
import { Button, Col, Row } from "reactstrap"

// ** Styles
import "@styles/base/pages/page-misc.scss"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

import error500Svg from "@src/assets/images/pages/error_500.svg"

const GenericErrorPage = ({ error }) => {
  // ** Hooks
  const { t } = useTranslation()

  useEffect(() => {
    window?.["newrelic"]?.["noticeError"](error, { errorLevel: "INDEX" })
  }, [])

  return (
    <div className="misc-wrapper">
      <Row
        className="d-flex flex-column flex-lg-row w-100"
        style={{
          maxWidth: "950px"
        }}
      >
        <Col sm={12} lg={6} className=" text-center">
          <img
            className="img-fluid"
            style={{ width: "100%", maxWidth: "450px" }}
            src={error500Svg}
            alt="Unhandled error page"
          />
        </Col>
        <Col sm={12} lg={6} className=" d-flex justify-content-center align-items-center">
          <div className="text-start p-1 p-md-0">
            <h1 className="mb-1">{t("unhandled_error")} 🕵🏻‍♀️</h1>
            <h4 className="text-wrap d-block d-md-none">{t("requested_page_generic_error")}</h4>
            <h4 className="text-nowrap d-none d-md-block">{t("requested_page_generic_error")}</h4>
            <h4>{t("requested_page_generic_error2")}</h4>
            <h4>{t("requested_page_generic_error3")}</h4>
            <Button className="mt-1 round w-50" color="primary" tag="a" href="javascript:history.back()" outline>
              {t("back")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
export default GenericErrorPage
