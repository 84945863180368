import { Col, Row } from "reactstrap"
import LoginContentComponent from "./components/content"
import { LoginComponentProps } from "@core/components/login"
import LoginSwiper, { LoginSwiperProps } from "./components/swiper"
import classNames from "classnames"

interface LoginContentPageProps extends LoginComponentProps, LoginSwiperProps {
  LoginContainerTag?: React.ElementType
  hideSwiper?: boolean
}

const LoginPage = ({ swiperData, hideSwiper, LoginContainerTag, ...loginContentProps }: LoginContentPageProps) => {
  return (
    <div className="fullscreen-login-layout">
      <div className="fullscreen-login-container">
        <div className="fullscreen-login-content-wrapper">
          <Row className="fullscreen-login-content-row w-100">
            {!hideSwiper && (
              <Col md={6} className="d-none d-md-flex align-items-center justify-content-center h-100">
                <LoginSwiper swiperData={swiperData} />
              </Col>
            )}
            <Col
              md={hideSwiper ? 12 : 6}
              className={classNames("d-flex align-items-center justify-content-center h-100", {
                "w-100 p-0": hideSwiper
              })}
            >
              <LoginContentComponent {...loginContentProps} ContainerTag={LoginContainerTag} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default LoginPage
