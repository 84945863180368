import useAuthContext from "contexts/auth/AuthInContext"
import Web3AuthContext, {
  Web3AuthLoadingState,
  Web3AuthProviderValues,
  Web3AuthProvidersEnum,
  Web3AuthResultState,
  Web3AuthStatusEnum,
  Web3AuthStatusState,
  Web3IdleStageShowingState,
  Web3IdleStages,
  Web3SelectedAuthProviderState
} from "./web3AuthContext"
import { useEffect, useState } from "react"
import MetamaskAuthProvider from "./providers/metamask/metamaskAuthProvider"
import useMetaMaskAuthContext from "./providers/metamask/useMetamaskAuthContext"
import { AuthModalsEnum } from "contexts/auth/AuthContext"

interface Web3AuthProviderStateProps
  extends Web3AuthStatusState,
    Web3AuthLoadingState,
    Web3AuthResultState,
    Web3SelectedAuthProviderState,
    Web3IdleStageShowingState {}

interface Web3AuthProviderProps extends Web3AuthProviderStateProps {
  children: React.ReactNode
}

const Web3AuthProvider = ({ children }: Pick<Web3AuthProviderProps, "children">) => {
  const [web3AuthStatus, setWeb3AuthStatus] = useState<Web3AuthStatusEnum>(Web3AuthStatusEnum.Idle)
  const [isWeb3AuthLoading, setIsWeb3AuthLoading] = useState<boolean>(false)
  const [web3AuthResult, setWeb3AuthResult] = useState<any>(null)
  const [selectedWeb3AuthProvider, setSelectedWeb3AuthProvider] = useState<Web3AuthProvidersEnum>(null)
  const [web3IdleStageShowing, setWeb3IdleStageShowing] = useState<Web3IdleStages>(Web3IdleStages.Get)

  const providerStatesProps: Web3AuthProviderStateProps = {
    web3AuthResult,
    setWeb3AuthResult,
    web3AuthStatus,
    setWeb3AuthStatus,
    isWeb3AuthLoading,
    setIsWeb3AuthLoading,
    selectedWeb3AuthProvider,
    setSelectedWeb3AuthProvider,
    web3IdleStageShowing,
    setWeb3IdleStageShowing
  }

  const authProviderProps = {
    selectedWeb3AuthProvider,
    setWeb3AuthStatus,
    isWeb3AuthLoading
  }

  return (
    <MetamaskAuthProvider {...authProviderProps}>
      <AuxWeb3AuthProvider {...providerStatesProps}>{children}</AuxWeb3AuthProvider>
    </MetamaskAuthProvider>
  )
}

const AuxWeb3AuthProvider = ({ children, ...providerStateProps }: Web3AuthProviderProps) => {
  const { Provider } = Web3AuthContext
  const {
    isWeb3AuthLoading,
    selectedWeb3AuthProvider,
    setIsWeb3AuthLoading,
    setSelectedWeb3AuthProvider,
    setWeb3AuthResult,
    setWeb3AuthStatus,
    web3AuthResult,
    web3AuthStatus,
    setWeb3IdleStageShowing,
    web3IdleStageShowing
  } = providerStateProps

  const { setCurrentAuthStageShowing } = useAuthContext()
  const { resetMetaMaskAuth, handleMetaMaskLogin } = useMetaMaskAuthContext()

  const resetWeb3Auth = () => {
    setWeb3AuthResult(null)
    const web3ProviderResets = {
      [Web3AuthProvidersEnum.METAMASK]: () => resetMetaMaskAuth()
    }

    const resetWeb3Provider = web3ProviderResets[selectedWeb3AuthProvider]

    if (resetWeb3Provider) {
      resetWeb3Provider()
    }

    setSelectedWeb3AuthProvider(null)
    setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    setIsWeb3AuthLoading(false)
  }

  useEffect(() => {
    if (selectedWeb3AuthProvider) {
      setIsWeb3AuthLoading(true)
    }
  }, [selectedWeb3AuthProvider])

  const providerValues: Web3AuthProviderValues = {
    resetWeb3Auth,
    web3AuthStatus,
    setWeb3AuthStatus,
    isWeb3AuthLoading,
    setIsWeb3AuthLoading,
    web3AuthResult,
    setWeb3AuthResult,
    selectedWeb3AuthProvider,
    setSelectedWeb3AuthProvider,
    setWeb3IdleStageShowing,
    web3IdleStageShowing
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default Web3AuthProvider
