import MarketPlaceAuthGuardProvider from "web-marketplace/contexts/guards/auth"

enum MarketPlaceParamsEnum {
  token = "token",
  collection = "collection",
  marketplace = "marketplace",
  "marketplace-company" = "marketplace-company"
}

type ParamsGuardRequestMaps = {
  [key in MarketPlaceParamsEnum]: Function
}

interface MarketPlaceGuardsProviderProps {
  children: React.ReactNode
}

const MarketPlaceGuardProvider = ({ children }: MarketPlaceGuardsProviderProps) => {
  return <MarketPlaceAuthGuardProvider>{children}</MarketPlaceAuthGuardProvider>
}

export default MarketPlaceGuardProvider
