import DisplaySource from "components/displaySource"

interface Web3CardImageProps {
  src: string
  [key: string]: any
}

const Web3CardImage = ({ src, ...rest }: Web3CardImageProps) => {
  return <DisplaySource src={src} contained {...rest} size={{ maxWidth: "48px" }} />
}

export default Web3CardImage
