import { MdOutlinePrivacyTip } from "react-icons/md"
import LoginDescription from "../../components/typography/description"
import LoginTitle from "../../components/typography/title"
import KycIcon from "../kyc/components/kycIcon"
import LoginCaption from "../../components/typography/caption"
import DisplaySource from "components/displaySource"
import Robot403 from "assets/images/errors/403_Robot.png"
import ButtonHandler from "../../components/buttons/buttonHandler"

import { useEffect } from "react"
import useAuthContext from "contexts/auth/AuthInContext"
import { AuthModalsEnum } from "contexts/auth/AuthContext"
import useMarketPlaceAuthContext from "web-marketplace/contexts/auth/useMarketPlaceAuthContext"

const LoginAccessDeniedStage = () => {
  const { isUserLoggedIn, setCurrentAuthStageShowing } = useAuthContext()
  const { handleAuthLogout } = useMarketPlaceAuthContext()

  useEffect(() => {
    if (!isUserLoggedIn) {
      setCurrentAuthStageShowing(AuthModalsEnum.LOGIN)
    }
  }, [isUserLoggedIn])

  return (
    <div className="d-flex flex-column justify-content-between h-100 gap-2">
      <div className="login-wrapper text-center">
        <LoginTitle text="Login_Access_Denied" />
        <LoginDescription text="Login_Access_Denied_Description" />
      </div>
      <div className="login-wrapper text-center gap-2">
        <DisplaySource centralized src={Robot403} contained displayProportion={"50%"} />
        <LoginDescription text="Login_Access_Denied_Description_2" />
      </div>
      <div className="login-wrapper gap-2">
        <ButtonHandler type="button" handleClickAction={() => handleAuthLogout()} text="Logout" />
        <span className="d-flex align-items-center justify-content-center gap-25">
          <KycIcon icon={MdOutlinePrivacyTip} size={"sm"} />
          <LoginCaption style={{ color: "#939393" }} text="Login_Access_Denied_Caption" />
        </span>
      </div>
    </div>
  )
}

export default LoginAccessDeniedStage
