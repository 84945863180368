import classNames from "classnames"
import { useTranslation } from "react-i18next"

export interface LoginCaptionProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const LoginCaption = ({ className, text, style, textProps = {}, ...rest }: LoginCaptionProps) => {
  const { t } = useTranslation()
  return (
    <span className={classNames("m-0", className)} {...rest} style={{ fontSize: "0.8rem", ...style }}>
      {t(text, textProps)}
    </span>
  )
}

export default LoginCaption
