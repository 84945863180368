import useWagmi from "contexts/auth/hooks/useWagmi"
import { MetaMaskAuthStatusState, MetaMaskLoginCallbackFrom, MetaMaskStatusEnum } from "../metamaskAuthContext"
import handleMetaMaskError, { MetamaskErrors } from "./handleError"

interface NetworksData {
  chainId: string
  chainName: string
  nativeCurrency: { name: string; symbol: string; decimals: number }
  rpcUrls: string[]
  blockExplorerUrls: string[]
}

interface SwitchMetaMaskNetworkProps
  extends Pick<MetaMaskAuthStatusState, "setMetaMaskAuthStatus">,
    Pick<ReturnType<typeof useWagmi>, "switchNetwork"> {
  proceedFrom: MetaMaskLoginCallbackFrom
  setShouldRestartAuthProcess?: boolean
  expectedChainId: number
  isChainAlreadyConfigured?: boolean
}

const toHex = (num) => {
  return `0x${num.toString(16)}`
}

const switchMetaMaskNetwork = async ({
  proceedFrom,
  setMetaMaskAuthStatus,
  switchNetwork: { switchNetworkAsync },
  expectedChainId,
  isChainAlreadyConfigured
}: SwitchMetaMaskNetworkProps) => {
  const {
    RequestedNetwork_Pending: ChainNotConfigured,
    SwitchNetwork_Pending: Pending,
    SwitchNetwork_Dismissed: Dismissed,
    SwitchNetwork_Failed: Failed,
    SwitchNetwork_Stack: Stack,
    SwitchNetwork_Success: Success
  } = MetaMaskStatusEnum

  const mustTrySwitchNetwork = (proceedFrom === "switch" && switchNetworkAsync) || (!proceedFrom && switchNetworkAsync)
  const response = {
    switchedNetworkData: null,
    stopProceed: false,
    isChainNotConfigured: false
  }

  if (mustTrySwitchNetwork) {
    setMetaMaskAuthStatus(Pending)

    try {
      const switchedNetworkData = await switchNetworkAsync(expectedChainId)
      setMetaMaskAuthStatus(Success)
      response.switchedNetworkData = switchedNetworkData
      return response
    } catch (err) {
      console.log("Error", JSON.stringify(err))
      const mappedMetaMaskSwitchNetworkErrors = handleMetaMaskError(err?.name as MetamaskErrors)
      if (mappedMetaMaskSwitchNetworkErrors) {
        const { callback, status } = mappedMetaMaskSwitchNetworkErrors

        const authStatus = {
          dismiss: Dismissed,
          stack: Stack,
          [MetaMaskStatusEnum.RequestedNetwork_Pending]: ChainNotConfigured
        }

        let callbackStatus = authStatus[status]
        if (callbackStatus === ChainNotConfigured) {
          if (isChainAlreadyConfigured) {
            callbackStatus = Dismissed
          } else {
            response.isChainNotConfigured = true
          }
        }

        callback(setMetaMaskAuthStatus, callbackStatus)
      } else {
        console.log("Error", JSON.stringify(err))
        setMetaMaskAuthStatus(Failed)
      }
      response.stopProceed = true
      return response
    }
  } else if (!switchNetworkAsync || proceedFrom === "addChain") {
    response.stopProceed = true
    return response
  }

  return null
}

export default switchMetaMaskNetwork
