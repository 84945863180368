import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import type { RootState, AppDispatch } from "./store"
import type { RootState as WeispaceRootState, AppDispatch as WeispaceAppDispatch } from "../web-weispace/redux/store"

export type useAppSelectorProps = "weispace" | "marketplace" | "default"
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useWeispaceAppDispatch: () => WeispaceAppDispatch = useDispatch
export const useWeispaceAppSelector: TypedUseSelectorHook<WeispaceRootState> = useSelector
