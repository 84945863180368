import WeispaceLoginBannerRoot from "./root"
import EarnRewardsImage from "web-weispace/assets/images/pages/login/earn_rewards.png"

const WeispaceLoginEarnRewardsBanner = () => {
  const { Description, Image, Title, Container } = WeispaceLoginBannerRoot

  return (
    <Container>
      <Title text="Weispace_Login_Banner_Earn_Rewards_Title" />
      <Image src={EarnRewardsImage} />
      <Description text="Weispace_Login_Banner_Earn_Rewards_Description" />
    </Container>
  )
}

export default WeispaceLoginEarnRewardsBanner
