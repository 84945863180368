import { useAppDispatch } from "redux/hooks"
import { removeSession } from "services/store"
import { removeSessionMetamask } from "services/store/metamask"
import useLogger from "./hooks/logger/useLogger"

function useIsJWTExpired(JWT) {
  const { createLogger } = useLogger(true)
  const dispatch = useAppDispatch()
  const logger = createLogger("useIsJWTExpired")
  if (JWT?.expires) {
    logger.add("Verificando se o JWT está expirado")
    const expirationTime = new Date(JWT?.expires).toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
    const expirationTimestamp = new Date(expirationTime).getTime()
    const currentTime = new Date().toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" })
    const currentTimestamp = new Date(currentTime).getTime()
    if (expirationTimestamp < currentTimestamp) {
      logger.add("- JWT está expirado")
      dispatch(removeSession({}))
      dispatch(removeSessionMetamask({}))
      logger.read()
      return false
    }
  }

  if (JWT?.accessToken) {
    logger.add("- JWT não está expirado")
    logger.read()
    return true
  }
}

export default useIsJWTExpired
