import { createSlice } from "@reduxjs/toolkit"
import { getCompanyBySlug } from "./slugsActions"
import { CreatedCompany } from "interfaces/company"

const companyBySlugState = {
  companyBySlug: {} as CreatedCompany,
  companyBySlugLoading: true,
  companyBySlugError: undefined
}

const companyBySlugSlice = createSlice({
  name: "companyBySlug",
  initialState: companyBySlugState,
  reducers: {
    setCompanyBySlug: (state, { payload }) => {
      state.companyBySlug = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCompanyBySlug.pending, (state) => {
      state.companyBySlugLoading = true
    })
    builder.addCase(getCompanyBySlug.fulfilled, (state, { payload }) => {
      state.companyBySlug = payload
      state.companyBySlugLoading = false
    })
    builder.addCase(getCompanyBySlug.rejected, (state, { error }) => {
      state.companyBySlugError = error
      state.companyBySlugLoading = false
    })
  }
})

export default companyBySlugSlice.reducer
