import { createSlice } from "@reduxjs/toolkit"
import { getCollectionBySlug } from "./slugsActions"
import { CreatedCollection } from "interfaces/collection"
import {
  collectionBlindMintExtraReducers,
  collectionBlindMintReducers,
  collectionBlindMintState
} from "./collection/blindmint/sliceProps"

const collectionBySlugState = {
  collectionBySlug: {} as CreatedCollection,
  collectionIsPrivate: false,
  collectionBySlugLoading: true,
  collectionBySlugError: undefined
}

const {
  pending: pendingCollectionBlindMint,
  fulfilled: fulfilledCollectionBlindMint,
  rejected: rejectedCollectionBlindMint
} = collectionBlindMintExtraReducers

const collectionBySlugSlice = createSlice({
  name: "collectionBySlug",
  initialState: {
    ...collectionBySlugState,
    ...collectionBlindMintState
  },
  reducers: {
    setcollectionBySlug: (state, { payload }) => {
      state.collectionBySlug = payload
    },
    resetCollectionBySlug: (state) => {
      state.collectionBySlug = {} as CreatedCollection
      state.collectionIsPrivate = false
      state.collectionBySlugLoading = true
      state.collectionBySlugError = undefined
    },
    ...collectionBlindMintReducers
  },
  extraReducers: (builder) => {
    builder.addCase(getCollectionBySlug.pending, (state, { payload }) => {
      state.collectionBySlugLoading = true
    })
    builder.addCase(getCollectionBySlug.fulfilled, (state, { payload }) => {
      const { haveAccess, memberAccessStatus, ...rest } = payload || {}
      state.collectionBySlug = { ...rest } as CreatedCollection
      state.collectionBySlugLoading = false
    })
    builder.addCase(getCollectionBySlug.rejected, (state, { error }) => {
      state.collectionBySlugError = error
      state.collectionBySlugLoading = false
    })
    builder.addCase(pendingCollectionBlindMint["action"], pendingCollectionBlindMint["reducer"])
    builder.addCase(fulfilledCollectionBlindMint["action"], fulfilledCollectionBlindMint["reducer"])
    builder.addCase(rejectedCollectionBlindMint["action"], rejectedCollectionBlindMint["reducer"])
  }
})

export const { resetCollectionBySlug, setCollectionBlindMint, setCollectionBlindMintLoading, setcollectionBySlug } =
  collectionBySlugSlice.actions
export default collectionBySlugSlice.reducer
