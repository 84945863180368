import { Button as StrapButton, ButtonProps as StrapButtonProps } from "reactstrap"
import Icon, { IconProps } from "../icon"
import classNames from "classnames"

export interface ButtonProps extends StrapButtonProps {
  leftIcon?: IconProps["icon"]
  rightIcon?: IconProps["icon"]
  iconProps?: Omit<IconProps, "icon">
}

const Button = ({ children, color = "", className, leftIcon, rightIcon, iconProps, ...rest }: ButtonProps) => {
  const ariaChecked = rest?.["aria-checked"] || false

  const ButtonIcon = ({ icon }) => {
    return <Icon icon={icon} {...iconProps} />
  }

  return (
    <StrapButton
      color={color}
      className={classNames("", {
        [className]: className,
        "d-flex align-items-center justify-content-center gap-75": leftIcon || rightIcon
      })}
      aria-checked={ariaChecked}
      {...rest}
    >
      {leftIcon && <ButtonIcon icon={leftIcon} />}
      {children}
      {rightIcon && <ButtonIcon icon={rightIcon} />}
    </StrapButton>
  )
}

export default Button
