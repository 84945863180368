import LoginInputContainer from "./container"
import LoginInputContentRoot from "./input"
import LoginInputLabel from "./label"

const LoginInputRoot = {
  Container: LoginInputContainer,
  Input: LoginInputContentRoot,
  Label: LoginInputLabel
}

export default LoginInputRoot
