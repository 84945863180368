import classNames from "classnames"
import { useTranslation } from "react-i18next"

export interface LoginTitleProps {
  text?: string
  textProps?: any
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const LoginTitle = ({ className, text, style, textProps = {}, ...rest }: LoginTitleProps) => {
  const { t } = useTranslation()
  return (
    <h1 className={classNames("m-0 fs-3 fw-bolder", className)} {...rest} style={style}>
      {t(text, textProps)}
    </h1>
  )
}

export default LoginTitle
