import { AuthModalsEnum } from "contexts/auth/AuthContext"
import { Dispatch, SetStateAction } from "react"
import { useAppDispatch } from "redux/hooks"
import { MemberPermissions, resetMemberPermissions } from "services/store"
import { CreateLogger } from "utility/hooks/logger/useLogger"

interface ShowMarketPlaceKycGuardFormEffect {
  createLogger: CreateLogger
  memberPermissions: MemberPermissions
  dispatch: ReturnType<typeof useAppDispatch>
  setCurrentAuthStageShowing: Dispatch<SetStateAction<AuthModalsEnum>>
  mustResetMemberPermissions: boolean
  isUserLoggedIn: boolean
}

const showMarketPlaceKycGuardForm = ({
  createLogger,
  memberPermissions,
  setCurrentAuthStageShowing,
  mustResetMemberPermissions,
  dispatch,
  isUserLoggedIn
}: ShowMarketPlaceKycGuardFormEffect) => {
  const memberAccessLevelHandlerTable = {
    "no-access": () => {
      logger.add("--- Usuário não tem permissão de acesso")
      logger.add("--- Redirecionando para tela de acesso negado")
      setCurrentAuthStageShowing(AuthModalsEnum.ACCESS_DENIED)
    },
    "full-access": () => {
      logger.add("--- Usuário tem permissão de acesso")
    }
  }

  const memberAccessStatusHandlerTable = {
    idle: () => {
      logger.add("--- Usuário precisa fazer o KYC")
      logger.add("--- Redirecionando para KYC")
      setCurrentAuthStageShowing(AuthModalsEnum.KYC)
    },
    pending_approval: () => {
      logger.add("--- KYC do usuário está pendente")
      logger.add("--- Redirecionando para KYC")
      setCurrentAuthStageShowing(AuthModalsEnum.KYC)
    },
    refused: () => {
      logger.add("--- KYC do usuário foi recusado")
      logger.add("--- Redirecionando para KYC")
      setCurrentAuthStageShowing(AuthModalsEnum.KYC)
    }
  }

  const havePermissions = Object.keys(memberPermissions).length !== 0
  const logger = createLogger("MarketPlaceKycGuardProvider => Show KYC Form Effect")
  if (
    havePermissions &&
    memberPermissions?.accessStatus !== "approved" &&
    !mustResetMemberPermissions &&
    isUserLoggedIn
  ) {
    logger.add("- Usuário possui permissões")
    const accessLevelHandler = memberAccessLevelHandlerTable[memberPermissions?.accessLevel]
    if (accessLevelHandler) {
      logger.add("-- Verificando se usuário tem permissão de acesso")
      accessLevelHandler()

      const accessStatusHandler = memberAccessStatusHandlerTable[memberPermissions?.accessStatus]
      if (accessStatusHandler) {
        logger.add("-- Verificando se usuário precisa fazer KYC")
        accessStatusHandler()
      } else {
        logger.add("-- Usuário não precisa fazer KYC")
      }
    } else {
      memberAccessLevelHandlerTable["no-access"]()
    }
    logger.read()
  } else if (mustResetMemberPermissions || !isUserLoggedIn) {
    logger.add("- Resetando permissões de usuário")
    logger.read()
    dispatch(resetMemberPermissions())
  }
}

export default showMarketPlaceKycGuardForm
