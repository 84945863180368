import { useAuthContext } from "contexts/auth/AuthContext"
import React from "react"
import AuthButtonRoot, { AuthButtonProps } from "./root"
import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import useEmailAuthContext from "contexts/auth/methods/email/useEmailAuthContext"

interface MainAuthButtonProps extends Omit<AuthButtonProps, "id"> {}

const MainAuthButton: React.FC<MainAuthButtonProps> = ({ authMethod, handleClickAction, src }) => {
  const { Container, Logo, Text } = AuthButtonRoot
  const { isFirebaseAuthLoading, isMetamaskAuthLoading } = useAuthContext()
  const { isEmailAuthLoading } = useEmailAuthContext()
  const { isWeb3AuthLoading } = useWeb3AuthContext()

  const isLoading = isFirebaseAuthLoading || isWeb3AuthLoading || isEmailAuthLoading

  return (
    <>
      <Container handleClickAction={handleClickAction} isLoading={isLoading} className="p-75 px-2 rounded-3" id={`connect-with-${authMethod.toLowerCase()}-button`}>
        <Logo authMethod={authMethod} src={src} />
        <Text>{authMethod}</Text>
      </Container>
    </>
  )
}

export default MainAuthButton
