import { useAppSelector } from "redux/hooks"
import KycResultPendingStageSlide from "./types/pending"
import KycResultRefusedStageSlide from "./types/refused"
import KycResultSendStageSlide from "./types/send"

const KycResultStageSlide = () => {
  return (
    <>
      <KycResultPendingStageSlide />
      <KycResultRefusedStageSlide />
      <KycResultSendStageSlide />
    </>
  )
}

export default KycResultStageSlide
