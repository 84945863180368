import { useEffect, useState } from "react"

import { fetchWhiteLabel } from "../redux/whitelabelConfig"
import { fetchProcessingTokens, fetchTokens } from "web-weispace/services/tokens/tokensActions"
import { useAppSelector, useWeispaceAppDispatch, useWeispaceAppSelector } from "redux/hooks"
import useWhitelabel from "web-weispace/utility/hooks/useWhitelabel"

const ConfigProvider = () => {
  const { tokens, tokensLoading } = useWeispaceAppSelector((state) => state.tokens)
  const userFromGoogle = useAppSelector((state) => state.auth)
  const userFromMetamask = useAppSelector((state) => state.metamask)
  const JWT = userFromGoogle?.JWT?.accessToken ? userFromGoogle?.JWT?.accessToken : userFromMetamask?.JWT?.accessToken
  const userId = userFromGoogle?.JWT?.accessToken ? userFromGoogle?.JWT?.user?.id : userFromMetamask?.JWT?.user?.id

  const [didFetchTokens, setDidFetchTokens] = useState(false)
  const [didFetchProcessingTokens, setDidFetchProcessingTokens] = useState(false)

  const dispatch = useWeispaceAppDispatch()

  const company = localStorage.getItem("companyId")
  const community = localStorage.getItem("communityId")
  const skin = localStorage.getItem("skin")
  const {
    defaultMarketPlaceData,
    isCustomDomain,
    customDomainCompanyId,
    defaultMarketPlaceLoading,
    isDefaultMarketplaceDataFetched
  } = useWhitelabel()

  useEffect(() => {
    if (isDefaultMarketplaceDataFetched) {
      if (isCustomDomain && defaultMarketPlaceData) {
        dispatch(fetchWhiteLabel({ companyId: company, defaultMarketPlaceData, isCustomDomain, customDomainCompanyId }))
      }
    } else {
      if (!company && community) {
        dispatch(
          fetchWhiteLabel({ communityId: community, defaultMarketPlaceData, isCustomDomain, customDomainCompanyId })
        )
      } else if (company && !community) {
        dispatch(fetchWhiteLabel({ companyId: company, defaultMarketPlaceData, isCustomDomain, customDomainCompanyId }))
      } else if (company && community) {
        dispatch(
          fetchWhiteLabel({
            communityId: community,
            companyId: company,
            defaultMarketPlaceData,
            isCustomDomain,
            customDomainCompanyId
          })
        )
      } else {
        dispatch(fetchWhiteLabel({}))
      }

      if (tokens?.length === 0 && !didFetchTokens && JWT && userId) {
        dispatch(fetchTokens({ accessToken: JWT, userId }))
        setDidFetchTokens(true)
      }

      if (didFetchTokens && !tokensLoading && !didFetchProcessingTokens && JWT && userId) {
        dispatch(fetchProcessingTokens({ accessToken: JWT, userId }))
        setDidFetchProcessingTokens(true)
      }

      if (skin) {
        localStorage.removeItem("skin")
        window.location.reload()
      }
    }
  }, [
    company,
    community,
    defaultMarketPlaceLoading,
    isDefaultMarketplaceDataFetched,
    tokens,
    tokensLoading,
    JWT,
    userId
  ])

  return null
}

export default ConfigProvider
