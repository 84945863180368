import { Spinner } from "reactstrap"

const FallbackLoading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
      <Spinner color="primary" />
    </div>
  )
}

export default FallbackLoading
