import { Dispatch, SetStateAction, createContext } from "react"


type GlobalLoadingContextType = {
  isGlobalLoadingActive: boolean
  setIsGlobalLoadingActive: Dispatch<SetStateAction<boolean>>
}

const GlobalLoadingContext = createContext({} as GlobalLoadingContextType)

export default GlobalLoadingContext