import { UserKyc } from "interfaces/user"
import { axiosBase, getAxiosConfig } from "services/axiosBase"

const path = "/kyc-submissions"

interface AccessToken {
  accessToken: string
}

interface UserKycData {
  kyc: UserKyc & { companyId: string }
}

const createNewKycSubmission = async ({ accessToken, kyc }: AccessToken & UserKycData) => {
  const config = getAxiosConfig(accessToken)
  const { data } = await axiosBase.post(`${path}`, kyc, config)

  return data
}

const getUserKycSubmission = async ({ accessToken, companyId }: AccessToken & { companyId: string }) => {
  const config = getAxiosConfig(accessToken)
  const { data } = await axiosBase.get(`${path}/company/${companyId}`, config)

  return data
}

export { createNewKycSubmission, getUserKycSubmission }
