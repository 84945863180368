import classNames from "classnames"

interface LoginInputContainerProps {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const LoginInputContainer = ({ children, className, ...rest }: LoginInputContainerProps) => {
  return (
    <div className={classNames("d-flex flex-column", className)} {...rest}>
      {children}
    </div>
  )
}

export default LoginInputContainer
