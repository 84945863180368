import { Web3HandlerStatusInterface } from "./interface"

export enum Web3DetectHandlerStatus {
  Detect_Pending = "detect_pending",
  Detect_Success = "detect_success",
  Detect_Dismiss = "detect_dismiss",
  Detect_Fail = "detect_fail"
}

export interface DetectWeb3HookProps extends Web3HandlerStatusInterface {}

/**
 * Detects if the user has the selected web3 provider installed
 * @param changeWeb3HandlerStatus - Function to change the status of the web3 handler
 * @param web3Provider - The web3 provider to detect
 * @returns {stopPropagation: boolean} - If the web3 provider is not installed, it will return true to stop the propagation of the event
 */

const detectWeb3Provider = ({ changeWeb3HandlerStatus, web3Provider }: DetectWeb3HookProps) => {
  const { Detect_Pending: Pending, Detect_Success: Success, Detect_Fail: Fail } = Web3DetectHandlerStatus

  const returnResult = { stopPropagation: false }

  const providersMethod = {
    metamask: () => {
      return ethereum.isMetaMask
    }
  }

  changeWeb3HandlerStatus(Pending)

  const { ethereum } = window

  if (ethereum) {
    const selectedProviderMethod = providersMethod[web3Provider]

    if (selectedProviderMethod) {
      if (selectedProviderMethod()) {
        changeWeb3HandlerStatus(Success)
        return returnResult
      }
    }
  }

  changeWeb3HandlerStatus(Fail)
  return { ...returnResult, stopPropagation: true }
}

export default detectWeb3Provider
