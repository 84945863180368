import { createAsyncThunk } from "@reduxjs/toolkit"
import { axiosBase } from "services/axiosBase"
import { CommunityPublicData } from "utility/request-interfaces/community-types"

export const getPublicCommunityData = createAsyncThunk<CommunityPublicData, string>(
  "communityPublicData/getById",
  async (communityId: string) => {
    try {
      return (await axiosBase.get<CommunityPublicData>(`/communities/${communityId}`))?.data
    } catch (error) {
      throw new Error(error.response.data)
    }
  }
)
