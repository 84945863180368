import classNames from "classnames"
import { AuthMethods, AuthNetworksEnum, useAuthContext } from "contexts/auth/AuthContext"
import React from "react"
import { Button, ButtonProps } from "reactstrap"

export interface AuthButtonContainerProps extends ButtonProps {
  children: React.ReactNode
  className?: string
  handleClickAction?: Function
  isLoading?: boolean
  disabled?: boolean
  id?: string
}

const AuthButtonContainer = ({
  children,
  handleClickAction,
  isLoading,
  className,
  outline,
  disabled,
  id,
  ...rest
}: AuthButtonContainerProps) => {
  return (
    <>
      <Button
        id={id}
        color={!outline ? "light" : ""}
        className={classNames("login-button-handler", {
          [className]: className,
          HoverShadownCustomized: !outline,
          "outline-backward-button": outline
        })}
        style={{ cursor: isLoading || disabled ? "not-allowed" : "pointer" }}
        disabled={isLoading || disabled}
        outline={outline}
        onClick={() => handleClickAction && handleClickAction()}
        {...rest}
      >
        {children}
      </Button>
    </>
  )
}

export default AuthButtonContainer
