import { Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import ButtonHandler from "@core/components/login/components/buttons/buttonHandler"
import { MetaMaskLoginCallbackFrom } from "contexts/auth/methods/web3/providers/metamask/metamaskAuthContext"
import useMetaMaskAuthContext from "contexts/auth/methods/web3/providers/metamask/useMetamaskAuthContext"

interface MetamaskStatusProps {
  pendingText: string
  dismissText: string
  alreadyPendingText: string
  retryIn: MetaMaskLoginCallbackFrom
}

const MetamaskStatus = ({ dismissText, pendingText, alreadyPendingText, retryIn }: MetamaskStatusProps) => {
  const { t } = useTranslation()

  const { metaMaskAuthStatus, handleMetaMaskLogin, isMetaMaskAuthLoading } = useMetaMaskAuthContext()

  const statusText = metaMaskAuthStatus.split(".")[1]

  const isLoading = isMetaMaskAuthLoading
  const isDisabled = isLoading || statusText === "4"

  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center gap-50 flex-fill">
        <h5 className="text-center m-0" style={{ maxWidth: "90%" }}>
          {statusText === "1" && t(pendingText)}
          {statusText === "2" && t(dismissText)}
          {statusText === "4" && t(alreadyPendingText)}
        </h5>
      </div>
      <ButtonHandler
        text="Try_Again"
        handleClickAction={() => handleMetaMaskLogin(retryIn)}
        isLoading={isMetaMaskAuthLoading}
        disabled={isDisabled}
      />
      {statusText === "4" && (
        <div className="d-flex gap-1 justify-content-between align-items-center">
          <span className="text-center" style={{ fontSize: "12px" }}>
            {t("Button_Still_Disabled")}
          </span>
          <span className="text-decoration-underline fw-bolder cursor-pointer" onClick={() => handleMetaMaskLogin()}>
            {t("Verify")}           
          </span>
        </div>
      )}
    </>
  )
}

export default MetamaskStatus
