import { fetchNotEndedAuctionTokens, fetchTokens, fetchIntegrationTokens, fetchProcessingTokens } from "./tokensActions"
import { createSlice } from "@reduxjs/toolkit"

import type { CreatedTokenWithOwnedQuantity, CreatedTokenWithProcessingFlag } from "./types"

const initialTokensState = {
  tokens: [] as CreatedTokenWithOwnedQuantity[],
  tokensLoading: true,
  tokensErrorMessage: undefined,
  processingTokens: [] as CreatedTokenWithProcessingFlag[],
  processingTokensLoading: false,
  processingTokensErrorMessage: undefined,
  notEndedAuctionTokens: [] as CreatedTokenWithOwnedQuantity[],
  notEndedAuctionTokensLoading: false,
  notEndedAuctionTokensErrorMessage: undefined,
  integrationTokens: [] as CreatedTokenWithOwnedQuantity[],
  integrationTokensLoading: false,
  integrationTokensErrorMessage: undefined
}

const tokensSlice = createSlice({
  name: "tokens",
  initialState: initialTokensState,
  reducers: {
    setTokens: (state, { payload }) => {
      state.tokens = payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokens.pending, (state, _action) => {
        state.tokensLoading = true
      })
      .addCase(fetchTokens.fulfilled, (state, { payload }) => {
        state.tokensLoading = false
        state.tokens = payload
      })
      .addCase(fetchTokens.rejected, (state, { error }) => {
        state.tokensLoading = false
        state.tokensErrorMessage = error
      })
      .addCase(fetchProcessingTokens.pending, (state, _action) => {
        state.processingTokensLoading = true
      })
      .addCase(fetchProcessingTokens.fulfilled, (state, { payload }) => {
        state.processingTokensLoading = false
        state.processingTokens = payload
        const previousTokens = state.tokens || []
        const mixedTokensWithProcessingTokens = [...previousTokens, ...payload] as CreatedTokenWithOwnedQuantity[]

        state.tokens = mixedTokensWithProcessingTokens
      })
      .addCase(fetchProcessingTokens.rejected, (state, { error }) => {
        state.processingTokensLoading = false
        state.processingTokensErrorMessage = error
      })
      .addCase(fetchNotEndedAuctionTokens.pending, (state, _action) => {
        state.notEndedAuctionTokensLoading = true
      })
      .addCase(fetchNotEndedAuctionTokens.fulfilled, (state, { payload }) => {
        state.notEndedAuctionTokensLoading = false
        state.notEndedAuctionTokens = payload
        state.notEndedAuctionTokensErrorMessage = undefined
      })
      .addCase(fetchNotEndedAuctionTokens.rejected, (state, { error }) => {
        state.notEndedAuctionTokensLoading = false
        state.notEndedAuctionTokens = []
        state.notEndedAuctionTokensErrorMessage = error
      })
      .addCase(fetchIntegrationTokens.pending, (state, _action) => {
        state.integrationTokensLoading = true
      })
      .addCase(fetchIntegrationTokens.fulfilled, (state, { payload }) => {
        state.integrationTokensLoading = false
        state.integrationTokens = payload
        state.integrationTokensErrorMessage = undefined
      })
      .addCase(fetchIntegrationTokens.rejected, (state, { error }) => {
        state.integrationTokensLoading = false
        state.integrationTokens = []
        state.integrationTokensErrorMessage = error
      })
  }
})

export default tokensSlice.reducer
export const { setTokens } = tokensSlice.actions
