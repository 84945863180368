
import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"
import Web3MetamaskLoginBody from "./metamask"

const LoginWeb3StageBody = () => {
  const web3MethodsBodies = {
    [Web3AuthProvidersEnum.METAMASK]: <Web3MetamaskLoginBody />
  }

  const Body = web3MethodsBodies[Web3AuthProvidersEnum.METAMASK]

  return <div className="login-wrapper h-100">{Body}</div>
}

export default LoginWeb3StageBody
