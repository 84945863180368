import { useTranslation } from "react-i18next"

interface WeispaceLoginBannerTitleProps {
  text: string
}

const WeispaceLoginBannerTitle = ({text} : WeispaceLoginBannerTitleProps) => {
  const {t} = useTranslation()
  
  return (
    <>
      <h1 className="m-0 fw-bolder text-center fs-1 text-dark">
        {t(text)}
      </h1>
    </>
  )
}

export default WeispaceLoginBannerTitle