import useWagmi from "contexts/auth/hooks/useWagmi"
import { MetaMaskAuthStatusState, MetaMaskStatusEnum } from "../metamaskAuthContext"
import handleMetaMaskError, { MetamaskErrors } from "./handleError"

interface MetaMaskSignInMessageProps
  extends Pick<MetaMaskAuthStatusState, "setMetaMaskAuthStatus">,
    Pick<ReturnType<typeof useWagmi>, "signMessage"> {
  userAddress: string
  userChainId: number
  message: string
}

/**
 * Signs the user in with metamask
 * @param userAddress - The user's address
 * @param userChainId - The user's chain id
 * @param setMetaMaskAuthStatus - The function to set the metamask auth status
 */

const metaMaskSignInMessage = async ({
  userAddress,
  userChainId,
  message,
  signMessage,
  setMetaMaskAuthStatus
}: MetaMaskSignInMessageProps) => {
  const {
    SignInMessage_Pending: Pending,
    SignInMessage_Success: Success,
    SignInMessage_Dismissed: Dismissed,
    SignInMessage_Failed: Failed,
    SignInMessage_Stack: Stack
  } = MetaMaskStatusEnum

  const mustTrySignInMessage = userAddress && userChainId

  if (mustTrySignInMessage) {
    setMetaMaskAuthStatus(Pending)

    const response = {
      signedMessageData: null,
      stopProceed: false
    }
    const { signMessageAsync } = signMessage

    try {
      const signedMessage = await signMessageAsync({ message })

      setMetaMaskAuthStatus(Success)

      response.signedMessageData = signedMessage
      return response
    } catch (err) {
      const mappedMetaMaskConnectErrors = handleMetaMaskError(err?.code as MetamaskErrors)
      if (mappedMetaMaskConnectErrors) {
        const { callback, status } = mappedMetaMaskConnectErrors
        const authStatus = status === "dismiss" ? Dismissed : Stack

        callback(setMetaMaskAuthStatus, authStatus)
      } else {
        console.log("Error", JSON.stringify(err))
        setMetaMaskAuthStatus(Failed)
      }

      response.stopProceed = true
      return response
    }
  }

  return null
}

export default metaMaskSignInMessage
