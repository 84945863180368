import { axiosBase } from "./../axiosbase"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { CreatedToken } from "./../tokens/types.d"
import { CreatedCollection } from "./types.d"

interface FetchCollectionsProps {
  accessToken: string
  tokens: CreatedToken[]
}

export const fetchCollections = createAsyncThunk<CreatedCollection[], FetchCollectionsProps>(
  "collections/fetchCollections",
  async ({ accessToken, tokens }: FetchCollectionsProps) => {
    const config = { headers: { Authorization: `Bearer ${accessToken}` } }

    const collectionIds = tokens.map((token) => token?.collectionId)
    const uniqueCollectionIds = [...new Set(collectionIds)]

    return await Promise.all(
      uniqueCollectionIds.map(async (collectionId) => {
        return axiosBase.get(`/collections/${collectionId}`, config).then((response) => response.data)
      })
    )
  }
)
