import { SwiperOptions } from "swiper"
import { SwiperProps } from "swiper/react/swiper-react"
import StandardSwiper from "./standard"
import PaginationSwiper from "./pagination"

enum SwiperVariantsEnum {
  STANDARD = "Standard",
  PAGINATION = "Pagination"
}

export interface SwiperVariantsRootProps {
  /**
   * Props for the SwiperVariantsRoot component.
   *
   * @typedef {Object} SwiperVariantsRootProps
   * @property {React.ReactNode} children - The content to be displayed inside the Swiper.
   * @property {string} [className] - Additional CSS class for styling.
   * @property {React.CSSProperties} [style] - Inline styles for the Swiper.
   * @property {SwiperOptions} [swiperOptions] - Configuration options for the Swiper.
   * @property {string} [slideClassName] - Additional CSS class for styling individual slides.
   * @property {React.CSSProperties} [slideStyle] - Inline styles for individual slides.
   * @property {any} [key] - Additional props.
   */
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  swiperOptions?: SwiperOptions
  swiperProps?: Omit<SwiperProps, keyof SwiperOptions>
  slideClassName?: string
  slideStyle?: React.CSSProperties
  [key: string]: any
}

const SwiperVariants = {
  /**
   * Swiper Standard variant. Clean swiper without any definition. Check the original file for additional infos.
   */
  [SwiperVariantsEnum.STANDARD]: StandardSwiper,
  /**
   * Swiper Pagination variant. Check the original file for additional infos.
   */
  [SwiperVariantsEnum.PAGINATION]: PaginationSwiper
}

export default SwiperVariants
