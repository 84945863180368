import { lazy } from "react"

const LazyHomeRoot = lazy(() => import("./index"))
const mainLoyaltyHomePath = `/weispace/home`

export const homePath = {
  default: mainLoyaltyHomePath
}

export const homeRoutes = [
  {
    path: homePath.default,
    element: <LazyHomeRoot />
  }
]
