import { CreateLogger } from "utility/hooks/logger/useLogger"

interface MarketPlaceCustomDomainDefaultEffectProps {
  createLogger: CreateLogger
  shouldFetchCustomDomain: boolean
  customDomainCompanyId: string
  defaultMarketPlaceIsLoading: boolean
  customDomainCompanyError: boolean
  defaultMarketPlaceData: any
  marketplaceLink: string
  setDefaultRoute: (route: string) => void
  setDefaultMarketPlaceLink: (link: string) => void
}

/**
 * Caso o usuário acesse uma rota que não existe, ele será redirecionado para a rota padrão
 * @param createLogger - Função para criar um logger
 * @param shouldFetchCustomDomain - Se deve buscar o dominio customizado
 * @param customDomainCompanyId - Id da company do dominio customizado
 * @param defaultMarketPlaceIsLoading - Se a rota padrão do marketplace está carregando
 * @param customDomainCompanyError - Se houve erro ao buscar o dominio customizado
 * @param marketplaceLink - Link do marketplace
 * @param defaultMarketPlaceData - Dados do marketplace
 */

export const defaultMarketPlaceCustomDomainEffect = ({
  createLogger,
  customDomainCompanyError,
  customDomainCompanyId,
  defaultMarketPlaceIsLoading,
  shouldFetchCustomDomain,
  marketplaceLink,
  defaultMarketPlaceData,
  setDefaultMarketPlaceLink,
  setDefaultRoute
}: MarketPlaceCustomDomainDefaultEffectProps) => {
  const logger = createLogger("CustomDomainProvider => Default Effect")
  if (shouldFetchCustomDomain) {
    logger.add("- Deve buscar o dominio customizado")
    if (customDomainCompanyId && !defaultMarketPlaceIsLoading && !customDomainCompanyError) {
      logger.add("- Dominio encontrado")
      logger.add("-- CustomDomainProvider => Loading")
      marketplaceLink = `/${defaultMarketPlaceData.type === "company" ? "marketplace-company" : "marketplace"}/${
        defaultMarketPlaceData?.slug ? defaultMarketPlaceData?.slug : defaultMarketPlaceData?.id
      }`.replace(/\/{2,}/g, "/")
      setDefaultRoute(marketplaceLink)
      setDefaultMarketPlaceLink(marketplaceLink)
      logger.read()
    }
  }
}

export default defaultMarketPlaceCustomDomainEffect
