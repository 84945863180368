import { changeManifestData } from "services/hooks/PWA/dynamicManifest"
import { CreateLogger } from "utility/hooks/logger/useLogger"

interface MarketPlaceCustomDomainchangeManifestEffectProps {
  createLogger: CreateLogger
  customDomainCompanyIsLoading: boolean
  customManifestIsLoading: boolean
  shouldFetchCustomDomain: boolean
  customManifestData: any
  setHasPWAForDownload: (hasPWAForDownload: boolean) => void
}

/**
 * Muda o manifest para o custom domain e adiciona PWA
 * @param createLogger - Função para criar um logger
 * @param customDomainCompanyIsLoading - Se a company do dominio customizado está carregando
 * @param customManifestIsLoading - Se o manifest customizado está carregando
 * @param shouldFetchCustomDomain - Se deve buscar o dominio customizado
 * @param customManifestData - Dados do manifest customizado
 * @param setHasPWAForDownload - Função para alterar o estado de hasPWAForDownload
 */

export const changeManifestMarketPlaceCustomDomainEffect = ({
  createLogger,
  customDomainCompanyIsLoading,
  customManifestIsLoading,
  shouldFetchCustomDomain,
  customManifestData,
  setHasPWAForDownload
}: MarketPlaceCustomDomainchangeManifestEffectProps) => {
  const logger = createLogger("CustomDomainProvider => Change Manifest Effect")
  if (!customDomainCompanyIsLoading && !customManifestIsLoading && shouldFetchCustomDomain) {
    logger.add("- Alterando Manifest")
    changeManifestData(customManifestData)
    logger.add("- Adicionando PWA para download")
    setHasPWAForDownload(true)
    logger.read()
  }
}

export default changeManifestMarketPlaceCustomDomainEffect
