import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import Web3IdleDescription from "../../components/typograpy/description"
import Web3QrCode from "../../components/web3QrCode"
import useGetWeb3ProvidersData from "@core/components/login/utils/hooks/useGetWeb3ProvidersData"
import Web3ButtonHandler from "../../components/buttons/handler"
import { useTranslation } from "react-i18next"
import { Web3IdleStages } from "contexts/auth/methods/web3/web3AuthContext"

const Web3IdleInstallStage = () => {
  const {t} = useTranslation()
  const { selectedWeb3AuthProvider, setWeb3IdleStageShowing } = useWeb3AuthContext()
  const { mobileInstallationLink } = useGetWeb3ProvidersData({ provider: selectedWeb3AuthProvider })

  const handleContinue = () => {
    setWeb3IdleStageShowing(Web3IdleStages.Get_Started_App)
  }

  return (
    <div className="login-wrapper flex-column gap-2 justify-content-center align-items-center">
      <div className="d-flex flex-column gap-50" style={{ maxWidth: "250px" }}>
        <Web3IdleDescription text={"Scan_with_your_phone"} className="text-center" />
        <Web3QrCode value={mobileInstallationLink} />
      </div>
      <Web3ButtonHandler onClick={handleContinue}>{t("Continue")}</Web3ButtonHandler>
    </div>
  )
}

export default Web3IdleInstallStage
