import { useLocation } from "react-router-dom"
import WhitelabelContext, { WhitelabelContextI } from "./whiteLabelContext"
import { useAppDispatch, useAppSelector } from "redux/hooks"
import { TableRouteIdentifierEnum, fetchMountedCompanyWhiteLabel, setWhiteLabelIsLoading } from "redux/whitelabelConfig"
import { startTransition, useEffect, useState } from "react"
import useCustomDomainContext from "../customDomain/useCustomDomainContext"
import useLogger from "utility/hooks/logger/useLogger"

enum MarketPlaceParamsEnum {
  token = "token",
  collection = "collection",
  "collection-blindmint" = "collection-blindmint",
  marketplace = "marketplace",
  "marketplace-company" = "marketplace-company"
}

type ParamsGuardRequestMaps = {
  [key in MarketPlaceParamsEnum]: Function
}

interface WhiteLabelProviderProps {
  children: React.ReactNode
}

const WhiteLabelProvider = ({ children }: WhiteLabelProviderProps) => {
  const { Provider } = WhitelabelContext

  const dispatch = useAppDispatch()
  const { createLogger } = useLogger(true)
  const { shouldFetchCustomDomain, defaultMarketPlaceLink } = useCustomDomainContext()
  const { whiteLabelIsLoading, whiteLabelGuardIsComplete } = useAppSelector((state) => state.whitelabelConfig)

  const [continueFetching, setContinueFetching] = useState<boolean>(true)
  const [whiteLabelIsFetched, setWhiteLabelIsFetched] = useState<boolean>(false)

  const location = useLocation()
  const params = location?.pathname.split("/")
  const shouldFetchWhiteLabel = !shouldFetchCustomDomain && defaultMarketPlaceLink !== ""
  //TODO Deveria ser um id único para evitar fetch contínuo
  const guardWhiteLabelRequestByParamMap: ParamsGuardRequestMaps = {
    [MarketPlaceParamsEnum.token]: () => {
      dispatch(fetchMountedCompanyWhiteLabel({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.TOKEN }))
    },
    [MarketPlaceParamsEnum.collection]: () => {
      dispatch(fetchMountedCompanyWhiteLabel({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COLLECTION }))
    },
    [MarketPlaceParamsEnum.marketplace]: () => {
      dispatch(fetchMountedCompanyWhiteLabel({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COMMUNITY }))
    },
    [MarketPlaceParamsEnum["marketplace-company"]]: () => {
      dispatch(fetchMountedCompanyWhiteLabel({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COMPANY }))
    },
    [MarketPlaceParamsEnum["collection-blindmint"]]: () => {
      dispatch(fetchMountedCompanyWhiteLabel({ slug: params[2], tableIdentifier: TableRouteIdentifierEnum.COLLECTION }))
    }
  }

  useEffect(() => {
    const logger = createLogger("WhiteLabelProvider => Default Effect")
    const getWhiteLabelGuard = async () => {
      const guardCallback = guardWhiteLabelRequestByParamMap?.[params[1]]
      if (guardCallback) {
        await startTransition(() => {
          guardCallback()
        })
        setContinueFetching(false)
      } else {
        dispatch(setWhiteLabelIsLoading(false))
      }
    }

    if (params && continueFetching && shouldFetchWhiteLabel) {
      logger.add("- Deve buscar o WhiteLabel")
      getWhiteLabelGuard()
      logger.read()
    } else if (shouldFetchCustomDomain && defaultMarketPlaceLink === "") {
      logger.add("- Esperando o CustomDomainProvider")
      logger.read()
    }
  }, [params, continueFetching, shouldFetchWhiteLabel])

  //TODO Remover o effect após testes.
  useEffect(() => {
    const logger = createLogger("WhiteLabelProvider => Default Effect")
    if (shouldFetchWhiteLabel) {
      logger.add("- Definindo status do carregamento do WhiteLabel")
      if (whiteLabelIsLoading) {
        setWhiteLabelIsFetched(false)
        logger.add("-- WhiteLabelProvider => Loading")
      }
      if (
        (!whiteLabelIsLoading && whiteLabelGuardIsComplete) ||
        params[1] === "benefit-qrcode" ||
        params[1] === "campaign"
      ) {
        logger.add("-- WhiteLabelProvider => Loaded")
        setWhiteLabelIsFetched(true)
      }
      logger.read()
    } else if (!shouldFetchCustomDomain && defaultMarketPlaceLink !== "") {
      logger.add("- Custom Domain deve ser alterado")
      logger.read()
    }
  }, [whiteLabelIsLoading, shouldFetchCustomDomain])

  const providerValues: WhitelabelContextI = {
    isWhiteLabelProviderLoading: !whiteLabelIsFetched,
    whiteLabelIsFetched
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default WhiteLabelProvider
