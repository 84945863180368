import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import Web3IdleHeader from "./header"
import { isMobile } from "react-device-detect"
import Web3IdleConnect from "./stages/connect"
import Web3IdleGet from "./stages/get"
import { Web3IdleStages } from "contexts/auth/methods/web3/web3AuthContext"
import Web3IdleGetStartedForExtension from "./stages/getStarted/extension"
import Web3IdleGetStartedForApp from "./stages/getStarted/app"
import Web3IdleInstallStage from "./stages/install"

type Web3IdleStagesMap = {
  [key in Web3IdleStages]: {
    header: JSX.Element
    body: JSX.Element
  }
}

const Web3IdleLoginStage = () => {
  const { resetWeb3Auth, selectedWeb3AuthProvider, web3IdleStageShowing, setWeb3IdleStageShowing } =
    useWeb3AuthContext()

  const web3IdleStages: Web3IdleStagesMap = {
    get: {
      header: (
        <Web3IdleHeader
          backAction={resetWeb3Auth}
          title={"Get_{{provider}}"}
          titleOptions={{ provider: selectedWeb3AuthProvider }}
        />
      ),
      body: <Web3IdleGet />
    },
    connect: {
      header: <Web3IdleHeader backAction={resetWeb3Auth} title={isMobile ? "Connect_Wallet" : "Scan_Wallet"} />,
      body: <Web3IdleConnect />
    },
    get_started_extension: {
      header: (
        <Web3IdleHeader
          backAction={() => setWeb3IdleStageShowing(Web3IdleStages.Get)}
          title={"Get_started_with_{{provider}}"}
          titleOptions={{ provider: selectedWeb3AuthProvider }}
        />
      ),
      body: <Web3IdleGetStartedForExtension />
    },
    get_started_app: {
      header: (
        <Web3IdleHeader
          backAction={() => setWeb3IdleStageShowing(Web3IdleStages.Get)}
          title={"Get_started_with_{{provider}}"}
          titleOptions={{ provider: selectedWeb3AuthProvider }}
        />
      ),
      body: <Web3IdleGetStartedForApp />
    },
    install: {
      header: (
        <Web3IdleHeader
          backAction={() => setWeb3IdleStageShowing(Web3IdleStages.Get)}
          title={"Install_{{provider}}"}
          titleOptions={{ provider: selectedWeb3AuthProvider }}
        />
      ),
      body: <Web3IdleInstallStage />
    },
    addChain: {
      header: <Web3IdleHeader backAction={resetWeb3Auth} title={"Add_Chain"} />,
      body: <Web3IdleInstallStage />
    }
  }

  const web3IdleStage = web3IdleStages[web3IdleStageShowing]
  const Header = web3IdleStage?.header
  const Body = web3IdleStage?.body

  return (
    <div className="d-flex flex-column gap-2 h-100 w-100">
      {Header}
      {Body}
    </div>
  )
}

export default Web3IdleLoginStage
