import BrowsePluginCard from "../../components/card/browserCard"
import ProviderForMobileCard from "../../components/card/providerForMobileCard"
import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"

import { isMobile } from "react-device-detect"

const GetWeb3DesktopWallet = () => {
  if (isMobile) return null

  const { selectedWeb3AuthProvider } = useWeb3AuthContext()

  return (
    <>
      <BrowsePluginCard />
      <ProviderForMobileCard provider={selectedWeb3AuthProvider} />
    </>
  )
}

export default GetWeb3DesktopWallet
