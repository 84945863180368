import { Web3AuthProvidersEnum } from "contexts/auth/methods/web3/web3AuthContext"
import Web3InstructionCard from "./variants/instructionCard"
import useGetWeb3ProvidersData from "@core/components/login/utils/hooks/useGetWeb3ProvidersData"
import { isMobile } from "react-device-detect"

interface GetStartedProviderCardProps {
  provider: Web3AuthProvidersEnum
  getStaredFrom?: "app" | "extension"
}

const GetStartedProviderCard = ({ provider, getStaredFrom = "extension" }: GetStartedProviderCardProps) => {
  const { logo } = useGetWeb3ProvidersData({ provider }) || {}

  const getStartedMap = {
    app: {
      title: "Open_{{provider}}_app",
      subtitle: "Open_{{provider}}_app_subtitle"
    },
    extension: {
      title: "Install_{{provider}}_extension",
      subtitle: "Install_{{provider}}_extension_subtitle"
    }
  }

  const { title, subtitle } = getStartedMap[getStaredFrom]

  return (
    <Web3InstructionCard
      image={logo}
      title={title}
      titleProps={{ provider }}
      subtitle={subtitle}
      subtitleProps={{ provider }}
    />
  )
}

export default GetStartedProviderCard
