import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import { Web3IdleStages } from "contexts/auth/methods/web3/web3AuthContext"
import { isMobile } from "react-device-detect"
import GetStartedProviderCard from "../../components/card/getStartedProviderCard"
import CreateOrImportWalletCard from "../../components/card/createOrImportWalletCard"
import Web3InstructionCard from "../../components/card/variants/instructionCard"
import Web3ButtonHandler from "../../components/buttons/handler"
import ProviderFAQButton from "../../components/buttons/providerFAQButton"

import ScanIlu from "assets/images/pages/scan.png"
import { useTranslation } from "react-i18next"
import DisplaySource from "components/displaySource"
import useGetWeb3ProvidersData from "@core/components/login/utils/hooks/useGetWeb3ProvidersData"

const GetWeb3MobileWallet = () => {
  if (!isMobile) return null
  const { t } = useTranslation()
  const { selectedWeb3AuthProvider, setWeb3IdleStageShowing } = useWeb3AuthContext()
  const { logo } = useGetWeb3ProvidersData({ provider: selectedWeb3AuthProvider })
  const handleConnect = () => {
    setWeb3IdleStageShowing(Web3IdleStages.Connect)
  }

  return (
    <>
      <div
        className="d-flex flex-column h-100 align-items-center mx-auto justify-content-between"
        style={{ maxWidth: "320px", gap: "2.5rem" }}
      >
        {/* <div className="d-flex flex-column h-100 gap-2 mx-auto justify-content-center">
          <GetStartedProviderCard provider={selectedWeb3AuthProvider} getStaredFrom="app" />
          <CreateOrImportWalletCard />
          <Web3InstructionCard title="Tap_the_scan_button" subtitle="Tap_the_scan_button_description" image={ScanIlu} />
        </div> */}

        <div className="d-flex flex-column gap-50 text-center w-100">
          <DisplaySource src={logo} centralized size={{ maxWidth: "96px" }} containerStyle={{ flexShrink: 0 }} />
          {/* <h4 className="m-0">Primeiro instale sua carteira MetaMask</h4> */}
        </div>

        <div className="d-flex flex-column gap-50">
          <Web3ButtonHandler onClick={handleConnect} id={`web3-browser-connect`}>
            {t("Install")}
          </Web3ButtonHandler>
          <h6 className="m-0 text-muted text-center">
            {t("New_instructions_will_appear_after_installing")}
          </h6>
        </div>
      </div>
    </>
  )
}

export default GetWeb3MobileWallet
