// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import whitelabelConfig from "./whitelabelConfig"
import token from "../web-marketplace/views/token/store"
import auth from "../services/store"
import metamask from "../services/store/metamask"
import loginModal from "@core/components/login-modal/store"
import refresh from "./refresh"
import checkout from "../components/checkout/store"
import companyBySlug from "../services/slugs/companyBySlugSlice"
import collectionBySlug from "../services/slugs/collectionBySlugSlice"
import communityBySlug from "../services/slugs/communityBySlugSlice"
import tokenBySlug from "services/slugs/tokenBySlugSlice"
import companyPublicData from "services/companyPublicData/companyPublicDataSlice"
import communityPublicData from "services/communityPublicData/communityPublicDataSlice"

const rootReducer = {
  navbar,
  layout,
  whitelabelConfig,
  token,
  auth,
  metamask,
  loginModal,
  refresh,
  checkout,
  companyBySlug,
  collectionBySlug,
  communityBySlug,
  tokenBySlug,
  companyPublicData,
  communityPublicData
}

export default rootReducer
