import Container, { ContainerProps } from "@core/components/container"
import classNames from "classnames"
import React from "react"
import useFormContext from "utility/context/formContext/useFormContext"

interface FormContainerProps extends ContainerProps {}

const FormContainer: React.FC<FormContainerProps> = ({ children, className }) => {
  const { isFormLoading, handleSubmit } = useFormContext()

  return (
    <Container
      Tag={"form"}
      onSubmit={handleSubmit}
      className={classNames("", {
        [className]: className,
        "disabled-forms": isFormLoading
      })}
    >
      {children}
    </Container>
  )
}

export default FormContainer
