import {lazy} from "react"

const LazyContents = lazy(() => import("./pages/home/index"))
const LazyContent = lazy(() => import("./pages/details/index"))
const mainContentsPath = "/weispace/contents"

export const contentsPaths = {
  home: mainContentsPath,
  detail: (contentId) => `${mainContentsPath}/${contentId}`
}

const contentsRoutes = [
  {
    path: contentsPaths.home,
    element: <LazyContents />
  },
  {
    path: contentsPaths.detail(":contentId"),
    element: <LazyContent />
  }
]

export default contentsRoutes