import { Dispatch, MutableRefObject, SetStateAction, createContext } from "react"
import { useForm } from "utility/hooks/useForm"

export interface FormStepProps {
  stepIsDisabled?: boolean
  setStepIsDisabled?: Dispatch<SetStateAction<boolean>>
}

export interface FormContextI<t = any, r = any> extends ReturnType<typeof useForm<t, r>>, FormStepProps {}

const FormContext = createContext({} as FormContextI)

export default FormContext
