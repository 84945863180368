import { AgeGate } from "./validateAgeGate"

type FormDataType = AgeGate

type ValidationRulesType<T> = Partial<{
  [key in keyof T]: (data: T) => string | undefined | null
}>

export function validateFormErrors<T>(formData: T, validationRules: ValidationRulesType<T>, singleDataName?: keyof T) {
  const formErrors: any = {}

  const validate = (propertyName) => {
    const validateResult = validationRules[propertyName]

    if (validateResult) {
      const result = validateResult(formData)

      if (result) {
        formErrors[propertyName] = result
      }
    }
  }

  if (singleDataName) {
    validate(singleDataName)
  } else {
    Object.entries(formData).map(([propertyName, _]) => validate(propertyName))
  }

  return formErrors
}
