import Web3DesktopIdleConnect from "./desktop"
import Web3MobileIdleConnect from "./mobile"

const Web3IdleConnect = () => {
  return (
    <div className="login-wrapper">
      <Web3DesktopIdleConnect />
      <Web3MobileIdleConnect />
    </div>
  )
}

export default Web3IdleConnect
