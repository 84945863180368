import { Provider } from "react-redux"
import ConfigProvider from "./configProvider"
import TitleProvider from "./titleProvider"
import { store } from "../redux/store"
import { ContextProvider } from "web-weispace/utility/hooks/useContext"
import { CookieExpiracyProvider } from "web-weispace/utility/context/CookieExpiracyContext"
import { WhitelabelProvider } from "./whitelabelProvider"
import GlobalLoadingProvider from "contexts/globalLoading/GlobalLoadingProvider"
import { CommunitiesProvider } from "web-weispace/utility/context/communities/communities.context"
import WeispaceAuthProvider from "web-weispace/contexts/auth/weispaceAuthProvider"

const WeispaceProviders = ({ children }) => {
  return (
    <>
      <ContextProvider>
        <CookieExpiracyProvider>
          <Provider store={store}>
            <WeispaceAuthProvider>
              <CommunitiesProvider>
                <WhitelabelProvider>
                  <GlobalLoadingProvider>
                    <ConfigProvider />
                    <TitleProvider />
                    {children}
                  </GlobalLoadingProvider>
                </WhitelabelProvider>
              </CommunitiesProvider>
            </WeispaceAuthProvider>
          </Provider>
        </CookieExpiracyProvider>
      </ContextProvider>
    </>
  )
}

export default WeispaceProviders
