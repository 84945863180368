import classNames from "classnames"
import DisplaySourceSkeleton, { DisplaySourceSkeletonProps } from "./displaySkeleton"
import DisplaySourceImage from "./displayImage"
import DisplaySourceVideo from "./displayVideo"
import DisplayEmptySource from "./displayEmptySource"
import { MediaType } from "utility/request-interfaces/collection-types"

export interface DisplaySourceTypeProps {
  src: string
  displayClassName?: string
  displayStyle?: React.CSSProperties
  alt?: string
}

interface DisplayProportion {
  width: number
  height: number
}

export interface DisplaySourceWrapperProps {
  wrapperClassName?: string
  wrapperStyle?: React.CSSProperties
  contained?: boolean
  displayProportion?: DisplayProportion | string
}

export interface DisplaySourceProps
  extends DisplaySourceTypeProps,
    DisplaySourceWrapperProps,
    Pick<DisplaySourceSkeletonProps, "skeletonType"> {
  isLoading?: boolean
  containerClassName?: string
  containerStyle?: React.CSSProperties
  sourceType?: MediaType
  centralized?: boolean
  size?: {
    maxWidth?: string
    minWidth?: string
  }
  noShrink?: boolean
  withoutGradient?: boolean
  rounded?:
    | "rounded"
    | "rounded-circle"
    | "rounded-pill"
    | "rounded-top"
    | "rounded-end"
    | "rounded-bottom"
    | "rounded-start"
    | "rounded-0"
    | "rounded-1"
    | "rounded-2"
    | "rounded-3"
}
/**
  @param {DisplaySourceTypePropsObject} Props
  @param {string} displayClassName - The class name of the display
  @param {React.CSSProperties} displayStyle - The style of the display
  @param {string} alt - The alt of the image

  @param {"image" | "gif" | "video"} sourceType - The type of the source to be displayed
  @param {string} src - The source of the image

  @param {boolean} isLoading - If the image is loading
  @param {string} containerClassName - The class name of the container
  @param {React.CSSProperties} containerStyle - The style of the container
  @description - Define the other structures of image and your proportion
  @param {string} wrapperClassName - The class name of the wrapper
  @param {React.CSSProperties} wrapperStyle - The style of the wrapper

  @param {string} centralized - If the image need to be in center
  @param {string} size - The size of the image { maxWidth: string, minWidth: string}

  @param {boolean} contained - If the image need to be contained
  @param {DisplayProportion} displayProportion - String with % or DisplayProportion to calculate the proportion of the image

  @type {boolean} noShrink - If the image need to be shrinked

  @returns {React.FunctionComponent} Return the Image, Gif, Video or Skeleton of the source
*/
const DisplaySource = ({
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  isLoading,
  sourceType = "image",
  centralized = false,
  contained = false,
  displayClassName,
  displayProportion = "100%",
  size = {
    maxWidth: null,
    minWidth: null
  },
  rounded,
  noShrink = false,
  withoutGradient = false,
  ...displaySourceProps
}: DisplaySourceProps) => {
  const calculateDisplayProportion = () => {
    if (typeof displayProportion === "string" && displayProportion.includes("%")) {
      return displayProportion
    } else if (typeof displayProportion === "object") {
      return `${(displayProportion?.height / displayProportion?.width) * 100}%`
    } else {
      return "100%"
    }
  }

  const haveImage = displaySourceProps?.src

  return (
    <div
      className={classNames("display-source-container", {
        [containerClassName]: containerClassName,
        [`${rounded} overflow-hidden`]: rounded,
        "flex-shrink-0": noShrink,
        "mx-auto my-auto": centralized,
        "d-none": !haveImage
      })}
      style={{
        ...containerStyle,
        maxWidth: size?.maxWidth,
        minWidth: size?.minWidth
      }}
    >
      <div
        className={classNames("display-source-wrapper", {
          [wrapperClassName]: wrapperClassName,
          "display-contained": contained
        })}
        style={{
          ...wrapperStyle,
          paddingBottom: calculateDisplayProportion()
        }}
      >
        {isLoading && <DisplaySourceSkeleton />}
        {!isLoading && !displaySourceProps?.src && !withoutGradient && (
          <DisplaySourceSkeleton skeletonType={"gradient"} />
        )}
        {!isLoading && displaySourceProps?.src && (
          <>
            {(sourceType === "image" || sourceType === "gif") && <DisplaySourceImage {...displaySourceProps} />}
            {sourceType === "video" && <DisplaySourceVideo {...displaySourceProps} />}
          </>
        )}
      </div>
    </div>
  )
}

export default DisplaySource
