import { createContext } from "react"

export type LoginContentValues = {
  isGoogleLoginEnabled: boolean
  isFacebookLoginEnabled: boolean
  isAppleLoginEnabled: boolean
  isMicrosoftLoginEnabled: boolean
  isMetamaskLoginEnabled: boolean
}

const LoginContentContext = createContext({} as LoginContentValues)

export default LoginContentContext