import { Dispatch, SetStateAction, createContext } from "react"

export interface AccessGuardShieldStateI {
  isAccessGuardShieldEnabled: boolean
  setIsAccessGuardShieldEnabled: Dispatch<SetStateAction<boolean>>
}

export interface UserHaveAccessPermissionStateI {
  userHaveAccessPermission: boolean
}

export interface AccessGuardContextI extends AccessGuardShieldStateI, UserHaveAccessPermissionStateI {}

const AccessGuardContext = createContext({} as AccessGuardContextI)

export default AccessGuardContext
