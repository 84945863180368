import { useQuery } from "react-query"
import { fetchCompanyCustomDomain, fetchDefaultMarketplace } from "services/customDomain"
import { onError } from "utility/onError"

interface FetchCompanyCustomDomainProps {
  customDomainCompanyId: string
  enabled?: boolean
}

const useFetchDefaultMarketPlace = ({ customDomainCompanyId, enabled }: FetchCompanyCustomDomainProps) => {
  const { data: defaultMarketPlaceData, isLoading: defaultMarketPlaceIsLoading } = useQuery(
    ["fetchDefaultMarketplace", customDomainCompanyId],
    () => fetchDefaultMarketplace(customDomainCompanyId).then((response) => response.data),
    {
      onError,
      enabled: !!customDomainCompanyId && enabled,
      refetchOnWindowFocus: false
    }
  )
  

  return { defaultMarketPlaceData, defaultMarketPlaceIsLoading }
}

export default useFetchDefaultMarketPlace
