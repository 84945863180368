import { useState } from "react"
import GlobalLoadingContext from "./GlobalLoadingContext"

interface GlobalLoadingProviderProps {
  children: React.ReactNode
}

const GlobalLoadingProvider = ({ children }: GlobalLoadingProviderProps) => {
  const { Provider } = GlobalLoadingContext
  const [isGlobalLoadingActive, setIsGlobalLoadingActive] = useState(false)

  const providerValues = {
    isGlobalLoadingActive,
    setIsGlobalLoadingActive
  }

  return <Provider value={providerValues}>{children}</Provider>
}

export default GlobalLoadingProvider
