import useWeb3AuthContext from "contexts/auth/methods/web3/useWeb3AuthContext"
import { Web3IdleStages } from "contexts/auth/methods/web3/web3AuthContext"

import { browserName as userBrowser } from "react-device-detect"
import { useTranslation } from "react-i18next"
import Web3CallToActionCard from "../variants/callToActionCard"
import useGetProviderPluginData from "./providerPluginData"
import { BrowserNames } from "@core/components/login/utils/hooks/useGetBrowserData"

interface BrowsePluginCardProps {
  browser?: BrowserNames
}

const BrowsePluginCard = ({ browser }: BrowsePluginCardProps) => {
  const { selectedWeb3AuthProvider, setWeb3IdleStageShowing } = useWeb3AuthContext()
  const { t } = useTranslation()

  const providerBrowserPlugin = useGetProviderPluginData({ web3Provider: selectedWeb3AuthProvider })

  const handleLinkToProvider = (addProviderLink) => {
    window.open(addProviderLink, "_blank")
    setWeb3IdleStageShowing(Web3IdleStages.Get_Started_Extension)
  }

  let addProviderLink = providerBrowserPlugin?.[browser || userBrowser]
  if (!addProviderLink) {
    addProviderLink = providerBrowserPlugin?.["default"]
  }

  const { link, logo, name } = addProviderLink || {}

  return (
    <Web3CallToActionCard
      id={`${selectedWeb3AuthProvider}-plugin-added`}
      onClick={() => handleLinkToProvider(link)}
      image={logo}
      subtitle="Get_web3_plugin_description"
      title={t("{{web3Provider}}_for_{{browser}}", { browser: name, web3Provider: selectedWeb3AuthProvider })}
      buttonText={t("Add_to_{{browser}}", { browser: name })}
    />
  )
}

export default BrowsePluginCard
