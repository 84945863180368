import SwiperContainer from "./container"
import StandardSwiperSlide from "./slides"
import SwiperVariants from "./variants"

// ** Third Party Components
import SwiperCore, {
  Grid,
  Lazy,
  Virtual,
  Autoplay,
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow
} from "swiper"

// ** Styles
import "@core/scss/react/libs/swiper/swiper.scss"

// ** Init Swiper Functions
SwiperCore.use([Navigation, Grid, Pagination, EffectFade, EffectCube, EffectCoverflow, Autoplay, Lazy, Virtual])

export interface SwiperRootProps {
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
  [key: string]: any
}

const SwiperRoot = {
  Container: SwiperContainer,
  Swiper: SwiperVariants,
  Slide: StandardSwiperSlide
}

export default SwiperRoot
