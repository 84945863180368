import ProceedToPhysicalPersonForms from "./components/proceedToPhysicalPersonForms"
import ProceedToJuridicForms from "./components/proceedToJuridictForms"
import KycHeaderLayout from "../../header"
import { BsClockHistory } from "react-icons/bs"
import KycCaption from "../../components/kycCaption"
import useFormContext from "utility/context/formContext/useFormContext"
import useKycContext from "../../context/useKycContext"
import { KycStatusStageEnum } from "../../context/KycContext"
import { useEffect } from "react"

const KycKnowBetterStageSlide = () => {
  const { setCurrentStatusStage, useKycSwiper } = useKycContext()
  const { handleInputChange, formData } = useFormContext()

  const handleContinue = () => {
    setCurrentStatusStage(KycStatusStageEnum.BASIC_INFOS)
    useKycSwiper?.slideNext()
  }

  const handleToNextSlide = (personType) => {
    handleInputChange({ target: { name: "identityDocument", value: { document: personType, value: "" } } })
    handleContinue()
  }
  return (
    <>
      <KycHeaderLayout
        title="KYC_Know_You_Better"
        description="KYC_Know_You_Better_Description"
        src="https://cdn.pixabay.com/photo/2015/04/23/22/00/tree-736885__480.jpg"
      />
      <div className="d-flex flex-column gap-25 h-100 justify-content-between">
        <div className="login-wrapper d-flex flex-column gap-75 h-100 justify-content-center">
          <ProceedToPhysicalPersonForms onClick={handleToNextSlide} />
          <ProceedToJuridicForms onClick={handleToNextSlide} />
        </div>
        <KycCaption icon={BsClockHistory} text="KYC_Know_You_Better_Caption" />
      </div>
    </>
  )
}

export default KycKnowBetterStageSlide
