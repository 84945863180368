import { CompanyPublicData } from "interfaces/company"
import { axiosBase, getAxiosConfig } from "./axiosBase"

const getcompanyById = (id: string, acessToken: string) => {
  const config = getAxiosConfig(acessToken)
  return axiosBase.get(`/companies/${id}/users`, config)
}

const getPublicCompanyDataAxios = async (companyId: string) => {
  try {
    return (await axiosBase.get<CompanyPublicData>(`/companies/${companyId}/public`))
  } catch (error) {
    throw new Error(error.response.data)
  }
}


export { getcompanyById, getPublicCompanyDataAxios }
