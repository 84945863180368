import AuthInputCaption from "@core/components/login/components/authInputCaption"
import LoginInputRoot from "@core/components/login/components/inputs/root"
import useFormContext from "utility/context/formContext/useFormContext"


export const cnpjMask = (v: string) => {
  v = v?.replace(/\D/g, "")
  v = v?.replace(/^(\d{2})(\d)/, "$1.$2")
  v = v?.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
  v = v?.replace(/\.(\d{3})(\d)/, ".$1/$2")
  v = v?.replace(/(\d{4})(\d)/, "$1-$2")
  return v
}

const KycCnpjInput = () => {
  const {
    Container,
    Label,
    Input: { Container: InputContainer, Input, RightSideIcon }
  } = LoginInputRoot
  const { formData, errors, isSubmit, isFormLoading, handleInputChange } = useFormContext()

  const value = formData?.identityDocument?.value
  const error = errors?.identityDocument
  const showError = isSubmit && error

  const handleInputChangeCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cnpjValue = cnpjMask(e.target.value)
    if (cnpjValue.length < 19) {
      handleInputChange({ target: { name: "identityDocument", value: { document: "cnpj", value: cnpjValue } } })
    }
  }

  return (
    <Container>
      <Label text="CNPJ" />
      <InputContainer>
        <Input
          id="kyc_cnpj"
          name="cnpj"
          type="text"
          placeholder="xx.xxx.xxx/xxxx-xx"
          value={cnpjMask(value)}
          showError={showError}
          onChange={handleInputChangeCnpj}
        />
        <RightSideIcon isLoading={isFormLoading} haveError={showError} size={"1.5rem"} />
      </InputContainer>
      <AuthInputCaption errorText={error} showError={showError} />
    </Container>
  )
}

export default KycCnpjInput
