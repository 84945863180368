import DefaultInput, { DefaultInputProps } from "components/input"

interface LoginInputContentProps extends DefaultInputProps {
  id: string
}

const LoginInputContent = ({ border = "border-light", ...rest }: LoginInputContentProps) => {
  return <DefaultInput border={border} {...rest} />
}

export default LoginInputContent
