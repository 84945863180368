import { createSlice } from "@reduxjs/toolkit"
import {  fetchCommunitiyProducts } from "./communitiesActions"

const communityProductsInitialState = {
  products: [],
  isFetchLoading: true,
  isFetchError: undefined,
  communityProducts: []
}

const communityProductsSlice = createSlice({
  name: "comunnityProducts",
  initialState: communityProductsInitialState,
  reducers: {
    setCommunityProducts: (state, { payload }) => {
      state.products = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCommunitiyProducts.pending, (state) => {
      state.isFetchLoading = true
    })
    builder.addCase(fetchCommunitiyProducts.fulfilled, (state, { payload }) => {
      state.products = payload
      state.isFetchLoading = false
    })
    builder.addCase(fetchCommunitiyProducts.rejected, (state, { error }) => {
      state.isFetchError = error
      state.isFetchLoading = false
    })
  }
})

export default communityProductsSlice.reducer
