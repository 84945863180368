import { useContext, useEffect } from "react"
import { WhitelabelContext } from "../context/whitelabelContext"
import { fetchCompanyCustomDomain, fetchDefaultMarketplace } from "services/customDomain"
import { useQuery } from "react-query"

const useWhitelabel = () => {
  const whitelabelContext = useContext(WhitelabelContext)
  const {
    isCustomDomain,
    setIsCustomDomain,
    initialCompanyIdCookie,
    initialCommunityIdCookie,
    initialCompanyIdParam,
    initialCommunityIdParam,
    shouldFetchCustomDomain,
    setShouldFetchCustomDomain
  } = whitelabelContext

  const params = location?.pathname.split("/")
  const url = window.location.href?.split("https://")[1]?.split("/")[0]

  useEffect(() => {
    if (!params[1]) setShouldFetchCustomDomain(true)
    if (url !== "https://app.gotokens.io" && params[1] && process.env.REACT_APP_ENVIRONMENT === "production") {
      setShouldFetchCustomDomain(true)
    }
    if (initialCommunityIdParam) {
      localStorage.setItem("communityId", initialCommunityIdParam)
    }
    if (initialCompanyIdParam) {
      localStorage.setItem("companyId", initialCompanyIdParam)
    }
  }, [params, url])

  const { data: customDomainCompanyId, isFetching: customDomainCompanyLoading } = useQuery(
    ["fetchCompanyCustomDomain", shouldFetchCustomDomain],
    () => fetchCompanyCustomDomain().then((response) => response.data),
    {
      enabled: !!shouldFetchCustomDomain,
      refetchOnWindowFocus: false
    }
  )

  const {
    data: defaultMarketPlaceData,
    isFetching: defaultMarketPlaceLoading,
    isFetchedAfterMount: isDefaultMarketplaceDataFetched
  } = useQuery(
    ["fetchDefaultMarketplace", customDomainCompanyId],
    () => fetchDefaultMarketplace(customDomainCompanyId).then((response) => response.data),
    {
      enabled: !!customDomainCompanyId,
      refetchOnWindowFocus: false
    }
  )

  const setCustomDomainIds = async () => {
    localStorage.removeItem("isCustomDomain")
    localStorage.setItem("isCustomDomain", "true")
    setIsCustomDomain(true)

    if (customDomainCompanyId && defaultMarketPlaceData && !defaultMarketPlaceLoading && !customDomainCompanyLoading) {
      if (defaultMarketPlaceData.type === "company") {
        localStorage.removeItem("companyId")
        localStorage.setItem("companyId", customDomainCompanyId)
        // Seta o communityId se tiver algum parâmetro de comunidade na url
        if (initialCommunityIdParam) localStorage.setItem("communityId", initialCommunityIdParam)
      } else if (initialCommunityIdCookie !== defaultMarketPlaceData.id) {
        localStorage.removeItem("companyId")
        localStorage.removeItem("communityId")
        localStorage.setItem("communityId", defaultMarketPlaceData.id)
      }
    }
  }

  const setCurrentWeispaceIds = () => {
    localStorage.removeItem("isCustomDomain")
    localStorage.setItem("isCustomDomain", "false")
    setIsCustomDomain(false)

    if (initialCompanyIdCookie || initialCompanyIdParam) {
      localStorage.removeItem("companyId")
      localStorage.setItem("companyId", initialCompanyIdParam ?? initialCompanyIdCookie)
    } else if (initialCommunityIdCookie || initialCommunityIdParam) {
      localStorage.removeItem("communityId")
      localStorage.setItem("communityId", initialCommunityIdParam ?? initialCommunityIdCookie)
    }
  }

  const setWeispaceIdsOnLocalStorage = async () => {
    if (customDomainCompanyId && (!defaultMarketPlaceLoading || !customDomainCompanyLoading)) {
      await setCustomDomainIds()
    } else {
      setCurrentWeispaceIds()
    }
  }

  useEffect(() => {
    setWeispaceIdsOnLocalStorage()
  }, [defaultMarketPlaceLoading, customDomainCompanyLoading])

  if (!whitelabelContext) {
    throw new Error("useWhitelabel must be used within a WhitelabelProvider")
  }

  return {
    ...whitelabelContext,
    customDomainCompanyId,
    customDomainCompanyLoading,
    defaultMarketPlaceData,
    defaultMarketPlaceLoading,
    isDefaultMarketplaceDataFetched,
    setWeispaceIdsOnLocalStorage,
    isCustomDomain,
    setIsCustomDomain
  }
}

export default useWhitelabel
