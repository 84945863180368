import React from "react"
import KycDetailedProceedButtonRoot from "../../../components/button/root"
import KycIcon from "../../../components/kycIcon"
import { MdPersonOutline } from "react-icons/md"
import { IoMdArrowRoundForward } from "react-icons/io"
import { KycPersonTypeI } from "../../../context/kycFormHooks/initialState"
import { UserIdentityDocumentsEnum } from "interfaces/user"

interface ProceedToPhysicalPersonFormsProps {
  onClick: (personType: KycPersonTypeI["identityDocument"]["document"]) => void
}

const ProceedToPhysicalPersonForms: React.FC<ProceedToPhysicalPersonFormsProps> = ({ onClick }) => {
  const { Container, Title, SubTitle } = KycDetailedProceedButtonRoot

  return (
    <>
      <Container
        LeftIcon={<KycIcon icon={MdPersonOutline} size={"lg"} />}
        RightIcon={<KycIcon icon={IoMdArrowRoundForward} />}
        onClick={() => {
          onClick(UserIdentityDocumentsEnum.CPF)
        }}
      >
        <Title text="Physical_Person" />
        <SubTitle text="Physical_Person_Description" />
      </Container>
    </>
  )
}

export default ProceedToPhysicalPersonForms
